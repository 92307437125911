import { clean, DOMHelper, IListenable, IView, Keys, Listenable } from "..";
import { callWithDelegateFallback } from "../helpers/delegate";
import { isInDOM } from "../helpers/HTMLElementHelper";
import { Log } from "../log";
import { BaseComponent, keyGenerator, objFilter } from "./declare";

type ViewFactory<M, V extends IView> = (item: M) => V;
// type ViewCleaner<V> = (view: V) => void;
// eslint-disable-next-line @typescript-eslint/ban-types
export interface ISwitchComponent<M = {}> {
  /** the DOM element managed by this view
   */
  readonly rootElement: HTMLElement;
  show(id: string): void;
  modelFromId(id?: string): M | undefined;
  viewFromId(id?: string): IView | undefined;

  readonly shownId$: IListenable<string | undefined>;
  readonly shownView$: IListenable<IView | undefined>;
  readonly focused$: IListenable<boolean>;
  readonly shown$: IListenable<boolean>;
}

export function isSwitchComponent(value: unknown): value is ISwitchComponent {
  // Check if the value has a .go function. If so, it's an IValidator
  return value !== undefined
    ? typeof (value as ISwitchComponent).shownView$ === "object" &&
        typeof (value as ISwitchComponent).shownId$ === "object"
    : false;
}

/**
  * @description the parameters structure for the SwitchComponent
    @param  id string
    @param model the model for the switch
    @param viewFactory function which, provided a root and a model, should return an list view element
    @param viewCleaner function which cleans a given list view element
    @param cacheViews optional boolean, if true don't recreate views. Default to false
    @param initialViewId (optional) which view should show on start. Defaults to none (undefined)
   */
export type SwitchComponentParams<M, V extends IView = IView> = {
  rootElement: HTMLElement;
  model: M[];
  viewFactory: ViewFactory<M, V>;
  initialViewId: string;
};

export function createSwitchComponent<M, V extends IView = IView>(
  params: SwitchComponentParams<M, V>,
  onReadyCallback?: (switchComponent: ISwitchComponent<M>) => void
): ISwitchComponent<M> {
  const switchComponent = new SwitchComponent(params);
  (async () => {
    onReadyCallback?.(switchComponent);
  })();

  return switchComponent;
}

class SwitchComponent<M, V extends IView = IView> extends BaseComponent<M, V> implements ISwitchComponent<M> {
  params: SwitchComponentParams<M, V>;
  rootElement: HTMLElement;

  shownId$ = new Listenable<string | undefined>(undefined);
  shownView$ = new Listenable<IView | undefined>(undefined);
  focused$ = new Listenable(false);
  shown$ = new Listenable(false);

  constructor(params: SwitchComponentParams<M, V>) {
    super(params.rootElement);
    this.params = params;
    this.rootElement = params.rootElement;

    // keep view in sync with index
    this.shownId$.didChange(shownId => {
      this.shownView$.value = this.viewFromId(shownId);
    });

    if (Object.keys(params.model).length === 0) throw "a switch needs at least 1 component!";

    params.model.forEach(item => {
      const id = keyGenerator(item);
      this.ids.push(id);
      this.modelMap[id] = item;
    });
    this.show(params.initialViewId);
  }

  /**
   * @description display component with specified index
   * @param index index component to display
   */
  show(id: string) {
    if (id == this.shownId$.value) return;
    // at this point, we can remove any views that isn't to be cached
    // so filter our view map
    this.viewMap = objFilter(this.viewMap, (key, view) => {
      // keep the others as they are
      if (this.shownId$.value != key) return true;

      // it's always hidden otherwise
      callWithDelegateFallback(view, "onHidden");
      if (view.cacheable) {
        // we keep
        return true;
      } else {
        // we cleanup from the DOM
        clean(view?.rootElement);
        callWithDelegateFallback(view, "onRelease");
        return false;
      }
    });

    let nextView = this.viewFromId(id);
    if (!nextView) {
      Log.ui_switch.log(`Switch ${this.componentId} creating view for key ${id}`);
      const item = this.modelFromId(id);
      if (item !== undefined) {
        nextView = this.viewMap[id] = this.params.viewFactory(item);
      }
    }
    if (!nextView) Log.ui_switch.error(`no views with id ${id} on switch ${this.componentId}`);

    nextView?.rootElement && this.rootElement.appendChild(nextView.rootElement);
    isInDOM(this.rootElement) && callWithDelegateFallback(nextView, "onShown");
    this.shownId$.value = id;
  }

  /*
  IFocusable implementation
   */
  public onNav(key: Keys): boolean {
    // just forward to shown view
    return callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onNav", key) || false;
  }

  public rejectsFocus(): boolean {
    return this.viewFromId(this.shownId$.value)?.rejectsFocus?.() ?? false;
  }

  public onUnfocused() {
    Log.ui_switch.debug(`${this.componentId} onUnfocused`);
    this.focused$.value = false;
    const currentFocusedView = this.viewFromId(this.shownId$.value);
    currentFocusedView?.rootElement && DOMHelper.removeClass(currentFocusedView?.rootElement, "focused");
    callWithDelegateFallback(currentFocusedView, "onUnfocused");
  }

  public onFocused(): void {
    Log.ui_switch.debug(`${this.componentId} onFocused`);
    this.focused$.value = true;
    const currentFocusedView = this.viewFromId(this.shownId$.value);
    currentFocusedView?.rootElement && DOMHelper.addClass(currentFocusedView?.rootElement, "focused");
    callWithDelegateFallback(currentFocusedView, "onFocused", false);
  }

  public onRelease(): void {
    const oldView = this.viewFromId(this.shownId$.value);
    clean(oldView?.rootElement);
    callWithDelegateFallback(oldView, "onHidden");
    callWithDelegateFallback(oldView, "onRelease");
  }

  public onShown() {
    // just forward to shown view
    this.shown$.value = true;
    isInDOM(this.rootElement) && callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onShown");
  }

  public onHidden() {
    // just forward to shown view
    this.shown$.value = false;
    callWithDelegateFallback(this.viewFromId(this.shownId$.value), "onHidden");
  }
}
