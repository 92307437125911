import "./partnersPage.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../../../bud-lite-tv/src/lib";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { AppVendors, Didomi, Position } from "../../../tools/cmp/didomi";
import { DidomiStub } from "../../../tools/cmp/didomiStub";
import { ButtonCmp } from "../../../views/cmp/cmpButton/buttonCmp";
import { CmpMenu, CmpMenuText } from "../../../views/cmp/cmpMenu/cmpMenu";
import { openFirstAppPage } from "../../launch/launchPage";
import { ParametersTab } from "../parameters/parametersPage";
import { PrivatePolicyTab } from "../privacyPolicy/privacyPolicy";
import { Partners, PartnersList } from "./partnersList";
export class PartnersTab extends View {
  private _partnersList: Partners[];

  private readonly itemCmpMenu: ButtonCmp[] = [
    {
      id: 0,
      width: 371,
      text: CmpMenuText.saveChoice,
      onPress: () => {
        const vendors: AppVendors = {
          enabled: [],
          disabled: [],
        };

        for (const vendor of this._partnersList) {
          if (vendor.id !== "All") {
            vendors[vendor.state === Position.allow ? "enabled" : "disabled"].push(vendor.id);
          }
        }

        void Didomi.updateUserConsents({
          vendors,
        });
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_partenaires",
            click: "enregistrer",
          },
          {}
        );
        navigationStack.destroyStack();
        void openFirstAppPage();
      },
    },
    {
      id: 1,
      width: 316,
      text: CmpMenuText.acceptAll,
      onPress: () => {
        void Didomi.enableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_partenaires",
            click: "tout_accepter",
          },
          {
            cmp_analytics: "true",
          }
        );
        navigationStack.destroyStack();
        void openFirstAppPage();
      },
    },
    {
      id: 2,
      width: 288,
      text: CmpMenuText.rejectAll,
      onPress: () => {
        void Didomi.disableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_partenaires",
            click: "tout_refuser",
          },
          {
            cmp_analytics: "false",
          }
        );
        navigationStack.destroyStack();
        void openFirstAppPage();
      },
    },
    {
      id: 3,
      width: 513,
      text: CmpMenuText.privacyPolicy,
      onPress: () => navigationStack.pushPage(new PrivatePolicyTab()),
    },
    {
      id: 4,
      width: 407,
      text: CmpMenuText.myConsent,
      onPress: () => navigationStack.pushPage(new ParametersTab()),
    },
  ];

  constructor() {
    super(DOMHelper.createDivWithParent(null, "PartnersPage"));
    this._partnersList = this.createPartnersList();

    DOMHelper.createDivWithParent(this.rootElement, "TitlePartners", null, "Sélectionner les partenaires pour Okoo");
    DOMHelper.createDivWithParent(
      this.rootElement,
      "TextPartners",
      null,
      "Vous pouvez paramétrer votre consentement pour chaque partenaire listé ci-dessous individuellement ou pour l'ensemble des partenaires en cliquant sur les interrupteurs correspondants. La position grise exprime le refus de consentement (les traceurs sont désactivés), la position verte exprime le consentement (les traceurs sont activés). Sans action de votre part les traceurs sont désactivés."
    );
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource<{ id: "partners"; data: Partners[] } | { id: "menu"; data: ButtonCmp[] }>([
          { id: "partners", data: this._partnersList },
          { id: "menu", data: this.itemCmpMenu },
        ]),
        viewFactory: model => {
          if (model.id === "partners") {
            return new PartnersList(this._partnersList);
          } else {
            return new CmpMenu(model.data);
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  private createPartnersList = () => {
    const partnersList: Partners[] = [];
    const vendorsList = DidomiStub.cmp.vendors;

    if (vendorsList?.length) {
      vendorsList.forEach(vendor => {
        partnersList.push({
          id: vendor.vendor_id,
          state: Didomi.isVendorEnabled(vendor.vendor_id) ? Position.allow : Position.denied,
          title: vendor.vendor_title,
        });
      });
    }
    return partnersList;
  };

  onShown() {
    sendPianoAnalytic(
      "publisher.impression",
      {
        feature: "cmp_partenaires",
      },
      {}
    );
  }
}
