import "./videoSwimlane.scss";

import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Unit } from "~models/unit";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { VideoView } from "~views/videoView";

import { ParamClickZone } from "../tools/analytics/piano";

export class VideoSwimlane extends View {
  private _list: IListComponent;
  private _searchString?: string;
  constructor(source: ItemCollection, isDetail: boolean, classname?: string, searchString?: string) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneVideoVignette", "list"));
    this._searchString = searchString;
    if (classname) {
      this.rootElement.classList.add(classname);
    }
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this.delegate = this._list = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Unit | Integrale)[]),
      viewFactory: model => {
        return new VideoView(model, isDetail, false);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: this.onSelect,
    });
  }

  private onSelect = (model: Unit | Integrale, index: number) => {
    return onSelectTile(model, index, ParamClickZone.playlist, this._searchString);
  };

  setFocusOnIndex = (index: number) => {
    this._list.setFocusOnIndex(index, { animate: false });
  };
}
