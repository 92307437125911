import "./searchHeroSwimlane.scss";

import { Program } from "~models/program";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { horizontalArrowFactory } from "../views/arrows/horizontalArrow";
import { SearchHeroView } from "../views/searchHeroView";

export class SearchHeroSwimlane extends View {
  constructor(source: { swimlaneItems: Program[]; title: string }) {
    super(DOMHelper.createDivWithParent(null, null, "searchHeroSwimlane"));

    DOMHelper.createDivWithParent(this.rootElement, null, "searchHeroSwimlaneTitle", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.swimlaneItems),
      viewFactory: model => {
        const view = new SearchHeroView(model);
        return view;
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 5,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: (model: Program, index: number) => {
        return onSelectTile(model, index, "recherche_" + source.title.toLowerCase());
      },
    });
  }
}
