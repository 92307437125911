import "./playerMagnetoPage.scss";

import { DOMHelper, IPage, Keys, platform, PlatformType, View } from "../../../bud-lite-tv/src/lib";
import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { ItemCollection } from "../../models/itemCollection";
import { getOriginPageParams, sendPianoAnalytic } from "../../tools/analytics/piano";
import { Didomi } from "../../tools/cmp/didomi";
import { PlayableItem } from "../rootPage";

const _playerVersionElement = DOMHelper.createDivWithParent(
  document.getElementById("mainContent"),
  "PlayerMagnetoVersion"
);

let _magnetoVersion = "";

export const magnetoVersion = () => {
  return _magnetoVersion;
};

interface PlayerMagnetoOptions {
  publicId?: string;
  userLoggedIn?: boolean;
  autostart?: boolean;
  platform?: string;
  consent?: {
    ad?: string;
    estat?: "optin" | "optout" | "exempt";
    npaw?: "optin" | "exempted";
    freewheel?: boolean;
    adUserId?: string;
    pubUserId?: string;
    recoUserId?: string;
  };
  env?: {
    device?: string;
    firmware?: string;
    connection_type?: string;
    app_version?: string;
    showAd?: boolean;
  };
  showAd?: boolean;
  startTimecode?: number;
  tracking?: {
    page?: string;
    pageType?: string;
    pageProvenance?: string;
    pageProvenanceType?: string;
    zoneProvenance?: string;
    positionVignette?: string | number;
    playProvenance?: PlayProvenance;
  };
  next?: boolean;
  diffusion?: {
    mode?: "tunnel" | "tunnel_first";
    position?: string | number;
    length?: string | number;
  };
  comingNext?: {
    program?: string;
    title?: string;
    preTitle?: string;
  };
  logo?: string;
  debug?: boolean;
}

interface PlayerInitData {
  src: string;
  config: PlayerMagnetoOptions;
}

type PlayProvenanceTunnel = "tunnel_auto" | "tunnel_next" | "tunnel_comingnext";
type PlayProvenance = PlayProvenanceTunnel | "recommendation" | "rejouer" | "reessayer" | "zapette" | "relancer";

type PlayerMagnetoEvent =
  | "error"
  | "next"
  | "settingsOpened"
  | "settingsClosed"
  | "trackListOpened"
  | "trackListClosed"
  | "tunnelOpened"
  | "tunnelClosed"
  | "spritesheetsOpened"
  | "spritesheetsClosed"
  | "playerClose";
// | "new_player"
// | "pause"
// | "canplay"
// | "canplaythrough"
// | "waiting"
// | "zappingOpened"
// | "zappingClicked"
// | "userVolumeChange"
// | "userPlaybackRateChange"
// | "userQualityChanged"
// | "userTextTrackChanged"
// | "userTunnelActivated"
// | "blur"
// | "comingNextDisplayed"
// | "enterpictureinpicture"
// | "leavepictureinpicture"
// | "new_player"
// | "new_video"
// | "pause "
// | "play"
// | "playing"
// | "renderer_ready"
// | "seeked"
// | "seeking"
// | "stalled"
// | "stopped"
// | "timeshiftingBackToLiveRequested"
// | "timeupdate"
// | "userAudioTrackChanged"
// | "userMute"
// | "userVolumeChanged"
// | "video_start"
// | "watchedTimeReached"
// | "zappingClicked"
// | "zappingOpened";

interface PlayerMagnetoType {
  load: (video: PlayerInitData) => void;
  play: () => void;
  pause: () => void;
  // stop: () => void;
  // seek: (position: number) => void;
  // forward: () => void;
  // rewind: () => void;
  // next: () => void;
  // mute: (value: boolean) => void;
  // volume: (value: number) => void;
  // fullscreen: (active: boolean) => void;
  on: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  off: (eventName: PlayerMagnetoEvent, callback: (eventName: string, payload: unknown) => void) => void;
  getCurrentTime: () => number;
  // getDuration: () => number;
  // getCurrentProgress: () => number;
  // getPlayerContainer: () => HTMLElement;
  // getLayer: () => HTMLElement;
  // setReco: (recommendations: any[]) => void;
  dispose: (emptyContainer: boolean) => void;
  // isAdServerReachable: () => boolean;
  toJSON: () => {
    version: string;
    name: string;
  };
}

type Tunnel = {
  currentPosition: number;
  readonly contents: PlayableItem[];
  nextItem: PlayableItem | undefined;
  startTimecode: number;
};

export class PlayerMagnetoPage extends View implements IPage {
  private _Magnetoscope = (window as any).magnetoscope;
  private _player?: PlayerMagnetoType | undefined;
  private _currentItem: PlayableItem;
  private readonly _progressInSec: number;
  private _indexInSlider: number | undefined;
  private _isPlaying = true;
  private _isLive: boolean;
  private _handleBackButton = true;

  constructor(item: PlayableItem, index?: number) {
    super(DOMHelper.createDivWithParent(null, "PlayerMagnetoPage", "playerMagnetoPage"));
    this._currentItem = item;
    this._isLive = item.metadata.extras?.is_live ? true : false;
    this._indexInSlider = index != undefined ? index + 1 : undefined;

    // manage progress
    // on Okoo there is no "reprise de lecture"
    this._progressInSec = 0;
  }

  private _pianoAnalyticsPageDisplaySent = false;

  onShown() {
    if (this._pianoAnalyticsPageDisplaySent === false) {
      // the onShown can triggered twice when clicking on a video the videoPlayerSwimlane
      this._pianoAnalyticsPageDisplaySent = true;
      this._sendPianoAnalyticsPageDisplay();
    }
  }

  // mechanism to fetch markerPiano only one time
  private _fetchMarkerPianoPromise?: Promise<NonNullable<ReturnType<typeof parseMarkerPianoPageDisplay>>>;
  private _fetchMarkerPiano = () => {
    if (this._fetchMarkerPianoPromise === undefined) {
      return (this._fetchMarkerPianoPromise = new Promise((resolve, reject) => {
        const markerPiano = parseMarkerPianoPageDisplay(this._currentItem.extras);
        if (markerPiano === undefined) {
          Plugin.getInstance()
            .fetchDetailled(this._currentItem)
            .subscribe(value => {
              const newMarkerPiano = parseMarkerPianoPageDisplay(value?.[0]?.extras);
              if (newMarkerPiano === undefined) {
                Log.analytics.error("Failed to send piano analytics");
              } else {
                resolve(newMarkerPiano);
              }
            }, reject);
        } else {
          resolve(markerPiano);
        }
      }));
    } else {
      return this._fetchMarkerPianoPromise;
    }
  };

  private _sendPianoAnalyticsPageDisplay = () => {
    this._fetchMarkerPiano()
      .then(markerPiano => {
        sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
      })
      .catch((e: unknown) => {
        Log.analytics.error("Failed to send piano analytics", e);
      });
  };

  private _getConfigPublicId = () => {
    return "";
  };

  private _getConfigConsent = () => {
    return {
      estat: Didomi.isVendorAllowedToTrack("estat-P2GUgJcz") ? "optin" : "exempt",
    } as const;
  };

  private _getConfigEnv = () => {
    const device = (() => {
      switch (platform.type) {
        case PlatformType.tizen:
          return "samsung";
        case PlatformType.webos:
          return "lg";
        case PlatformType.hisense:
          return "hisense";
        case PlatformType.philips:
          return "philips";
        default:
          return "lg";
      }
    })();

    return {
      device: device,
      app_version: __APP_VERSION__,
    };
  };

  private _getConfigPlatform = () => {
    return "smarttv";
  };

  private _onError = (eventName: string, payload: unknown) => {
    Log.player.error("[MAGNETO] error", payload);
  };

  private _onPlayerClose = () => {
    Log.player.info("[MAGNETO] onPlayerClose");
    navigationStack.removePage(this);
  };

  init = () => {
    Log.player.log("[MAGNETO] init");
    try {
      const initMagnetoOptions: { debug: boolean; platform: string; webservices?: { gateway?: string } } = {
        debug: true,
        platform: this._getConfigPlatform(),
      };
      if (__BACKEND_TARGET__ === "pre") {
        initMagnetoOptions.webservices = {
          gateway: "https://k7.ftven-preprod.fr/videos/",
        };
      }

      this._player = new this._Magnetoscope(this.rootElement, initMagnetoOptions) as PlayerMagnetoType;
      _magnetoVersion = this._player?.toJSON?.()?.version ?? "";
      if (__IS_RELEASE__ === false) {
        _playerVersionElement.innerText = _magnetoVersion;
      }
    } catch (e: unknown) {
      Log.player.log("[MAGNETO] init, cannot create new magneto player.");
      // TODO: maybe display a popup and remove the page
      return;
    }

    this._player.on("error", this._onError);

    this._player.on("playerClose", this._onPlayerClose);

    // Setting listeners
    const openedEvents: PlayerMagnetoEvent[] = [
      "trackListOpened",
      "tunnelOpened",
      "spritesheetsOpened",
      "settingsOpened",
    ];
    const closedEvents: PlayerMagnetoEvent[] = [
      "trackListClosed",
      "tunnelClosed",
      "spritesheetsClosed",
      "settingsClosed",
    ];

    for (const event of openedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(false);
      });
    }

    for (const event of closedEvents) {
      this._player.on(event, () => {
        this._setHandleBackButton(true);
      });
    }

    if (this._isLive === false) {
      Plugin.getInstance()
        .fetchNextEpisodes(this._currentItem)
        .toPromise()
        .then((value: unknown[]) => {
          const nextEpisodesItemCollection = value[0];
          if (nextEpisodesItemCollection instanceof ItemCollection && nextEpisodesItemCollection.items.length > 0) {
            // ajout dde l'item au début du tunnel
            nextEpisodesItemCollection.items.unshift(this._currentItem);
            const tunnel: Tunnel = {
              currentPosition: 0,
              contents: nextEpisodesItemCollection.items,
              nextItem: nextEpisodesItemCollection.items[1],
              startTimecode: this._progressInSec ?? 0,
            };
            this._player?.on("next", (eventName, payload) => {
              const playProvenance = (() => {
                if (payload === "button") {
                  return "tunnel_next";
                } else if (payload === "comingNext") {
                  return "tunnel_comingnext";
                } else {
                  return "tunnel_auto";
                }
              })();

              if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                tunnel.currentPosition++;
                this._currentItem = tunnel.contents[tunnel.currentPosition];
                if (tunnel.currentPosition + 1 < tunnel.contents.length) {
                  tunnel.nextItem = tunnel.contents[tunnel.currentPosition + 1];
                  tunnel.startTimecode = 0;
                } else {
                  tunnel.nextItem = undefined;
                }
              }
              this._loadVideo(playProvenance, tunnel);
            });

            // loading first video
            this._loadVideo(undefined, tunnel);
          } else {
            // tunnel is empty
            this._loadVideo(undefined, undefined);
          }
        })
        .catch((error: unknown) => {
          // err loading initial video without tunnel
          Log.player.error("fetchNextEpisodes failed", error);
          this._loadVideo(undefined, undefined);
        });
    } else {
      // currentItem is a live, loading without tunnel
      this._loadVideo(undefined, undefined);
    }
  };

  private _setHandleBackButton(handleBackButton: boolean) {
    this._handleBackButton = handleBackButton;
  }

  private _loadVideo = (playProvenance: PlayProvenance | undefined, tunnel: Tunnel | undefined) => {
    if (this._player === undefined) {
      Log.app.error("[MAGNETO] player is undefined, init was not called or page is released!");
      return;
    }
    Log.player.log("[MAGNETO] loading video...", this._currentItem.id);

    const originPageParams = getOriginPageParams();

    // if the playableItem is a "live" and not "externe", it means that is a "live channel", the si_id have to be taken from extras.channel.si_id
    let si_id = undefined;
    if (
      this._currentItem.metadata?.extras?.is_live &&
      this._currentItem.media?.broadcast?.extras?.broadcast_channel &&
      this._currentItem.media.broadcast.extras.broadcast_channel !== "externe"
    ) {
      si_id = this._currentItem.extras?.channel?.extras?.si_id;
    } else {
      si_id = this._currentItem.extras?.si_id;
    }

    if (typeof si_id !== "string") {
      Log.app.error("[MAGNETO] si_id is not a string", this._currentItem);
      return;
    }

    const videoOptions: PlayerInitData = {
      src: si_id,
      config: {
        autostart: true,
        publicId: this._getConfigPublicId(),
        userLoggedIn: false,
        showAd: false, // no ads in Okoo //!this._currentItem.extras.ads_blocked,
        startTimecode: tunnel === undefined ? this._progressInSec : tunnel.startTimecode,
        platform: this._getConfigPlatform(),
        tracking: {
          // TODO: check what to do
          page: "player",
          pageType: "player",
          pageProvenance: originPageParams.page ?? undefined,
          pageProvenanceType: originPageParams.pageType ?? undefined,
          // zoneProvenance: this._item?.itemCollection?.type ?? undefined,
          // positionVignette: this._indexInSlider,
          playProvenance,
        },
        consent: this._getConfigConsent(),
        env: this._getConfigEnv(),
        debug: true,
      },
    };

    if (this._isLive) {
      const channelLogo: string | undefined = this._currentItem.extras?.channel?.getLogoImgUrl();
      if (channelLogo !== undefined) {
        videoOptions.config.logo = channelLogo;
      }
    }

    if (tunnel !== undefined) {
      videoOptions.config.diffusion = {
        mode: tunnel.currentPosition === 0 ? "tunnel_first" : "tunnel",
        position: tunnel.currentPosition + 1,
        length: tunnel.contents.length,
      };
      if (tunnel.nextItem !== undefined) {
        videoOptions.config.next = true;
        videoOptions.config.comingNext = {
          program: tunnel.nextItem.extras?.program?.title ?? "", // TODO: verfiy how to get the title when item is from an event and don't have program
          title: tunnel.nextItem.extras?.episode_title ?? "",
          preTitle: "",
        };
      } else {
        videoOptions.config.next = false;
      }
    } else {
      // no tunnel
      videoOptions.config.next = false;
    }

    this._player.load(videoOptions);
  };

  onRelease = () => {
    Log.player.log("[MAGNETO] Destroying player...");
    this._player?.dispose(false);
    this._player = undefined;
  };

  // These key handlers are here for testing. Magneto will implement it on its side.
  onNav = (key: Keys): boolean => {
    Log.player.log(key);
    switch (key) {
      case Keys.play:
        this._player?.play();
        return true;
      case Keys.playPause:
        this._isPlaying ? this._player?.pause() : this._player?.play();
        this._isPlaying = !this._isPlaying;
        return true;
      case Keys.pause:
        this._player?.pause();
        return true;
      case Keys.stop:
        this._onPlayerClose();
        return true;
      case Keys.back:
        if (this._handleBackButton) {
          // return false, navigationStack will remove the page
          return false;
        } else {
          // return true to stop propagation of the event, backButton is handled by magnetoPlayer
          return true;
        }
    }
    return false;
  };
}
