import "./privacyPolicy.scss";

import BackButtonImg from "~images/cmp/vector.png";

import { DOMHelper, Keys, View } from "../../../../bud-lite-tv/src/lib";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
export class PrivatePolicyTab extends View {
  private readonly title = "Notre politique de confidentialité";
  private readonly text =
    "Scannez ce QR code avec votre smartphone ou rendez vous sur https://www.francetelevisions.fr/groupe/confidentialite/gestion-des-cookies#";

  constructor() {
    super(DOMHelper.createDivWithParent(null, "PrivacyPolicyPage"));
    const BackButton = DOMHelper.createDivWithParent(this.rootElement, "BackButtonPrivatePolicyTab");
    DOMHelper.createImg(BackButton, "ImgBackButtom", null, BackButtonImg, "#ffffff");
    BackButton.onmousedown = this.goBack;
    DOMHelper.createDivWithParent(this.rootElement, "TitlePrivatePolicy", null, this.title);
    DOMHelper.createDivWithParent(this.rootElement, "TextPrivatePolicy", null, this.text);
    const imageDiv = DOMHelper.createDivWithParent(this.rootElement, "ImagePrivatePolicy", null, null);
    DOMHelper.createDivImg(imageDiv, "QRCode", null, require("~images/cmp/PolitiqueDeConfidentialite.png"));
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: "politiques_de_confidentialite", page_type: "autre" }, {});
  }

  private goBack = () => {
    navigationStack.removePage(this);
  };

  public onNav(key: Keys) {
    switch (key) {
      case Keys.select:
        navigationStack.removePage(this);
        return true;
      default:
        return false;
    }
  }
}
