import "./newSearchView.scss";

import { DOMHelper, View } from "~ui-lib";

export class NewSearchView extends View {
  constructor(inProgramSearchSwimlane?: boolean) {
    super(DOMHelper.createDivWithParent(null, null, "newSearch"));
    if (inProgramSearchSwimlane === true) {
      this.rootElement.classList.add("inProgramSearch");
    }
    const boxElement = DOMHelper.createDivWithParent(this.rootElement, null, "newSearchBoxZoom");
    const backgroundElement = DOMHelper.createDivWithParent(boxElement, null, "newSearchBackground");
    DOMHelper.createDivImg(backgroundElement, null, "newSearchImg", require("~images/pictos_search/search_picto.png"));
    DOMHelper.createDivWithParent(backgroundElement, null, "newSearchText", "Nouvelle recherche");
  }
}
