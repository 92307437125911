import "./searchTextBox.scss";

import { DOMHelper, IListenable, View } from "~ui-lib";

export class SearchTextBoxView extends View {
  private _scrollBoxElement: HTMLElement;
  private _textElement: HTMLElement;
  private _textToSearchUnreg: () => void;

  constructor(parent: HTMLElement, textToSearch$: IListenable<string>) {
    super(DOMHelper.createDivWithParent(parent, null, "searchTextBox"));
    DOMHelper.createDivWithParent(this.rootElement, null, "searchTextBoxIcon");
    this._scrollBoxElement = DOMHelper.createDivWithParent(this.rootElement, null, "searchTextBoxScrollBox");
    this._textElement = DOMHelper.createDivWithParent(this._scrollBoxElement, null, "searchTextBoxText", "");
    this._textToSearchUnreg = textToSearch$.didChange(newValue => {
      this.updateTextFields(newValue);
      if (newValue.length > 0) this._textElement.innerText = newValue;
    });
  }

  updateTextFields = (newValue: string) => {
    this._textElement.innerText = newValue;
    if (this._textElement.offsetWidth > this._scrollBoxElement.offsetWidth)
      this._textElement.style.left = this._scrollBoxElement.offsetWidth - this._textElement.offsetWidth - 10 + "px";
    else this._textElement.style.left = "0";
  };

  onRelease() {
    this._textToSearchUnreg();
  }
}
