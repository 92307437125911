import "./infobanner.scss";

import { PlayableItem } from "~pages/rootPage";
import { getChannelLogoPlayer } from "~tools/getChannelPics";
import { DOMHelper } from "~ui-lib";

export class InfoBanner {
  constructor(parent: HTMLElement, item: PlayableItem, isLive: boolean) {
    const rootElement = DOMHelper.createDivWithParent(parent, "playerInfoBanner", "playerInfoBanner");
    const programTitle = item.extras.program?.title,
      videoTitle = item.extras?.episode_title;

    // En direct
    const liveTextBox = DOMHelper.createDivWithParent(rootElement, null, "liveTextBox");
    DOMHelper.createDivWithParent(liveTextBox, null, "liveText", "en direct");
    isLive && DOMHelper.addClass(rootElement, "live");

    const metadataContainer = DOMHelper.createDivWithParent(rootElement, "metadataContainer", "metadataContainer");

    // Logo
    const channelLogo = getChannelLogoPlayer(item);
    DOMHelper.createDivImg(
      metadataContainer,
      "playerChannelIcon",
      "playerChannelIcon",
      channelLogo ? channelLogo : null
    );

    // Title & subtitle
    DOMHelper.createDivWithParent(metadataContainer, "playerProgramTitle", "playerProgramTitle", programTitle || "");
    DOMHelper.createDivWithParent(metadataContainer, "playerVideoTitle", "playerVideoTitle", videoTitle || "");
    !videoTitle && DOMHelper.addClass(metadataContainer, "noProgramTitle");
  }
}
