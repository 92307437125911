import "./splashscreenView.scss";

import { Storage } from "~libs/storage";
//import { AgePage } from "~pages/gestionAge/agePage";
import { DOMHelper, View } from "~ui-lib";

const anim_start = require("~images/launch/splashscreen.gif");

export class SplashscreenView extends View {
  DOMAnim: HTMLImageElement;
  animationDuration = 2000;
  private sound: string | undefined;
  private audio: HTMLAudioElement | undefined;
  oneAnimationEnded: () => void;

  constructor(oneAnimationEnded: () => void) {
    super(DOMHelper.createDivWithParent(null, "splashscreen-overlay"));
    this.oneAnimationEnded = oneAnimationEnded;
    const loader = DOMHelper.createDivWithParent(this.rootElement, "splashscreen-container", null);
    // use image src in order to reload gif animation from start
    this.DOMAnim = DOMHelper.createImg(loader, "splashscreen-anim", null, null, "#8C08FF");
  }

  start(): void {
    this.sound = require(`./../../../static/sounds/splashscreen.mp3`);
    this.audio = new Audio(String(this.sound));
    this.audio.autoplay = true;
    this.audio.muted = JSON.parse(Storage.getItem("muted") || "false");
    this.audio.play();

    this.DOMAnim.src = "";
    this.DOMAnim.src = anim_start;

    setTimeout(() => {
      this.oneAnimationEnded();
    }, this.animationDuration);
  }

  onRelease(): void {
    this.DOMAnim.src = "";
  }
}
