import "./horizontalArrow.scss";

import { DOMHelper, View } from "~ui-lib";

class HorizontalArrow extends View {
  constructor(direction: "prev" | "next") {
    super(DOMHelper.createDivWithParent(null, null, "horizontalArrow " + direction));
    DOMHelper.createDivWithParent(this.rootElement, null, "text", direction == "prev" ? "<" : ">");
  }
}

export const horizontalArrowFactory = (direction: "prev" | "next") => new HorizontalArrow(direction);
