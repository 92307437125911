import "./emptySearchSwimlane.scss";

import { createListComponent, DOMHelper, Listenable, StaticModelSource, View } from "~ui-lib";

import { sendPianoAnalytic } from "../tools/analytics/piano";
import { NewSearchView } from "../views/newSearchView";

export class EmptySearchSwimlane extends View {
  constructor(textToSearch$: Listenable<string>) {
    super(DOMHelper.createDivWithParent(null, "", "emptySearchSwimlane"));
    DOMHelper.createDivWithParent(this.rootElement, null, "emptySearchSwimlaneTitle", "Oups !");
    DOMHelper.createDivWithParent(this.rootElement, null, "emptySearchSwimlaneSubtitle", "Il n'y a pas de résultat.");
    DOMHelper.createDivImg(
      this.rootElement,
      null,
      "emptySearchSwimlaneIcon",
      require(`~images/pictos_search/noResult.png`)
    );
    this.delegate = createListComponent({
      rootElement: DOMHelper.createDivWithParent(this.rootElement, "", "emptySearchSwimlaneButtonList"),
      modelSource: new StaticModelSource(["emptySearchSwimlane"]),
      viewFactory: () => {
        return new NewSearchView();
      },
      horizontal: true,
      pageSize: 1,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: () => {
        sendPianoAnalytic("click.action", {}, { click: "nouvelle_recherche", ise_keyword: textToSearch$.value });
        textToSearch$.value = "";
        return true;
      },
    });
  }
}
