import "./mixedView.scss";

import { convertDuration } from "~tools/convert";
import { checkImage } from "~tools/tool";
import { DOMHelper, View } from "~ui-lib";

export class MixedView extends View {
  constructor(item: any) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemMixedVignette"));

    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemMixedViewBoxZoom");
    const background = DOMHelper.createDivWithParent(box, null, "backgroundMixed");
    const elementImgSrc: string = item.getSquareImgUrl();

    if (item.type == "unitaire" || item.type == "integrale" || item.type == "extrait") {
      const elementImg = DOMHelper.createDivImg(background, null, "elementImg");
      checkImage(
        elementImgSrc,
        () => {
          elementImg.style.background = `url("${elementImgSrc}") no-repeat`;
          elementImg.style.backgroundSize = "100%";
        },
        () => {
          elementImg.style.background = `url("${require("~images/fallback/default_okoo.png")}") no-repeat`;
          elementImg.style.backgroundSize = "100%";
        }
      );
      DOMHelper.createDivWithParent(elementImg, null, "videoMask");
      const videoInfoBox = DOMHelper.createDivWithParent(elementImg, null, "videoInfoBox");
      DOMHelper.createDivImg(videoInfoBox, null, "playIconItem", require("~images/pictos_options/play.png"));

      let title = "";
      let episodeName = "";
      let season = "";
      let episode = "";
      if (item.extras?.event) {
        // if video is link to a event
        title = item.extras.event.title || "";
        episodeName = item.extras.episode_title || "";
        season = item.extras.season || "";
        episode = item.extras.episode || "";
      } else if (item.extras?.program) {
        // if video is link to a program
        title = item.extras.program.title || "";
        episodeName = item.extras.episode_title || "";
        season = item.extras.season || "";
        episode = item.extras.episode || "";
      }
      DOMHelper.createDivWithParent(videoInfoBox, null, "videoTitle", episodeName);
      if (season || episode)
        DOMHelper.createDivWithParent(
          videoInfoBox,
          null,
          "videoSeason",
          season
            ? episode
              ? `saison ${season}, episode ${episode}`
              : `saison ${season}`
            : episode
            ? `episode ${episode}`
            : ""
        );
      DOMHelper.createDivWithParent(videoInfoBox, null, "videoTitleProgram", title);

      if (item.metadata) {
        if (item.metadata.duration)
          DOMHelper.createDivWithParent(videoInfoBox, null, "videoDuration", convertDuration(item.metadata.duration));
      }
    } else {
      const fallbackTitle = DOMHelper.createDivWithParent(background, null, "titleMixedFallback", item.title);
      checkImage(
        elementImgSrc,
        () => {
          DOMHelper.createDivImg(background, null, "elementImg", elementImgSrc);
          fallbackTitle.classList.add("hide");
        },
        () => {
          const backgroundFallback = DOMHelper.createDivWithParent(background, null, "backgroundMixedFallback");
          DOMHelper.createDivImg(
            backgroundFallback,
            null,
            "elementImg",
            require("~images/fallback/default_okoo_square.png")
          );
        }
      );
    }
  }

  onFocused = () => {
    // ItemInfoControllerSingleton.focusVideo(this.item);
  };
}
