import { Log } from "../log";

export class Counter {
  name: string;
  currentCount = 0;
  maxCount = 0;

  constructor(name: string) {
    this.name = name;
  }

  increase(): void {
    this.currentCount++;
    Log.perf.debug(`${this.name}: increased: ${this.currentCount}`);
    if (this.currentCount > this.maxCount) {
      this.maxCount = this.currentCount;
      Log.perf.warn(`${this.name}: max increased: ${this.maxCount}`);
    }
  }

  decrease(): void {
    this.currentCount--;
    Log.perf.debug(`${this.name}: increased: ${this.currentCount}`);
  }
}
