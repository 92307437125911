import "./checkBoxParameters.scss";

import {
  createListComponent,
  DOMHelper,
  IListComponent,
  StaticModelSource,
  View,
} from "../../../../bud-lite-tv/src/lib";
import { navigationStack } from "../../../main";
import { Position } from "../../../tools/cmp/didomi";
import { CmpCheckBoxView } from "../../../views/cmp/cmpCheckbox/cmpCheckbox";
import { MoreInformationcCmp } from "../moreInformations/moreInformations";
import { Parameters } from "./parametersList";

class ButtonMoreInformation extends View {
  index: number;
  parameters: Parameters;

  constructor(parameters: Parameters, index: number) {
    super(DOMHelper.createDivWithParent(null, "ButtonMoreInformation"));
    this.index = index;
    this.parameters = parameters;
    DOMHelper.createDivWithParent(this.rootElement, "Text", null, "plus d'information");
  }
}

export class CheckboxParameters extends View {
  private parameters: Parameters;
  private _checkboxView: CmpCheckBoxView;

  constructor(parametersList: Parameters[], index: number, withButton: boolean) {
    super(DOMHelper.createDivWithParent(null, null, "purposeCheckbox"));
    this.parameters = parametersList[index];
    if (!withButton) {
      this.rootElement.style.height = "402px";
    }
    DOMHelper.createDivWithParent(this.rootElement, null, "title", this.parameters.title);
    DOMHelper.createDivWithParent(this.rootElement, null, "text", this.parameters.text);

    this._checkboxView = new CmpCheckBoxView(parametersList[index].state === Position.allow);
    this.rootElement.appendChild(this._checkboxView.rootElement);
  }

  public toggle = () => {
    this.parameters.state = this.parameters.state === Position.allow ? Position.denied : Position.allow;
    return this._checkboxView.toggle();
  };
}

export class CheckboxParametersWithButton extends View {
  private _list: IListComponent<"Checkbox" | "Button">;
  constructor(parametersList: Parameters[], index: number) {
    super(DOMHelper.createDivWithParent(null, "CheckboxParameters"));
    this._list = this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(["Checkbox" as const, "Button" as const]),
      viewFactory: model => {
        switch (model) {
          case "Checkbox":
            return new CheckboxParameters(parametersList, index, true);
          default:
            return new ButtonMoreInformation(parametersList[index], index);
        }
      },
      onSelect: model => {
        const view = this._list.viewFromId(model);
        if (view instanceof CheckboxParameters) {
          view.toggle();
          return true;
        } else if (view instanceof ButtonMoreInformation) {
          navigationStack.pushPage(new MoreInformationcCmp());
          return true;
        }

        return false;
      },
      horizontal: false,
      pageSize: 2,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: false,
    });

    /**
     * `this.delegate` is a MouseListComponent,
     * `this.delegate.onMouseWheel` method is thus the `MouseListComponent.onMouseWheel` one
     *
     * CheckboxParameters list is already in a list component
     * We want to propagate child onMouseWheel event onto the parent list
     * Therefore, we need to override onMouseWheel method to return boolean literal `false` which
     * will indicate to the lib that we want to propagate onWheel event to its parent component.
     */
    this.delegate.onMouseWheel = () => {
      return false;
    };
  }
}
