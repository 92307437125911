import "./homeTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { ItemAge } from "~pages/gestionAge/itemAge";
import { MixedSwimlane } from "~swimlaneViews/mixedSwimlane";
import { ProgramSwimlane } from "~swimlaneViews/programSwimlane";
import { VideoSwimlane } from "~swimlaneViews/videoSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { Plugin } from "../../datas/plugin";
import { MiseEnAvantSwimlane } from "../../swimlaneViews/miseEnAvantSwimlane";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { formatParam } from "../../tools/analytics/stringFormatter";

const _validSwimlaneTypes = ["mise_en_avant", "playlist_video", "playlist_program", "playlist_mixed"];
export class HomeTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _scrollIndexUnregister?: () => void;
  static _currentAge: ItemAge | undefined = undefined;
  private _focusedViewDidChangeUnregister?: () => void;
  private _viewedModels: Set<ItemCollection> = new Set<ItemCollection>();
  private _lastModelViewed?: ItemCollection;
  private _zoneMeaModel?: ItemCollection;
  private _zoneProgrammeModel?: ItemCollection;

  constructor(source?: ItemAge) {
    super(DOMHelper.createDivWithParent(null, "ShowContent", "ShowContent"));
    if (source != undefined) HomeTab._currentAge = source;
    this._fetchSources();
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: "accueil", page_type: "accueil" }, {});
    this._viewedModels.clear();
    if (this._lastModelViewed !== undefined) {
      this._sendPianoAnalytics(this._lastModelViewed);
    }
  }

  private _fetchSources() {
    Plugin.getInstance()
      .fetchChildCategories(HomeTab._currentAge)
      .subscribe(
        value => {
          // Here use it to create the UI
          Log.app.log("[fetchChildCategories] next !", value);
          this._onSourceReady(value);
        },
        error => {
          // Here use it to trigger and display an error
          Log.app.error("[fetchChildCategories] Error !", error);
        }
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, "ContentHomeTab", "contentHomeTab");

    if (source[0]?.type === "mise_en_avant") {
      this._zoneMeaModel = source[0];
      if (source[1] !== undefined) {
        this._zoneProgrammeModel = source[1];
      }
    } else {
      this._zoneMeaModel = undefined;
      if (source[0] !== undefined) {
        this._zoneProgrammeModel = source[0];
      }
    }
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(contentRoot, "HomeTabList", "homeTabList"),
        modelSource: new StaticModelSource(source),
        viewFactory: model => {
          if (model.type == "mise_en_avant") {
            return new MiseEnAvantSwimlane(model);
          } else if (model.type == "playlist_program") {
            return new ProgramSwimlane(model);
          } else if (model.type == "playlist_video") {
            return new VideoSwimlane(model, false);
          } else if (model.type == "playlist_mixed") {
            return new MixedSwimlane(model);
          } else if (model.type == "playlist_hero") {
            return new ProgramSwimlane(model);
          } else {
            Log.app.warn("HomeTab: swimlane type '" + model.type + "' not handled yet.");
            return new MixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 2,
        visibleAfter: 1,
        visibleBefore: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: false,
      },
      mainList => {
        mainList.setFocusOnIndex(0);
      }
    );

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, _) => {
      if (newIndex !== undefined) {
        // in case of mouse or arrow scroll
        this.listComponent?.setFocusOnIndex(newIndex, { scroll: false });
      }
    });

    this._focusedViewDidChangeUnregister = this.listComponent.focusedIndex$.didChange(newIndex => {
      const modelFocused = this.listComponent?.modelFromIndex(newIndex);
      if (modelFocused !== undefined) {
        this._sendPianoAnalytics(modelFocused);
        this._lastModelViewed = modelFocused;
      }
    });
  }

  private _sendPianoAnalytics(model: ItemCollection) {
    if (this._viewedModels.has(model) === true) {
      return;
    }
    this._viewedModels.add(model);
    if (model === this._zoneMeaModel) {
      sendPianoAnalytic(
        "publisher.impression",
        {},
        {
          zone: "mea",
        }
      );
    } else if (model === this._zoneProgrammeModel) {
      sendPianoAnalytic(
        "publisher.impression",
        {},
        {
          zone: "programme",
        }
      );
    } else {
      sendPianoAnalytic(
        "publisher.impression",
        {},
        {
          zone: "playlist_" + formatParam(model.title),
        }
      );
    }
  }

  onRelease = () => {
    this._scrollIndexUnregister?.();
    this._focusedViewDidChangeUnregister?.();
  };
}
