import "./searchProgramSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { AgePage } from "~pages/gestionAge/agePage";
import { onSelectTile, pushTabWithMenu } from "~pages/rootPage";
import { SearchTab } from "~pages/search/searchTab";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { debounce } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { navigationStack } from "../main";
import { LimitAgePopup } from "../pages/popup/limitAgePopup";
import { ParamClickZone, sendPianoAnalytic } from "../tools/analytics/piano";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";
import { NewSearchView } from "../views/newSearchView";
import { ProgramView } from "../views/programView";

export class SearchProgramSwimlane extends View {
  private _list: IListComponent;
  private _searchString?: string;
  private _title?: "Suggestions programmes" | "Résultats programmes";
  constructor(
    source: ItemCollection,
    title?: "Suggestions programmes" | "Résultats programmes",
    searchString?: string
  ) {
    super(DOMHelper.createDivWithParent(null, "SearchProgramSwimlane" + source.id, "searchProgramSwimlane"));
    this._searchString = searchString;
    this._title = title;
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", title !== undefined ? title : source.title);
    this.delegate = this._list = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Program | ItemCollection)[]),
      viewFactory: model => {
        if (model.type == "newSearch") {
          return new NewSearchView(true);
        } else {
          return new ProgramView(model);
        }
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 5,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: this.onSelect,
    });
  }
  private onSelect = (model: Program | ItemCollection, index: number) => {
    if (model.type == "newSearch") {
      sendPianoAnalytic("click.action", {}, { click: "nouvelle_recherche", ise_keywork: this._searchString ?? "" });
      pushTabWithMenu(new SearchTab(), "searchPage", MainMenuItemSlug.search);
      return true;
    }
    if (model.extras && model.extras.age_min && AgePage.selectAge && AgePage.selectAge.age < model.extras.age_min) {
      onSelectTile(model, index, undefined, this._searchString, true);
      const popupLimitAgePage = new LimitAgePopup(
        model.extras.age_min,
        "Ce programme n’est pas adapté à ton âge. Pour y accéder, change ton âge dans le menu profil."
      );
      navigationStack.pushPage(popupLimitAgePage);

      const _hide = debounce(() => {
        navigationStack.removePage(popupLimitAgePage);
      }, 2000);

      _hide();

      return true;
    } else {
      const zoneParams = this._title == "Suggestions programmes" ? "recherche_historique" : ParamClickZone.hero;
      return onSelectTile(model, index, zoneParams, this._searchString);
    }
  };
}
