/**
 * @description Find out if obj is an HTMLElement
 * @returns true if obj is HTMLElement, if not false
 */
function isHtmlElement(obj: Element | null | undefined): obj is HTMLElement {
  return !!obj && (obj as HTMLElement).style !== undefined;
}

/**
 * @description Create HTMLElement
 * @param type Element type for the new HTMLElement (div,span,h1,...)
 * @param parent HTMLElement parent in DOM for the new HTMLElement
 * @param elementId Element id for the new HTMLElement
 * @param classname Element class for the new HTMLElement
 * @param content Some text for the new HTMLElement
 * @returns the created HTMLElement
 */
const createElementWithParent = (
  type: string,
  parent: Element | null = null,
  elementId: string | null = null,
  classname: string | null = null,
  content: string | null = null
): HTMLElement => {
  const element = document.createElement(type);
  if (elementId != null) element.id = elementId;
  if (classname != null) element.className = classname;
  if (parent != null) parent.appendChild(element);
  else document.getElementById("hidden")?.appendChild(element);
  if (content != null) element.innerText += content;

  return element;
};

/**
 * @description Used to build page.
 */
export const DOMHelper = {
  /**
   * @description Find the first HTMLElement with spedcified className
   * @param className HTMLElement classname that you want to find
   * @returns HTMLElement if found, if not throw an error
   * @throws "no HTML element found for classname {className}"
   */
  firstElementByClassName: (className: string): HTMLElement | undefined => {
    const element = document.getElementsByClassName(className).item(0);
    if (isHtmlElement(element)) {
      return element;
    } else {
      throw `no HTML element found for classname ${className}`;
    }
  },

  /**
   * @description Get the head HTMLElement
   * @returns HTMLElement if found, if not throw an error
   * @throws "no head"
   */
  getHead: (): HTMLHeadElement => {
    const head = document.getElementsByTagName("head").item(0);
    if (!head) throw "no head!";
    return head;
  },

  /**
   * @description Get the body HTMLElement
   * @returns HTMLElement if found, if not throw an error
   * @throws "no body"
   */
  getBody: (): HTMLBodyElement => {
    const body = document.getElementsByTagName("body").item(0);
    if (!body) throw "no body!";
    return body;
  },

  /*
   * GENERAL FUNCTIONS
   */

  /**
   * @description Give a new className to your HTMLElement (only if className was null on this Element)
   * @param element HTMLElement to edit
   * @param classname the classname to add
   */
  addClass: (element: HTMLElement | undefined, classname: string): void => {
    if (element && !element.classList.contains(classname)) {
      element.classList.add(classname);
    }
  },

  /**
   *@description Remove HTMLElement className
   *@param element HTMLElement to edit
   * @param classname the classname to remove
   */
  removeClass: (element: HTMLElement | undefined, classname: string): void => {
    if (element && element.classList.contains(classname)) {
      element.classList.remove(classname);
    }
  },

  /**
   *@description Find out if HTMLElement have the specified classname
   *@param element HTMLElement to test
   *@param className classname to test
   */
  hasClass: (element: HTMLElement | undefined, classname: string): boolean => {
    if (element && !element.classList.contains(classname)) {
      return false;
    }
    return true;
  },

  createElementWithParent,

  /**
   * @description create HTMLElement with div type
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param elementId Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement
   * @param content Some text for the new HTMLElement
   * @returns the create HTMLElement
   */
  createDivWithParent: (
    parent: Element | null = null,
    elementId: string | null = null,
    classname: string | null = null,
    content: string | null = null
  ): HTMLElement => {
    return createElementWithParent("div", parent, elementId, classname, content);
  },

  /**
   * @description create HTMLElement with source type
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param elementId Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement
   * @param content Some text for the new HTMLElement
   * @returns the create HTMLElement
   */
  createSourceWithParent: (
    parent: HTMLElement | null = null,
    elementId: string | null = null,
    classname: string | null = null,
    content: string | null = null
  ): HTMLElement => {
    return createElementWithParent("source", parent, elementId, classname, content);
  },

  /**
   * @description create HTMLElement with span type
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param elementId Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement
   * @param content Some text for the new HTMLElement
   * @returns the create HTMLElement
   */
  createSpanWithParent: (
    parent: HTMLElement | null = null,
    elementId: string | null = null,
    classname: string | null = null,
    content: string | null = null
  ): HTMLElement => {
    return createElementWithParent("span", parent, elementId, classname, content);
  },

  /**
   * @description create HTMLElement with p type
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param elementId Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement
   * @param content Some text for the new HTMLElement
   * @returns the create HTMLElement
   */
  createTextWithParent: (
    parent: HTMLElement | null,
    elementId: string | null = null,
    classname: string | null = null,
    content: string | null = null
  ): HTMLElement => {
    return createElementWithParent("p", parent, elementId, classname, content);
  },

  /**
   * @description create HTMLElement with div type and an image background with backgroud-repeat : no-repeat && background size : contain
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param idElement Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement (will add " img" at end. Ex: <div class='sample img'>)
   * @param src image file source.
   * @param size define image size
   * @returns the create HTMLElement
   */
  createDivImg: (
    parent: HTMLElement | null,
    idElement: string | null,
    classname: string | null = null,
    src: string | null = null,
    size: { width: number; height: number } | null = null
  ): HTMLDivElement => {
    const element = document.createElement("div");
    classname ? (classname += " img") : (classname = "img");
    if (idElement != null) element.id = idElement;
    if (classname != null) element.className = classname;

    // PERF: this adds a listener on each & every image. Expensiveness? Benefits?
    // element.onerror = () => {
    //   element.style.display = "none";
    // };

    if (src) {
      element.style.background = `url("${src}") 50% 50% no-repeat`;
      element.style.backgroundSize = "cover";

      if (size) {
        element.style.width = size.width.toString();
        element.style.height = size.height.toString();
      }
    }

    if (parent) {
      parent.appendChild(element);
    }
    return element;
  },

  /**
   * @description create HTMLElement with img type and an image
   * @param parent HTMLElement parent in DOM for the new HTMLElement
   * @param idElement Element id for the new HTMLElement
   * @param classname Element class for the new HTMLElement (will add " img" at end. Ex: <div class='sample img'>)
   * @param src image file source.
   * @param backgroundColor optional background color
   * @returns the create HTMLElement
   */
  createImg: (
    parent: HTMLElement | null,
    idElement: string | null,
    classname: string | null = null,
    src: string | null = null,
    backgroundColor = "#444"
  ): HTMLImageElement => {
    const element = document.createElement("img") as HTMLImageElement;
    // classname ? (classname += " img") : (classname = "img");
    element.style.backgroundColor = backgroundColor;
    if (idElement != null) element.id = idElement;
    if (classname != null) element.className = classname;

    // PERF: this adds a listener on each & every image. Expensiveness? Benefits?
    // element.onerror = () => {
    //   element.style.display = "none";
    // };
    // element.onload = () => {
    //   console.warn(`${idElement} loaded and is ${element.naturalWidth}x${element.naturalHeight}`);
    //   delete element.onload;
    // };

    if (src) {
      element.style.objectFit = "cover";
      element.src = src;
    }

    if (parent) {
      parent.appendChild(element);
    }
    return element;
  },
};
