import "./errorPage.scss";

import { DOMHelper, IPage, View } from "~ui-lib";

import { navigationStack } from "../../main";

export class ErrorPage extends View implements IPage {
  topPage: IPage | undefined;

  constructor(params: { title: string; description: string }, topPage: IPage | undefined) {
    super(DOMHelper.createDivWithParent(null, "ErrorPage"));

    const { title, description } = params;

    const popup = DOMHelper.createDivWithParent(this.rootElement, "ErrorPageContainer", null);
    DOMHelper.createDivWithParent(popup, "ErrorPageTitle", null, title);
    DOMHelper.createDivWithParent(popup, "ErrorPageDescription", null, description);

    this.topPage = topPage;
  }
  onRelease(): void {
    // On release popup that has been pushed, I asked navigation to perform back from delegate popuup page
    // TODO: Check if error is generic and poped from page, otherwise we will need to handle differently
    if (this.topPage) {
      navigationStack.removePage(this.topPage);
    }
  }
  isPopup = () => true;
}
