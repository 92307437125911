import { Log } from "../log";

/**
 * @description Used to read JSON file
 * @param json JSON to read
 * @param key JSON key to read
 * @return the value if value found, if not throw an error
 * @throws{key} is missing from {json}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function readFromJSON<T>(json: any, key: string, errorWhenMissing = false): T | undefined {
  if (json === null || json === undefined) {
    Log.net.debug(`trying to read ${key} from undefined`);
    return undefined;
  }
  const value = json[key];
  if (value === undefined) {
    // lowering log level because that actually happens a *lot*
    if (errorWhenMissing) {
      Log.net.error(`${key} is missing from ${JSON.stringify(json, null, 2)}`);
    } else {
      Log.net.debug(`${key} is missing from ${JSON.stringify(json, null, 2)}`);
    }
    return undefined;
  }
  return value;
}
