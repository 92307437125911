/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, Observer } from "rxjs";

import { Artwork } from "~models/artwork";
import { BrowsableItem } from "~models/browsableItem";
import { Category } from "~models/category";
import { Channel } from "~models/channel";
import { Collection } from "~models/collection";
import { Event } from "~models/event";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Broadcast, Media } from "~models/media";
import { Metadata, Person, Rating } from "~models/metadata";
import { Program } from "~models/program";
import { Unit } from "~models/unit";

import { getAdditionalProperties, parsePianoPageTypeEnum, PianoPageTypeName } from "../tools/analytics/piano";
import { ParseException } from "./exceptions";

export function parseHome(json: any): Observable<ItemCollection> {
  return new Observable((observer: Observer<ItemCollection>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      if (json instanceof Array) {
        json.forEach((item: any) => {
          const itemCollection = _parseCollection(item);
          observer.next(itemCollection);
        });
      } else if (json.item) {
        // Specific parsing for event that is not formatted as expected
        let newJson = json;
        newJson = json.item;
        newJson.collections = json.collections;
        const itemCollection = _parseCollection(newJson);
        observer.next(itemCollection);
      } else {
        const itemCollection = _parseCollection(json);
        observer.next(itemCollection);
      }
    }

    observer.complete();
  });
}

export function parseMenu(json: any): Observable<any> {
  return new Observable((observer: Observer<any>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      const jsonItems = json.items || [];
      jsonItems.forEach((item: any) => {
        item.id = item.slug + "_" + Math.random().toString(36).substr(2, 9);
        observer.next(item);
      });
    }

    observer.complete();
  });
}

export function parseItems(json: any): Observable<BrowsableItem> {
  return new Observable((observer: Observer<BrowsableItem>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      if (json instanceof Array) {
        json.forEach((item: any) => {
          const browsableItem = _parseItem(item);
          observer.next(browsableItem);
        });
      } else {
        const browsableItem = _parseItem(json);
        if (browsableItem !== undefined) {
          observer.next(browsableItem);
        }
      }
    }

    observer.complete();
  });
}

export function parsePlayer(json: any): Observable<JSON> {
  return new Observable((observer: Observer<JSON>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else {
      observer.next(json);
    }

    observer.complete();
  });
}

/**
 * Directs create separate swmilanes in model
 * create 3 types of swimlanes: playlist_channel, playlist_event, playlist_extern
 * @param json
 */
export function parseDirects(json: any): Observable<ItemCollection> {
  return new Observable((observer: Observer<ItemCollection>) => {
    if (json === null || json === undefined) {
      observer.error(new ParseException("Unexpected json: " + JSON.stringify(json)));
    } else if (json.items) {
      if (json.items) {
        const itemsChannels: Array<BrowsableItem> = [];
        const itemCollectionsEventObj: Record<string, ItemCollection> = {};
        const itemsExterns: Array<BrowsableItem> = [];
        const extras: any = {};

        // Parse images
        const artworks: Array<Artwork> = [];

        //playlist_channel
        const itemCollectionChannels = new ItemCollection(
          "playlist_channel" + "_" + Math.random().toString(36).substr(2, 9),
          "playlist_channel",
          "En direct",
          undefined,
          itemsChannels,
          artworks,
          extras
        );

        //playlist_channel
        const itemCollectionExterns = new ItemCollection(
          "playlist_extern" + "_" + Math.random().toString(36).substr(2, 9),
          "playlist_extern",
          "En direct sur france.tv",
          undefined,
          itemsExterns,
          artworks,
          extras
        );

        json.items.forEach((item: any) => {
          if (_isValidJson(item["broadcast_channel"]))
            if (item["broadcast_channel"] == "externe") {
              if (_isValidJson(item["event"]) && item["event"].type == "event" && _isValidJson(item["event"].id)) {
                // Parse Items and create collection according to event types found
                if (!itemCollectionsEventObj[item.event.id]) {
                  const items: Array<BrowsableItem> = [];
                  //playlist_event
                  const itemCollection = new ItemCollection(
                    "playlist_event" + "_" + Math.random().toString(36).substr(2, 9),
                    "playlist_event",
                    item.event.label || item.event.title,
                    undefined,
                    items,
                    artworks,
                    extras
                  );
                  itemCollectionsEventObj[item.event.id] = itemCollection;
                }
                itemCollectionsEventObj[item.event.id].items.push(_parseItem(item));
              } else if (!_isValidJson(item["event"])) {
                itemsExterns.push(_parseItem(item));
              }
            } else {
              itemsChannels.push(_parseItem(item));
            }
        });

        // Send playlist channels first
        if (itemCollectionChannels.items.length > 0) {
          observer.next(itemCollectionChannels);
        }

        // Sort Playlist event by number of items descendant
        let itemsCollectionsEvent: Array<ItemCollection> = [];
        Object.entries(itemCollectionsEventObj).forEach(([key, value]) => {
          itemsCollectionsEvent.push(value);
        });

        itemsCollectionsEvent = itemsCollectionsEvent.sort((a, b) => (a.items > b.items ? -1 : 1));
        itemsCollectionsEvent.forEach(itemsCollectionEvent => {
          if (itemsCollectionEvent.items.length > 0) {
            observer.next(itemsCollectionEvent);
          }
        });

        // Send playlist externs at the end
        if (itemCollectionExterns.items.length > 0) {
          observer.next(itemCollectionExterns);
        }
      }
    }

    observer.complete();
  });
}

/**
 * Item Collection can be :
 * mise_en_avant, playlist_video, playlist_mixed, playlist_program
 */
function _parseCollection(json: any): ItemCollection {
  const items: Array<any> = [];
  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];
  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  const jsonItems = json.items || json.collections || [];

  jsonItems.forEach((item: any) => {
    const children = _parseItem(item);
    items.push(children);
  });

  const itemId = json.id || json.type + "_" + Math.random().toString(36).substr(2, 9);

  // extras?
  for (const extraKey of ["marker_piano"]) {
    const extraKeyValue = json[extraKey];
    if (_isValidJson(extraKeyValue)) {
      extras[extraKey] = extraKeyValue;
    }
  }

  const type = json.label == "tes héros préférés" || json.title == "tes héros préférés" ? "playlist_hero" : json.type;
  const itemCollection = new ItemCollection(
    itemId,
    type,
    json.label || json.title,
    json.description,
    items,
    artworks,
    extras
  );
  for (const item in itemCollection.items) itemCollection.items[item].itemCollection = itemCollection;
  return itemCollection;
}

/**
 * BrowsableItem can be:
 * collection, program, unitaire, integrale, categorie, event
 */
function _parseItem(json: any): BrowsableItem {
  const debug: any = {};

  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];

  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  if (json.type === "bande-annonce") {
    json.type = "extrait";
  }
  if (json.type === "bonus") {
    json.type = "integrale";
  }
  // Parse Metadatas
  let metadata: Metadata | null | undefined = null;

  // Parse media
  let media: Media | null | undefined = null;
  if (
    (_isValidJson(json.begin_date) && _isValidJson(json.end_date)) ||
    (_isValidJson(json.broadcast_begin_date) && _isValidJson(json.broadcast_end_date))
  ) {
    media = _parseMedia(json);
    debug.media = media;
  }

  let item: BrowsableItem = new BrowsableItem(
    json.id,
    json.type,
    json.label || json.title,
    json.description,
    artworks,
    extras
  );

  // Parse type to define object
  if (_isValidJson(json.items)) {
    item = _parseCollection(json);
  } else if (json.type) {
    switch (json.type) {
      case "program":
        debug.program = json;
        // Parse other keys that can be needed (but I don't know yet so it is temporary)
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (
              [
                "ads_blocked",
                "downloadable",
                "program_path",
                "marker",
                "marker_piano",
                "integral_counter",
                "channel",
                "color",
                "age_min",
                "label_edito",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Program(json.id, json.type, json.label || json.title, json.description, artworks, extras);
        break;
      case "collection":
        debug.collection = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (["marker", "marker_piano", "subtitle", "integral_counter", "color", "age_min"].includes(key)) {
              extras[key] = json[key];
            }
          }
        });
        item = new Collection(json.id, json.type, json.label || json.title, json.description, artworks, extras);
        break;
      case "categorie":
        debug.categorie = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (["marker", "marker_piano", "url_complete", "integral_counter"].includes(key)) {
              extras[key] = json[key];
            }
          }
        });
        item = new Category(json.id, json.type, json.label || json.title, json.description, artworks, extras);
        break;
      case "event":
        debug.event = json;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (
              [
                "marker",
                "marker_piano",
                "url_complete",
                "integral_counter",
                "call_to_action_url",
                "call_to_action",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Event(json.id, json.type, json.label || json.title, json.description, artworks, extras);
        break;
      case "channel":
        debug.channel = json;
        item = _parseChannel(json);
        break;
      case "integrale":
        debug.integrale = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "season",
                "episode",
                "age_min",
                "label_edito",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Integrale(
          json.id,
          json.type,
          json.label || json.title,
          json.description,
          artworks,
          metadata,
          extras,
          media
        );
        break;
      case "unitaire":
        debug.unitaire = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "season",
                "episode",
                "age_min",
                "label_edito",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Unit(
          json.id,
          json.type,
          json.label || json.title,
          json.description,
          artworks,
          metadata,
          extras,
          media
        );
        break;
      case "extrait":
        debug.unitaire = json;
        // eslint-disable-next-line no-case-declarations
        metadata = _parseMetadata(json);
        debug.metadata = metadata;
        Object.keys(json).map(key => {
          if (_isValidJson(json[key])) {
            if (key == "channel") {
              extras[key] = _parseChannel(json[key]);
            } else if (key == "program") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "category") {
              extras[key] = _parseItem(json[key]);
            } else if (key == "event") {
              extras[key] = _parseItem(json[key]);
            } else if (
              [
                "ads_blocked",
                "downloadable",
                "marker",
                "marker_piano",
                "subtitle",
                "episode_title",
                "integral_counter",
                "si_id",
                "season",
                "episode",
                "age_min",
              ].includes(key)
            ) {
              extras[key] = json[key];
            }
          }
        });
        item = new Extrait(
          json.id,
          json.type,
          json.label || json.title,
          json.description,
          artworks,
          metadata,
          extras,
          media
        );
        break;
      default:
        break;
    }
  }

  //consoleLog("debug", debug);
  return item;
}

/**
 * Images from item
 */
function _parseArworks(json: any, itemId: string): Array<Artwork> {
  const artworks: Array<Artwork> = [];

  Object.keys(json.urls).map(key => {
    const artworkKey: string = key.replace("w:", "");
    const artwork = new Artwork(itemId + "#" + artworkKey, artworkKey, json.urls[key], json.type);
    artworks.push(artwork);
  });

  return artworks;
}

/**
 * Channel from item
 */
function _parseChannel(json: any): Channel {
  const extras: any = {};

  // Parse images
  const artworks: Array<Artwork> = [];

  if (json.images) {
    json.images.forEach((image: any) => {
      const atwks = _parseArworks(image, json.id);
      atwks.forEach((artwork: Artwork) => {
        artworks.push(artwork);
      });
    });
  }

  Object.keys(json).map(key => {
    if (_isValidJson(json[key]) && ["marker", "channel_path", "channel_url", "si_id"].includes(key)) {
      extras[key] = json[key];
    }
  });

  return new Channel(json.type + "_" + json.id, json.type, json.label, json.description, artworks, extras);
}

/**
 * Metadatas from item for casting, duration, rating, parental level etc...
 */
function _parseMetadata(json: any): Metadata {
  const extras: any = {};

  // Duration
  const duration: number = json.duration ? json.duration : 0;

  // Production year
  const prodYear: number = json.production_year ? json.production_year : null;

  // Casting
  const casting: Array<Person> = [];

  // Rating
  const rating: Rating =
    json.rating_csa && json.rating_csa_code
      ? new Rating(json.rating_csa, json.rating_csa_code, 0)
      : new Rating("", "", 0);

  // Extras
  Object.keys(json).map(key => {
    if (_isValidJson(json[key])) {
      if (key == "casting" || key == "characters" || key == "director" || key == "presenter" || key == "producer") {
        _parsePerson(json[key], key).forEach((person: Person) => {
          casting.push(person);
        });
      } else if (
        ["is_audio_descripted", "is_live", "is_multi_lingual", "is_recommended", "is_subtitled"].includes(key)
      ) {
        extras[key] = json[key];
      }
    }
  });

  return new Metadata(extras, duration, casting, prodYear, rating);
}

/**
 * Media from item for broadcast and video infos
 */
function _parseMedia(json: any): Media {
  let broadcast: Broadcast | null = null;
  let beginDate: Date | null = null;
  let endDate: Date | null = null;

  const extras: any = {};
  const extrasBroadcast: any = {};

  if (_isValidJson(json.broadcast_begin_date) && _isValidJson(json.broadcast_end_date)) {
    if (_isValidJson(json.broadcast_channel)) {
      extrasBroadcast["broadcast_channel"] = json.broadcast_channel;
    }

    broadcast = new Broadcast(
      new Date(json.broadcast_begin_date * 1000),
      new Date(json.broadcast_end_date * 1000),
      extrasBroadcast
    );
  }

  if (_isValidJson(json.begin_date) && _isValidJson(json.end_date)) {
    beginDate = new Date(json.begin_date * 1000);
    endDate = new Date(json.end_date * 1000);
  }

  return new Media(extras, broadcast, beginDate, endDate);
}

/**
 * Casting from item: charracters, presenters, director...
 */
function _parsePerson(json: any, type: string): Array<Person> {
  const persons: Array<Person> = [];

  const splitted = json.split(", ");
  if (splitted) {
    splitted.forEach((title: string) => {
      persons.push(new Person(title, type));
    });
  }

  return persons;
}

function _isValidJson(value: any): boolean {
  return value != null && value != undefined && value != "";
}

type MarkerPianoParsed = {
  additional_properties: Record<string, string>;
  contextual_properties: Record<string, unknown> | undefined;
};

/**
 * Parse `marker_piano` object from extra parameter
 * AND insuring that contextual_properties is correctly typed
 *
 * This method insures we have a marker_piano of MarkerPianoParsed shape
 * OR `undefined` if parsing has failed
 * @param extras
 * @returns MarkerPianoParsed | undefined
 */
export const parseMarkerPianoPageDisplay = (
  extras: unknown
):
  | (Omit<MarkerPianoParsed, "contextual_properties"> & {
      contextual_properties: { page: string; page_type: PianoPageTypeName };
    })
  | undefined => {
  const markerPiano = parseMarkerPiano(extras);

  if (markerPiano !== undefined) {
    // Getting Contextual Properties
    const pageParams = parseMarkerPianoPageParams(markerPiano);

    if (pageParams === undefined) {
      // Contextual properties has failed to be parsed
      return undefined;
    } else {
      // Marker Piano
      return {
        additional_properties: getAdditionalProperties(markerPiano?.additional_properties),
        contextual_properties: pageParams,
      };
    }
  } else {
    // Error during the parsing of Marker Piano
    return undefined;
  }
};

/**
 * Parse `marker_piano` object from extra parameter
 *
 * This method insures we have a marker_piano of MarkerPianoParsed shape
 * OR `undefined` if parsing has failed
 * @param extras
 * @returns MarkerPianoParsed | undefined
 */
export const parseMarkerPiano = (extras: unknown): MarkerPianoParsed | undefined => {
  if (typeof extras === "object" && extras !== null) {
    const markerPiano = (extras as {
      marker_piano:
        | Record<"additional_properties" | "contextual_properties", Record<string, unknown> | undefined | null>
        | undefined;
    }).marker_piano;

    return markerPiano === undefined
      ? undefined
      : {
          additional_properties: getAdditionalProperties(markerPiano.additional_properties),
          contextual_properties: markerPiano.contextual_properties ?? undefined,
        };
  } else {
    return undefined;
  }
};

/**
 * Parse page & page type fields from Marker Piano Object
 *
 * @param markerPiano - `extas.marker_piano` record
 * @returns Page & Page Type or undefined if parsing failed
 */
const parseMarkerPianoPageParams = (
  markerPiano:
    | Record<"additional_properties" | "contextual_properties", Record<string, unknown> | null | undefined>
    | undefined
): { page: string; page_type: PianoPageTypeName } | undefined => {
  if (markerPiano !== undefined) {
    if (typeof markerPiano.contextual_properties?.page === "string") {
      const page = markerPiano.contextual_properties.page;

      const rawPageType = parsePianoPageTypeEnum(markerPiano.contextual_properties?.page_type);
      if (rawPageType !== undefined) {
        return {
          page,
          page_type: rawPageType,
        };
      }
    }
  }

  return undefined;
};
