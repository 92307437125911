export type TriggerListener = () => void;

export type ITrigger = {
  onSignal: (listener: TriggerListener) => () => void;
};

export class Trigger implements ITrigger {
  private _listeners: TriggerListener[] = [];

  signal() {
    this._listeners.forEach(listener => listener());
  }
  /**
   * @description Register to signals
   * @param listener the listener callback function
   * @returns the unregister function
   */
  onSignal = (listener: TriggerListener): (() => void) => {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener);
    }
    return () => {
      this._listeners = this._listeners.filter(registeredListener => registeredListener != listener);
    };
  };
}
