import "./optionsProfilView.scss";

import { Storage } from "~libs/storage";
import { AgePage } from "~pages/gestionAge/agePage";
import { optionsType } from "~swimlaneViews/optionsProfilSwimlane";
import { DOMHelper, View } from "~ui-lib";

import { ItemImages } from "../../pages/gestionAge/itemAge";
export class OptionsProfilView extends View {
  private images: ItemImages;
  private imagesNoSound: ItemImages;
  private divImage: HTMLDivElement;
  private item: optionsType;
  constructor(item: optionsType) {
    super(DOMHelper.createDivWithParent(null, null, "optionSettings"));
    this.item = item;
    const images: ItemImages = { iconFocus: null, iconUnfocus: null };
    const imagesNoSound: ItemImages = { iconFocus: null, iconUnfocus: null };
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "optionSettingsZoom");
    if (item == optionsType.age) {
      const age = AgePage.selectAge;
      try {
        images.iconFocus = require(`~images/pictos_ages/ageFocus/${age?.slug}.png`);
        images.iconUnfocus = require(`~images/pictos_ages/age/${age?.slug}.png`);
      } catch (error) {
        console.error("[itemMenu] Error !", error);
      }
    } else {
      try {
        images.iconFocus = require("~images/pictos_sound/soundOnFocus.png");
        images.iconUnfocus = require("~images/pictos_sound/soundOn.png");
        imagesNoSound.iconFocus = require("~images/pictos_sound/soundOffFocus.png");
        imagesNoSound.iconUnfocus = require("~images/pictos_sound/soundOff.png");
      } catch (error) {
        console.error("[itemMenu] Error !", error);
      }
    }
    this.images = images;
    this.imagesNoSound = imagesNoSound;
    if (item == optionsType.sound && Storage.getItem("muted") == "true")
      this.divImage = DOMHelper.createDivImg(box, null, "optionIcon", imagesNoSound.iconUnfocus);
    else this.divImage = DOMHelper.createDivImg(box, null, "optionIcon", images.iconUnfocus);
  }

  onFocused() {
    if (this.item == optionsType.sound && Storage.getItem("muted") == "true")
      this.divImage.style.background = `url(${this.imagesNoSound.iconFocus})`;
    else this.divImage.style.background = `url(${this.images.iconFocus})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }

  onUnfocused() {
    if (this.item == optionsType.sound && Storage.getItem("muted") == "true")
      this.divImage.style.background = `url(${this.imagesNoSound.iconUnfocus})`;
    else this.divImage.style.background = `url(${this.images.iconUnfocus})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }
}
