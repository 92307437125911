import { Keys } from "..";
import { Log } from "../log";
import { platform, PlatformType } from "../platform";

declare global {
  // extending keyboard event for LG
  interface KeyboardEvent {
    keyIdentifier: string;
  }
}

type KeyMap = { [key: string]: Keys };
type KeycodeMap = { [key: number]: Keys };

const keyCodeMapDefault: KeycodeMap = {
  8: Keys.back,
  65: Keys.back,
  57396: Keys.back,
  13: Keys.select,
  57397: Keys.select,

  37: Keys.left,
  38: Keys.up,
  39: Keys.right,
  40: Keys.down,

  48: Keys.zero,
  49: Keys.one,
  50: Keys.two,
  51: Keys.three,
  52: Keys.four,
  53: Keys.five,
  54: Keys.six,
  55: Keys.seven,
  56: Keys.eight,
  57: Keys.nine,

  403: Keys.red,
  404: Keys.green,
  405: Keys.yellow,
  406: Keys.blue,

  33: Keys.rewind,
  34: Keys.forward,
  36: Keys.play,
  35: Keys.pause,
  83: Keys.stop,
};

const keyCodeMapWebOS: KeycodeMap = {
  461: Keys.back,

  // ???
  502: Keys.yellow,

  33: Keys.pageUp,
  34: Keys.pageDown,

  412: Keys.rewind,
  417: Keys.forward,
  415: Keys.play,
  19: Keys.pause,
  413: Keys.stop,
};

const keyCodeMapTizen: KeycodeMap = {
  10009: Keys.back,

  427: Keys.pageUp,
  428: Keys.pageDown,

  412: Keys.rewind,
  417: Keys.forward,
  415: Keys.play,
  19: Keys.pause,
  413: Keys.stop,
  10252: Keys.playPause,
};

const keyCodeMapHisense: KeycodeMap = {
  502: Keys.yellow,
};

const keyCodeMapOrange: KeycodeMap = {
  166: Keys.back,
  178: Keys.stop,
  179: Keys.playPause,
  176: Keys.next,
  177: Keys.previous,
  227: Keys.rewind,
  228: Keys.forward,
};

const keyMap: KeyMap = {
  Up: Keys.up,
  Down: Keys.down,
  Left: Keys.left,
  Right: Keys.right,
  Enter: Keys.select,

  PageUp: Keys.pageUp,
  PageDown: Keys.pageDown,

  ChannelUp: Keys.pageUp,
  ChannelDown: Keys.pageDown,

  // webOS 3.x ?
  "U+0030": Keys.zero,
  "U+0031": Keys.one,
  "U+0032": Keys.two,
  "U+0033": Keys.three,
  "U+0034": Keys.four,
  "U+0035": Keys.five,
  "U+0036": Keys.six,
  "U+0037": Keys.seven,
  "U+0038": Keys.eight,
  "U+0039": Keys.nine,
  "U+0193": Keys.red,
  "U+0194": Keys.green,
  "U+0195": Keys.yellow,
  "U+0196": Keys.blue,
  "U+01CD": Keys.back,

  // webOS 5.x ?
  RCUBack: Keys.back,
  Pause: Keys.pause,
  "U+019F": Keys.play,

  // LG 2020
  GoBack: Keys.back,

  F1: Keys.red,
  F2: Keys.green,
  F3: Keys.yellow,
  F4: Keys.blue,
  "U+0042": Keys.back, // B for back - support for windows chromium 38

  // Tizen 5.x
  XF86Back: Keys.back,
  XF86Red: Keys.red,
  XF86Green: Keys.green,
  XF86Yellow: Keys.yellow,
  XF86Blue: Keys.blue,
  XF86AudioPause: Keys.pause,
  XF86AudioPlay: Keys.play,
  XF86AudioStop: Keys.stop,
  XF86AudioRewind: Keys.rewind,
  XF86AudioNext: Keys.forward,
  XF86PlayBack: Keys.playPause,
  XF86NextChapter: Keys.next,
  XF86PreviousChapter: Keys.previous,

  // tizen 3.x, keyIdentifier
  "U+280C": Keys.playPause,
  MediaPause: Keys.pause,
  MediaPlay: Keys.play,
  MediaStop: Keys.stop,
  MediaRewind: Keys.rewind,
  MediaFastForward: Keys.forward,

  //hisense
  VK_PLAY: Keys.play,
  VK_PAUSE: Keys.pause,
  VK_PLAY_PAUSE: Keys.playPause,
  VK_STOP: Keys.stop,
  VK_BACK_SPACE: Keys.back,

  // Orange
  BrowserBack: Keys.back,

  // Philips
  VK_ENTER: Keys.select,
  VK_LEFT: Keys.left,
  VK_RIGHT: Keys.right,
  VK_UP: Keys.up,
  VK_DOWN: Keys.down,
  VK_BACK: Keys.back,
  VK_RED: Keys.red,
  VK_BLUE: Keys.blue,
  VK_GREEN: Keys.green,
  VK_YELLOW: Keys.yellow,
  VK_FAST_FWD: Keys.forward,
  VK_REWIND: Keys.rewind,
  VK_0: Keys.zero,
  VK_1: Keys.one,
  VK_2: Keys.two,
  VK_3: Keys.three,
  VK_4: Keys.four,
  VK_5: Keys.five,
  VK_6: Keys.six,
  VK_7: Keys.seven,
  VK_8: Keys.eight,
  VK_9: Keys.nine,
};

export function mapKeyboardEvent(ev: KeyboardEvent): Keys {
  Log.keys.info(`mapKeyboardEvent: key: ${ev.key}, keyIdentifier(LG): ${ev.keyIdentifier}, keyCode: ${ev.keyCode}`);
  const keyFromKeyMap = keyMap[ev.keyIdentifier ?? ev.key];
  const keyFromKeycodeMap = (() => {
    switch (platform.type) {
      case PlatformType.webos:
        return keyCodeMapWebOS[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

      case PlatformType.tizen:
        return keyCodeMapTizen[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

      case PlatformType.hisense:
        return keyCodeMapHisense[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

      case PlatformType.orange:
        return keyCodeMapOrange[ev.keyCode] ?? keyCodeMapDefault[ev.keyCode];

      default:
        return keyCodeMapDefault[ev.keyCode];
    }
  })();
  return keyFromKeyMap ?? keyFromKeycodeMap ?? ev.keyIdentifier ?? ev.key;
}
