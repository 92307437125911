/**
 * this needs to be done right away at the top of our project entrypoint (usually main.ts)
 * we condition which polyfills to import based on the platform
 *
 * We're not allowed the Log object here as it's not yet created
 *
 */
"use strict";

export enum PlatformType {
  tizen = "Samsung TV",
  webos = "LG TV",
  hisense = "Hisense TV",
  philips = "Philips TV",
  orange = "Orange",
  other = "Smart TV (Generic)",
}

export type Platform = {
  type: PlatformType;
  version?: string;
  alternativeCSS: boolean;
  deviceName: string;
  deviceId: string;
  exit: () => void;
};

const tizenMatch = navigator.userAgent.match(new RegExp("Tizen", "i"));
const webOSMatch = navigator.userAgent.match(new RegExp("web(?:o|0)s", "i"));
const hisenseMatch =
  navigator.userAgent.match(new RegExp("vidaa", "i")) || navigator.userAgent.match(new RegExp("hisense", "i"));
const philipsMatch =
  navigator.userAgent.toLowerCase().match(new RegExp("philips", "i")) ||
  navigator.userAgent.toLowerCase().match(new RegExp("whaletv", "i"));
const orangeMatch =
  navigator.userAgent.match(new RegExp("SOPOpenBrowser", "i")) || navigator.userAgent.match(new RegExp("SAGEM", "i"));
// careful! any code here needs to work *without* polyfills!!
// String.includes for example doesn't. So do an initial first pass on the platform type to set the polyfills
let platformType: PlatformType;

switch (true) {
  case tizenMatch != null:
    platformType = PlatformType.tizen;
    require("core-js/es");
    break;

  case webOSMatch != null:
    platformType = PlatformType.webos;
    require("ts-polyfill");
    break;

  case hisenseMatch != null:
    platformType = PlatformType.hisense;
    require("core-js/es");
    break;

  case philipsMatch != null:
    platformType = PlatformType.philips;
    require("core-js/es");
    break;

  case orangeMatch != null:
    platformType = PlatformType.orange;
    require("core-js/es");
    break;

  default:
    platformType = PlatformType.other;
    require("core-js/es");
    break;
}

/**
 * at this point, we have polyfills
 */

/**
 * Some globals that aren't already defined (tizen ones are already typed from tizen-tv-webapis and tizen-common-web packages)
 */
declare global {
  interface Window {
    webOS?: {
      libVersion: string;
      platform: {
        tv: boolean;
      };
      platformBack: () => void;
    };
    Hisense_GetDeviceID?: () => string;
    Hisense_GetBrand?: () => string;
    SmartTvA_API?: {
      exit: () => void;
    };
  }
}

// require/import platform specific packages
switch (platformType) {
  case PlatformType.tizen:
    {
      try {
        // on tizen, this needs to be added
        // warning: I think it's an async op - webapis might
        // not be filled right after the script has been added to head
        const c = document.createElement("script");
        c.src = "$WEBAPIS/webapis/webapis.js";
        document.head.appendChild(c);
      } catch (error) {
        console.error("couldn't require webapis", error);
      }
      // Register keys for Tizen
      const usedKeys = [
        "MediaPause",
        "MediaPlay",
        "MediaPlayPause",
        "MediaFastForward",
        "MediaRewind",
        "MediaStop",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "ColorF0Red",
        "ColorF1Green",
        "ColorF2Yellow",
        "ColorF3Blue",
        "ChannelUp",
        "ChannelDown",
      ];

      usedKeys.forEach(function (keyName) {
        window.tizen.tvinputdevice.registerKey(keyName);
      });
    }
    break;

  case PlatformType.webos:
    require("../../webOSTV.js");
    break;
}

// we need those to save device name & ids persistently
import { CookieStorage } from "./helpers/cookieStorage";
export const Storage = "undefined" == typeof localStorage ? new CookieStorage() : localStorage;

const version = (() => {
  switch (platformType) {
    case PlatformType.tizen:
      return navigator.userAgent.match(new RegExp("Tizen ([^;)]*)"))?.[1] ?? undefined;

    case PlatformType.webos: {
      // parse version. assume highest by default as we don't know about future naming schemes but we know about past ones
      const chromeVersion = parseInt(navigator.userAgent.match(new RegExp("Chrome/([^.]*)"))?.[1] ?? "0");
      const safariVersion = parseInt(navigator.userAgent.match(new RegExp("Safari/([^.]*)"))?.[1] ?? "0");
      switch (chromeVersion) {
        case 53:
          return "4.x";
          break;

        case 38:
          return "3.x";
          break;

        default:
          if (chromeVersion == 0) {
            // then it must be 1 or 2?
            switch (safariVersion) {
              case 537:
                return "1.x";
                break;

              case 538:
                return "2.x";
                break;
            }
          }
          break;
      }
      return "5.x ";
    }
  }
})();

const deviceId = (() => {
  const saveDeviceId = Storage.getItem("osn_device_id");
  if (saveDeviceId) return saveDeviceId;

  const dateObject = new Date();
  const uniqueId = `${dateObject.getFullYear()}${dateObject.getMonth()}${dateObject.getDate()}${dateObject.getTime()}_${~~(
    Math.random() * 99999999
  )}`;

  // console.warn("osn_device_id", uniqueId);
  Storage.setItem("osn_device_id", uniqueId);
  return uniqueId;
})();

const deviceName = (() => {
  const saveDeviceName = Storage.getItem("osn_device_name");
  if (saveDeviceName) return saveDeviceName;

  let uniqueName = "";

  switch (platformType) {
    case PlatformType.hisense:
      uniqueName =
        `${window.Hisense_GetBrand?.() ?? "#unknown#"}_${~~(Math.random() * 99999999)}` ||
        `${PlatformType.other}_${~~(Math.random() * 99999999)}`;
      break;
    case PlatformType.tizen:
    case PlatformType.webos:
    case PlatformType.other:
      uniqueName = `${platformType}_${~~(Math.random() * 99999999)}`;
      break;
    default:
      console.log("Unsupported Device Name");
  }
  // console.warn("osn_device_name", uniqueName);
  Storage.setItem("osn_device_name", uniqueName);
  return uniqueName;
})();

const alternativeCSS = (() => {
  switch (platformType) {
    case PlatformType.tizen:
      return parseInt(version?.[0] ?? "0") <= 2;

    case PlatformType.webos:
      return parseInt(version?.[0] ?? "0") <= 3;

    default:
      return false;
  }
})();

export const platform: Platform = {
  type: platformType,
  version,
  deviceName,
  deviceId,
  alternativeCSS,
  exit: () => {
    switch (platform.type) {
      case PlatformType.webos:
        window.close();
        break;

      case PlatformType.tizen:
        window.tizen?.application.getCurrentApplication().exit();
        break;

      case PlatformType.hisense:
        window.close();
        break;

      case PlatformType.philips:
        if (window.SmartTvA_API) {
          window.SmartTvA_API.exit();
        } else {
          window.history.go(-999);
          window.close();
        }
        break;

      case PlatformType.orange:
        window.close();
        break;

      default:
        console.warn("won't quit, unknown platform");
    }
  },
};
