import "./searchHeroView.scss";

import { checkImage } from "~tools/tool";
import { DOMHelper, View } from "~ui-lib";

import { Program } from "../models/program";

export class SearchHeroView extends View {
  constructor(item: Program) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemSearchProgramVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemSearchProgramViewBoxZoom");
    const background = DOMHelper.createDivWithParent(box, null, "backgroundProgram");
    let elementImgSrc = require("~images/fallback/default_okoo.png");
    if (item.artworks) {
      const srcFromArtworks = item.getSquareImgUrl();
      if (srcFromArtworks !== "") {
        elementImgSrc = srcFromArtworks;
      }
    }
    checkImage(
      elementImgSrc,
      () => {
        DOMHelper.createDivImg(background, null, "elementImg", elementImgSrc);
      },
      () => {
        const backgroundFallback = DOMHelper.createDivWithParent(background, null, "backgroundProgramFallback");
        DOMHelper.createDivImg(
          backgroundFallback,
          null,
          "elementImg",
          require("~images/fallback/default_okoo_square.png")
        );
        DOMHelper.createDivWithParent(background, null, "titleProgramFallback", item.title);
      }
    );
  }
}
