/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map, mergeMap, toArray } from "rxjs/operators";

import { BrowsableItem } from "~models/browsableItem";
import { Category } from "~models/category";
import { Channel } from "~models/channel";
import { Collection } from "~models/collection";
import { Event } from "~models/event";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { User } from "~models/user";
import { ItemAge } from "~pages/gestionAge/itemAge";
import { ErrorPage } from "~pages/popup/errorPage";
import { platform, PlatformType } from "~ui-lib";

import { navigationStack } from "../main";
import { PlayableItem } from "../pages/rootPage";
import { AppConsents } from "../tools/cmp/didomi";
import { Config } from "./../config";
import { BackendErrorException, UnknownException } from "./exceptions";
import { parseDirects, parseHome, parseItems, parseMenu, parsePlayer } from "./parser";
import { Dictionary, Request, RequestMethod } from "./request";

const _k7DeviceParamValue = {
  [PlatformType.hisense]: "hisense" as const,
  [PlatformType.other]: "lg" as const,
  [PlatformType.tizen]: "samsung" as const,
  [PlatformType.webos]: "lg" as const,
  [PlatformType.orange]: "orange" as const,
  [PlatformType.philips]: "philips" as const,
}[platform.type];

/**
 * The Plugin class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 * it is the service used for request and all private datas needed in the project
 */
export class Plugin {
  private static instance: Plugin;

  request: Request;
  baseURL: string;
  playerURL: string;
  adsURL: string;
  didomi: typeof Config["didomi"]["prod" | "preprod"];
  user: User;

  /**
   * The Plugin's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    this.request = new Request();
    if (__BACKEND_TARGET__ === "prod") {
      this.baseURL = Config.server.prod;
      this.playerURL = Config.player.prod;
      this.adsURL = Config.ads.prod;
      this.didomi = Config.didomi.prod;
    } else {
      this.baseURL = Config.server.preprod;
      this.playerURL = Config.player.preprod;
      this.adsURL = Config.ads.preprod;
      this.didomi = Config.didomi.preprod;
    }

    this.user = new User();
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Plugin class while keeping
   * just one instance of each subclass around.
   */

  public static getInstance(): Plugin {
    if (!Plugin.instance) {
      Plugin.instance = new Plugin();
    }

    return Plugin.instance;
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */

  public fetch(
    action: string,
    method?: RequestMethod,
    params?: Dictionary<string>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchJson(this.baseURL + action, method, params, headers);
  }

  public fetchURL(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<unknown>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return this.request.fetchJson(url, method, params, headers);
  }

  public fetchStubs(name: string): Observable<string> {
    return this.request.readJson(name);
  }

  /**
   * Catch error from request BackendErrorException | UnknownException
   * Display/push popup error message on fullscreen
   */

  public fetchError(error: BackendErrorException | UnknownException): void {
    const description =
      error instanceof BackendErrorException ? (error.backendCode ? "(Erreur " + error.backendCode + ")" : "") : "";
    const topPage = navigationStack.topPage;
    navigationStack.pushPage(
      new ErrorPage(
        {
          title: error.message,
          description: description,
        },
        topPage
      )
    );
  }

  /**
   * Get dynamic menu items for navigation
   */
  public fetchMenu(): Observable<any> {
    // TODO: replace with fetch url when api will be created on FTV back-end
    return this.fetchStubs("stubs_menu").pipe(
      mergeMap(json => {
        return parseMenu(json);
      }),
      toArray(),
      map(items => {
        return items.sort((c1, c2) => {
          return c1.order - c2.order;
        });
      })
    );
  }

  public fetchAge(): Observable<any> {
    // TODO: replace with fetch url when api will be created on FTV back-end
    return this.fetch("/smarttv_okoo/age_selection?platform=smart_tv_okoo").pipe(
      mergeMap(json => {
        return parseMenu(json);
      }),
      toArray(),
      map(items => {
        return items.sort((c1, c2) => {
          return c1.age - c2.age;
        });
      })
    );
  }

  /**
   * Get CGU
   */
  public fetchCGU(): Observable<any> {
    return this.fetchStubs("cgu").pipe(toArray());
  }
  /**
   * Get mentions légale
   */
  public fetchMentionLegale(): Observable<any> {
    return this.fetchStubs("mention_legal").pipe(toArray());
  }
  /**
   * Get all swimlanes in Home Tab
   */
  public fetchHome(): Observable<any> {
    return this.fetch("/generic/page/_?platform=smart_tv").pipe(
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  /**
   * Get all ChildCategories for swimlane ChildCategories example
   */
  public fetchChildCategories(source?: ItemAge): Observable<any> {
    if (source == undefined) return EMPTY;
    return this.fetch(source.link + "?platform=smart_tv_okoo").pipe(
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  public fetchDefaultSearchByAge(source?: ItemAge): Observable<any> {
    if (source == undefined) return EMPTY;
    return this.fetch("/generic/enfants/" + source.age + "/programs?platform=smart_tv_okoo&size=999").pipe(
      mergeMap(json => {
        return parseItems(json);
      }),
      toArray()
    );
  }
  public fetchSearch(searchText?: string): Observable<any> {
    if (searchText == undefined) return EMPTY;
    return this.fetch("/generic/search/kids?platform=smart_tv_okoo&term=" + searchText).pipe(
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  /**
   * Get dynamic url for player
   */
  public fetchPlayer(item: Unit | Extrait | Integrale): Observable<JSON> {
    // Preprod: https://player.webservices.ftv-preprod.fr/v1/videos/538e5c16-c93e-46a6-9ddf-c1036a0424c7?device_type=smarttv&device=lg&domain=okoo&country_code=FR
    // Prod: https://player.webservices.francetelevisions.fr/v1/videos/ca509123-f5f8-49ed-916a-247fe29e4fbc?device_type=smarttv&device=lg&domain=okoo&country_code=FR

    if (item && item.extras && item.extras.si_id) {
      const url =
        this.playerURL +
        "/videos/" +
        item.extras.si_id +
        "?device_type=smarttv&device=" +
        _k7DeviceParamValue +
        "&domain=okoo&country_code=FR" +
        `&screen_w=600&screen_h=600`;
      return this.fetchURL(url).pipe(
        catchError((e: BackendErrorException | UnknownException) => {
          this.fetchError(e);
          return EMPTY;
        }),
        mergeMap((json: any) => {
          if (json.video && json.video.token && json.video.workflow == "token-akamai") {
            return this.fetchURL(json.video.token + "&url=" + json.video.url).pipe(
              mergeMap((tokenJson: any) => {
                json.video.url = tokenJson.url;
                return parsePlayer(json);
              })
            );
          }
          return parsePlayer(json);
        })
      );
    }
    return EMPTY;
  }

  /**
   * Get detailled home page for program, category or collection

   * program: http://api-mobile.yatta.francetv.fr/generic/program/france-2_la-faute-a-rousseau?platform=smart_tv
   * collections: http://api-mobile.yatta.francetv.fr/generic/collections/2298805?platform=smart_tv
   * category: https://api-mobile.yatta.francetv.fr/generic/page/films?platform=apps_tv
   * unitaire: video no live: http://api-mobile.yatta.francetv.fr/apps/contents/2261129/player?platform=smart_tv
   * event: https://api-mobile.yatta.ftv-preprod.fr/apps/events/sport_cyclisme_tour-de-france?platform=smart_tv
   * Unit no live will open player directly, same as Extrait: no detailled page before player
   * TODO: check if some items type Item exist but not handled in the switch case.
   * TODO: So far, I have only the infos for these 3 items
   */
  public fetchDetailled(item: BrowsableItem): Observable<any> {
    let path = "";

    if (item instanceof Program) {
      path = "/generic/program/" + item.extras.program_path;
    } else if (item instanceof Collection) {
      path = "/generic/collections/" + item.id;
    } else if (item instanceof Category) {
      path = "/generic/categories/" + item.extras.url_complete;
    } else if (item instanceof Unit && item.metadata && item.metadata.extras && !item.metadata.extras.is_live) {
      path = "/apps/contents/" + item.id + "/player";
    } else if (item instanceof Unit || item instanceof Integrale || item instanceof Extrait) {
      path = "/generic/contents/" + item.id;
    } else if (item instanceof Event) {
      path = "/apps/events/" + item.extras.url_complete;
    } else if (item instanceof Channel) {
      path = "/generic/channels/" + item.extras.channel_url;
    } else {
      return EMPTY;
    }

    return this.fetch(path + "?platform=smart_tv_okoo").pipe(
      mergeMap(json => {
        return parseHome(json);
      }),
      toArray()
    );
  }

  public fetchContents(contentId: string): Observable<BrowsableItem> {
    return this.fetch("/generic/contents/" + contentId + "?platform=smart_tv_okoo").pipe(
      mergeMap(json => {
        return parseItems(json);
      })
    );
  }

  public fetchProgram(programPath: string): Observable<Program> {
    return this.fetch("/generic/program/" + programPath + "?platform=smart_tv_okoo").pipe(
      mergeMap(json => {
        return parseItems(json);
      })
    );
  }

  public fetchNextEpisodes(item: PlayableItem): Observable<any> {
    if (item.metadata.extras.is_live)
      return this.fetch("/generic/directs?platform=smart_tv").pipe(
        mergeMap(json => {
          return parseDirects(json);
        }),
        toArray()
      );
    else
      return this.fetch("/apps/contents/" + item.id + "/next-episodes" + "?platform=smart_tv").pipe(
        mergeMap(json => {
          return parseHome(json);
        }),
        toArray()
      );
  }

  public getDidomiConsents(id: string, access_token: string): Observable<unknown> {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };

    return this.fetchURL(
      `${this.didomi.apiUrl}/v1/consents/users`,
      "GET",
      {
        organization_id: this.didomi.organizationId,
        organization_user_id: id,
      },
      headers
    ).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }

  public createDidomiAccessToken(): Observable<unknown> {
    const headers = { "Content-Type": "application/json" };
    const body = { type: "api-key", key: this.didomi.privateAPIKey, secret: this.didomi.secretAPIKey };

    return this.fetchURL(`${this.didomi.apiUrl}/v1/sessions/`, "POST", body, headers).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }

  public updateDidomiConsents(id: string, consents: AppConsents, access_token: string): Observable<any> {
    const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };

    return this.fetchURL(
      `${this.didomi.apiUrl}/v1/consents/events?organization_id=${this.didomi.organizationId}&organization_user_id=${id}`,
      "POST",
      {
        user: {
          organization_user_id: id,
          metadata: { custom_key: `${"notice_tvc_francetvc"}` },
        },
        consents,
      },
      headers
    ).pipe(
      mergeMap(json => {
        return of(json);
      })
    );
  }
}
