import { Storage } from "../libs/storage";

const STORAGE_AGE_KEY = "age";

export class User {
  private _age: number | undefined;

  constructor() {
    this._age = this.getUserAge();
  }

  getUserAge = () => {
    const storedAge = Storage.getItem(STORAGE_AGE_KEY);
    if (storedAge === null) {
      return undefined;
    } else {
      const ageNumber = parseInt(storedAge);

      if (isNaN(ageNumber)) {
        return undefined;
      } else {
        return ageNumber;
      }
    }
  };

  setUserAge = (age: number | undefined) => {
    if (age === undefined) {
      Storage.removeItem(STORAGE_AGE_KEY);
    } else {
      Storage.setItem(STORAGE_AGE_KEY, `${age}`);
    }
  };
}
