import { z } from "zod";

import { debounce, platform, PlatformType } from "../../bud-lite-tv/src/lib";
import { Plugin } from "../datas/plugin";
import { navigationStack } from "../main";
import { Collection } from "../models/collection";
import { Extrait } from "../models/extrait";
import { Integrale } from "../models/integrale";
import { Program } from "../models/program";
import { Unit } from "../models/unit";
import { HomeTab } from "../pages/home/homeTab";
import { WarningPopup } from "../pages/popup/warningPopup";
import { ProgramTab } from "../pages/program/programTab";
import { pushPlayerPage, pushTabWithMenu } from "../pages/rootPage";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";

/**
 * Example:
 * Program
 * #page/program/[program_path]
 * #page/program/france-5_masha-et-michka
 *
 * Collection
 * #page/collection/[id]
 * #page/collection/4843294 Thomas Pesquet : Objectif France
 *
 * Player
 * #player/unit/[id]
 * #player/unit/3614947 Les grandes vacances
 * #player/extrait/[id]
 * #player/integrale/[id]
 * #player/integrale/235125 Lego Ninjage s1e1
 * #player/channel/[channel_url]
 * #player/channel/france-4
 *
 * #player/integrale/5264385 (video with program)
 */

const pushErrorPage = (description: string) => {
  const errorPopup = new WarningPopup(description);
  navigationStack.pushPage(errorPopup);

  const _hide = debounce(() => {
    navigationStack.removePage(errorPopup);
  }, 5000);

  _hide();
};

type AppDeeplinkData =
  | {
      type: "page";
      pageType: "program";
      programPath: string;
    }
  | {
      type: "page";
      pageType: "collection";
      contentId: string;
    }
  | {
      type: "player";
      contentType: "integrale" | "unit" | "extrait";
      contentId: string;
    };

const parseRawDeeplink = (rawDeeplink: string): AppDeeplinkData | undefined => {
  const split = rawDeeplink.replace("#", "").split("/");
  const p0 = split[0];
  const p1 = split[1];
  const p2 = split[2];

  if (p0 === "page" && typeof p2 === "string") {
    if (p1 === "program") {
      return { type: p0, pageType: p1, programPath: p2 };
    }
    if (p1 === "collection") {
      return { type: p0, pageType: p1, contentId: p2 };
    }
  }

  if (p0 === "player" && typeof p2 === "string") {
    if (p1 === "integrale" || p1 === "unit" || p1 === "extrait") {
      return { type: p0, contentType: p1, contentId: p2 };
    }
  }

  Log.app.error("Parsing deeplinlk error :", rawDeeplink);
  return undefined;
};

const getDeeplinkTizen = (): AppDeeplinkData | undefined => {
  const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();
  /**
   * Deeplink data will always have the PAYLOAD key
   * Deeplink data is an array of items
   * Deeplink data will return either a string (stringified deeplink data) or undefined if no deeplinking has been done
   */
  const appControlData = requestedAppControl.appControl.data;
  Log.app.log("appControlData", appControlData);
  Log.app.log(
    "PAYLOAD",
    appControlData.find(item => item.key === "PAYLOAD")
  );
  const payloadRawData = appControlData.find(item => item.key === "PAYLOAD")?.value?.[0];
  Log.app.log("appControlData", payloadRawData);

  if (payloadRawData !== undefined) {
    try {
      const payloadRawDataValues = z.object({ values: z.string() }).parse(JSON.parse(payloadRawData)).values;
      Log.app.log("payloadRawDataValues", payloadRawDataValues);

      const deeplinkRawData = z
        .object({
          contentId: z.string(),
        })
        .parse(JSON.parse(payloadRawDataValues)).contentId;
      Log.app.log("getDeeplinkTizen raw data", deeplinkRawData);

      return parseRawDeeplink(deeplinkRawData);
    } catch (error) {
      Log.app.error("getDeeplinkTizen: Error parsing payload", payloadRawData, error);
      return undefined;
    }
  }
  return undefined;
};

const getDeeplinkOther = (): AppDeeplinkData | undefined => {
  const href = new URL(window.location.href);
  const deeplinkRawData = href.searchParams.get("deeplink");
  Log.app.log("getDeeplinkOther raw data", deeplinkRawData);
  if (deeplinkRawData !== null) {
    return parseRawDeeplink(deeplinkRawData);
  }
  return undefined;
};

class DeeplinkHandler {
  private _deeplinkData?: AppDeeplinkData;

  constructor() {
    // Deeplinking only occurs on Samsung
    if (platform.type === PlatformType.tizen) {
      /**
       * Samsung Smart Hub Preview Listener
       * Will be used to handle deeplinking when Tizen OS receives deeplink information
       * appcontrol events are only handled if the app was previously started
       * if the app was not launced before deeplink data has been received, appcontrol won't be triggered
       */
      window.addEventListener("appcontrol", this._activeDeeplinkListener);
    }
  }

  checkDeeplinkData = async () => {
    switch (platform.type) {
      case PlatformType.tizen:
        this._deeplinkData = getDeeplinkTizen();
        break;
      case PlatformType.other:
        this._deeplinkData = getDeeplinkOther();
        break;
      default:
        this._deeplinkData = undefined;
    }
  };

  private _activeDeeplinkListener = () => {
    this.checkDeeplinkData();
    this.handlePageDeeplink();
  };

  /**
   * Handling deeplink
   * @returns has DeeplinkOccured, true if navigationStack has changed, false if no deeplink occured
   **/
  public handlePageDeeplink = (): boolean => {
    //get current deeplink value
    if (this._deeplinkData === undefined) {
      return false;
    }
    switch (this._deeplinkData.type) {
      case "page":
        {
          switch (this._deeplinkData.pageType) {
            case "program": {
              // #page/program/france-3_lego-ninjago
              // worng path : page/program/france-3_lego-ninjago1231
              navigationStack.destroyStack();
              pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
              const programPath = this._deeplinkData.programPath;
              Plugin.getInstance()
                .fetchProgram(programPath)
                .subscribe(
                  value => {
                    if (value instanceof Program) {
                      const age = Plugin.getInstance().user.getUserAge();
                      if (age !== undefined && age >= value.extras.age_min) {
                        pushTabWithMenu(new ProgramTab(value), "ProgramPage");
                      } else {
                        pushErrorPage(
                          "Oups... Ce programme est uniquement disponible à partir de " + value.extras.age_min + " ans"
                        );
                      }
                    } else {
                      pushErrorPage("Oups... Ce programme n’est pas disponible actuellement");
                    }
                  },
                  () => {
                    pushErrorPage("Oups... Ce programme n’est pas disponible actuellement");
                  }
                );
              return true;
            }
            case "collection": {
              // #page/collection/2179057
              // wrong id : #page/collection/21790574546546546
              navigationStack.destroyStack();
              pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
              const collectionId = this._deeplinkData.contentId;
              Plugin.getInstance()
                .fetchContents(collectionId)
                .subscribe(
                  value => {
                    if (value instanceof Collection) {
                      const age = Plugin.getInstance().user.getUserAge();
                      if (age !== undefined && age >= value.extras.age_min) {
                        pushTabWithMenu(new ProgramTab(value), "ProgramPage");
                      } else {
                        pushErrorPage(
                          "Oups... Cette collection est uniquement disponible à partir de " +
                            value.extras.age_min +
                            " ans"
                        );
                      }
                    } else {
                      pushErrorPage("Oups... Cette collection n’est pas disponible actuellement");
                    }
                  },
                  () => {
                    pushErrorPage("Oups... Cette collection n’est pas disponible actuellement");
                  }
                );
              return true;
            }
          }
        }
        break;
      case "player":
        {
          // 8cc0443e-df60-4a1d-8bde-628ecf30cdab
          // #player/unit/3614947
          // #player/integrale/1119685
          // not available: #player/integrale/43717 (no si_id and begin_date null)
          // wrong id : #player/integrale/43717123121
          navigationStack.destroyStack();
          pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
          const contentId = this._deeplinkData.contentId;
          Plugin.getInstance()
            .fetchContents(contentId)
            .subscribe(
              value => {
                if (value instanceof Unit || value instanceof Integrale || value instanceof Extrait) {
                  const hasProgram = value.extras?.program ? true : false;
                  if (hasProgram) {
                    const program = value.extras.program;
                    if (program instanceof Program) {
                      const age = Plugin.getInstance().user.getUserAge();
                      if (age !== undefined && age >= program.extras.age_min) {
                        pushTabWithMenu(new ProgramTab(program, contentId), "ProgramPage");
                        pushPlayerPage(value);
                      } else {
                        pushErrorPage(
                          "Oups... Cette vidéo est uniquement disponible à partir de " + program.extras.age_min + " ans"
                        );
                      }
                    }
                  } else {
                    pushPlayerPage(value);
                  }
                } else {
                  pushErrorPage("Oups... Cette vidéo n’est pas disponible actuellement");
                  Log.api.error("Deeplink error: contentId", contentId, "is not playable.");
                }
              },
              error => {
                // Here use it to trigger and display an error
                pushErrorPage("Oups... Cette vidéo n’est pas disponible actuellement");
                Log.api.error("[DEEPLINK TO PLAYER] Error !", error);
              },
              () => {}
            );
          return true;
        }
        break;
    }
  };
}

export const deepLinkHandlerSingleton = new DeeplinkHandler();
