import "./exitPopup.scss";

import { Keys, platform } from "~ui-lib";

import { WarningPopup } from "./warningPopup";

export class ExitPopup extends WarningPopup {
  constructor() {
    super("Taper de nouveau pour quitter Okoo.");
  }

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.back:
        platform.exit();
        return true;
    }
    return false;
  };
}
