import { ILogger, initNamespacedLog } from "@dotscreen/log-tv";

import { Keys } from "../bud-lite-tv/src/lib";

// some default namespaces to separate log entries
const namespaces = ["player", "api", "analytics", "app", "ads"] as const;
declare global {
  type NamespacedLog = { [key in typeof namespaces[number]]: ILogger };
  const Log: NamespacedLog;
  interface Window {
    Log: NamespacedLog;
  }
}

window.Log = initNamespacedLog({
  /*sentry: __IS_RELEASE__
    ? {
        // from okoo-tv-smart-tv project: https://sentry.io/organizations/dotscreen/issues/?project=5732502#welcome
        // https://sentry.io/settings/dotscreen/projects/okoo-smart-tv/keys/
        DSN: "https://e3c20cd4a688428290b8f317d8d3d055@o207455.ingest.sentry.io/5732502",
        release: __APP_VERSION__,
      }
    : undefined,*/
  sentry: undefined,
  menuTriggerSequences: [
    ["Control"],
    __IS_RELEASE__ ? [Keys.blue, Keys.yellow, Keys.green, Keys.blue, Keys.green] : [Keys.blue],
  ],
  remotePrefix: "Okoo", // TODO: use something else, related to the project
  namespaces,
  remapConsoleToNamespace: "_",
  rootElementId: "mainContent",
  levels: require("../logLevels.json"), // the namespaces for the Log object
  // as sentry is undefined, on RELEASE there will be no log.
  // This is important because console.log can create "memory leaks" because garbageCollector will not remove references to objects that are log in the console
  initialLogOutput: __IS_RELEASE__ ? "sentry" : "console",
}) as NamespacedLog;
