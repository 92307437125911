import "./videoPlayerSwimlane.scss";

import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Unit } from "~models/unit";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { PlayerPage } from "../pages/player/playerPage";
import { pushPlayerPage } from "../pages/rootPage";
import { VideoView } from "../views/videoView";

export class VideoPlayerSwimlane extends View {
  private _selectedContent: PlayerPage;
  constructor(source: ItemCollection, currentID: string, selectedContent: PlayerPage, isLiveSwimlane = false) {
    super(DOMHelper.createDivWithParent(null, "VideoPlayerSwimlane" + source.id, "videoPlayerSwimlane"));
    this._selectedContent = selectedContent;
    if (isLiveSwimlane) DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Unit | Integrale)[]),
      viewFactory: model => {
        return new VideoView(model, true, currentID == model.id ? true : false);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: this._onItemSelect,
    });
  }
  private _onItemSelect = (item: Unit | Integrale, index: number) => {
    pushPlayerPage(item, index);
    this._selectedContent.exitPlayer();
    // must display element videoBackground deactivate when player is closed
    const DOMVideo = document.getElementById("videoBackground");
    if (DOMVideo) {
      DOMVideo.classList.add("active");
      DOMVideo.hidden = false;
    }
    return true;
  };
}
