export const delay = (time: number): Promise<void> => new Promise(resolve => setTimeout(resolve, time));

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
): ((...args: Parameters<F>) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

export const throttle = <F extends (...args: any[]) => any>(
  func: F,
  limit: number
): { func: (...args: Parameters<F>) => void; abort: () => void } => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const throttled = (...args: Parameters<F>) => {
    // eslint-disable-next-line no-empty
    if (timeout !== null) {
    } else {
      func(...args);
      timeout = setTimeout(() => {
        timeout = null;
      }, limit);
    }
  };

  return {
    func: throttled,
    abort: () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    },
  };
};
