import { IDelegate } from ".";

export interface IView extends IDelegate {
  /** the DOM element managed by this view
   */
  rootElement: HTMLElement;
  /** if true, the list will cache this view when resetting its content - won't be recreated
   */
  cacheable?: boolean;
  /** the delegate which will handle release, focus, show, hide, nav functions if the view doesn't implement them
   */
  delegate?: IDelegate;
}

export class View implements IView {
  rootElement: HTMLElement;
  cacheable = false;
  private _delegate?: IDelegate;

  constructor(rootElement: HTMLElement) {
    this.rootElement = rootElement;
    // make it possible to bubble up from views to views via the DOM
    this.rootElement._dsView = this;
  }

  get delegate(): IDelegate | undefined {
    return this._delegate;
  }

  set delegate(newDelegate: IDelegate | undefined) {
    if (this.delegate)
      throw `the view ${this.rootElement.id} already has a delegate!!! (${
        (this.delegate as any)?.rootElement?.id ?? "<unknown>"
      })`;
    this._delegate = newDelegate;
  }
}

export interface IPage extends IView {
  /** for a page to show up on top of another one, without hiding the previous one, it needs to be a "popup".
   * define this function to return true if it's the wanted behavior
   * @default false if not defined
   */
  isPopup?: () => boolean;
}
