import "./verticalArrow.scss";

import { DOMHelper, View } from "~ui-lib";

class VerticalArrow extends View {
  constructor(direction: "prev" | "next") {
    super(DOMHelper.createDivWithParent(null, null, "verticalArrow " + direction));
    DOMHelper.createDivWithParent(this.rootElement, null, "text", direction == "prev" ? "<" : ">");
  }
}

export const verticalArrowFactory = (direction: "prev" | "next") => new VerticalArrow(direction);
