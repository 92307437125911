import "./cguPage.scss";

import { Plugin } from "~pages/../datas/plugin";
import { DOMHelper, IPage, Keys, throttle } from "~ui-lib";

import { settingType } from "../../swimlaneViews/cardSettingsSwimlane";
import { sendPianoAnalytic } from "../../tools/analytics/piano";

const cguSource = async (parent: HTMLElement, text: string) => {
  const termsInfo = text;
  const newElement = document.createElement("div");
  newElement.innerHTML = termsInfo;
  parent.appendChild(newElement);
};

const scrollsize = (rootHeight: number, scrollHeight: number) => {
  const numberscroll = rootHeight / (1080 / 3);
  const ret = scrollHeight / Math.floor(numberscroll);
  return ret;
};

export class CGUPage implements IPage {
  rootElement: HTMLElement;
  textBox: HTMLElement;
  scrollRoot: HTMLElement;
  scroll: HTMLElement;
  offset = 0;
  offsetScroll = 0;
  sizeScroll = 0;
  settingType: settingType;
  private readonly _pianoPageName: "cgu" | "mentions_legales";

  constructor(source: settingType.cgu | settingType.mentionsLegales) {
    this.settingType = source;
    this.rootElement = DOMHelper.createDivWithParent(null, null, "cguPageRoot");
    const wheelHandler = (ev: WheelEvent): void => {
      this.termsNav(ev.deltaY < 0);
    };
    this.rootElement.addEventListener("wheel", throttle(wheelHandler, 200).func);
    this.textBox = DOMHelper.createDivWithParent(this.rootElement, null, "textBox");
    this.scrollRoot = DOMHelper.createDivWithParent(this.rootElement, null, "scrollRoot");
    this.scroll = DOMHelper.createDivWithParent(this.scrollRoot, null, "scroll");
    switch (source) {
      case settingType.cgu:
        this._fetchSourceCGU();
        this._pianoPageName = "cgu";
        break;

      case settingType.mentionsLegales:
        this._fetchSourceMention();
        this._pianoPageName = "mentions_legales";
        break;
    }
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: this._pianoPageName, page_type: "autre" }, {});
  }

  private _fetchSourceCGU() {
    Plugin.getInstance()
      .fetchCGU()
      .subscribe(
        (value: any) => {
          // Here use it to create the UI
          console.log("[DETAILLED] Next !", value);
          cguSource(this.textBox, value[0].resources.cgu).then(res => {
            this.sizeScroll = scrollsize(
              this.textBox.clientHeight,
              this.scrollRoot.clientHeight - this.scroll.clientHeight
            );
          });
        },
        (error: any) => {
          // Here use it to trigger and display an error
          console.log("[DETAILLED] Error !", error);
        },
        () => {
          console.log("[DETAILLED] Complete !");
        }
      );
  }
  private _fetchSourceMention() {
    Plugin.getInstance()
      .fetchMentionLegale()
      .subscribe(
        (value: any) => {
          // Here use it to create the UI
          console.log("[DETAILLED] Next !", value);
          cguSource(this.textBox, value[0].resources.mention_legal).then(res => {
            this.sizeScroll = scrollsize(
              this.textBox.clientHeight,
              this.scrollRoot.clientHeight - this.scroll.clientHeight
            );
          });
          // this._onSourceReady(value[0].items);
        },
        (error: any) => {
          // Here use it to trigger and display an error
          console.log("[DETAILLED] Error !", error);
        },
        () => {
          console.log("[DETAILLED] Complete !");
        }
      );
  }

  public termsNav(up: boolean) {
    let offset = this.offset;
    let offsetScroll = this.offsetScroll;
    if (up) {
      offsetScroll -= this.sizeScroll;
      offset += 0.5 * 720;
    } else {
      offsetScroll += this.sizeScroll;
      offset -= 0.5 * 720;
    }
    if (offset >= -this.textBox.clientHeight && offset <= 0) {
      this.offset = offset;
      this.offsetScroll = offsetScroll;
      this.textBox.style.marginTop = `${this.offset}px`;
      this.scroll.style.marginTop = `${this.offsetScroll}px`;
    }
  }

  // forward all nav & focus to the list component
  onNav = (key: Keys) => {
    switch (key) {
      // case DS.keys.enter:
      //   return false;
      case Keys.down:
        this.termsNav(false);
        return true;
      case Keys.up:
        this.termsNav(true);
        return true;

      default:
        return false; //this.listComponent.onNav(key);
    }
  };
  onFocused = () => {
    DOMHelper.addClass(this.scroll, "focus");
  };
}
