/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, Observer } from "rxjs";
import request from "superagent";

import { BackendErrorException, UnknownException } from "./exceptions";

export interface Dictionary<T> {
  [Key: string]: T;
}

type ProxyMappings = { [url: string]: string };
let proxyMappings: ProxyMappings = {};

export type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

try {
  if (__BACKEND_TARGET__ == "proxy") proxyMappings = require("../../.proxy/mappings.json");
  // eslint-disable-next-line no-empty
} catch {}

export class Request {
  timeoutDelay: number;

  constructor() {
    this.timeoutDelay = 30000;
  }

  public fetchJson(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<unknown>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const agent = request.agent();
      const reqMethod = method ? method : "GET";

      const callback = (error: any, res?: request.Response) => {
        if (error) {
          // This is an error but we need to handle the response from back-end
          if (res && res.body) {
            const message = res.body.message ? res.body.message : "";
            const code = res.body.show_code && res.body.code ? res.body.code : "";
            observer.error(new BackendErrorException(message, "", code, url));
          } else {
            observer.error(new BackendErrorException(error.message, "", error.code, url));
          }
        } else if (res && res.ok && res.body) {
          observer.next(res.body);
          observer.complete();
        } else {
          observer.error(new UnknownException("Unexpected method: " + (res ? res.text : "")));
        }
      };

      // if check if proxied
      Object.keys(proxyMappings).forEach(proxiedUrl => {
        url = url.replace(proxiedUrl, proxyMappings[proxiedUrl]);
      });

      switch (reqMethod) {
        case "GET":
          agent
            .get(url)
            .withCredentials()
            .query(params ? params : {})
            .set(headers ? headers : {})
            .timeout(this.timeoutDelay)
            .end(callback);
          break;
        case "POST":
          agent
            .post(url)
            .withCredentials()
            .send(params ? params : {})
            .set(headers ? headers : {})
            .timeout(this.timeoutDelay)
            .end(callback);
          break;
        default:
          observer.error(new UnknownException("Unexpected method: " + method));
          return;
      }

      return () => {
        /*
         * when the subscriber called unsubscribe
         * so let's cancel it.
         */
        if (agent && agent.abort) {
          agent.abort();
        }
      };
    });
  }

  public fetchTxtJson(
    url: string,
    method?: RequestMethod,
    params?: Dictionary<string>,
    headers?: Dictionary<string>
  ): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const agent = request.agent();
      const reqMethod = method ? method : "GET";

      const callback = (error: any, res?: request.Response) => {
        if (error) {
          observer.error(new BackendErrorException(error.message, "", error.code, url));
        } else if (res && res.ok && res.text) {
          observer.next(res.text);
          observer.complete();
        } else {
          observer.error(new UnknownException("Unexpected method: " + (res ? res.text : "")));
        }
      };

      // if check if proxied
      Object.keys(proxyMappings).forEach(proxiedUrl => {
        url = url.replace(proxiedUrl, proxyMappings[proxiedUrl]);
      });

      switch (reqMethod) {
        case "GET":
          agent
            .get(url)
            //.withCredentials()
            .query(params ? params : {})
            .set(headers ? headers : {})
            .timeout(this.timeoutDelay)
            .end(callback);
          break;
        case "POST":
          agent
            .post(url)
            //.withCredentials()
            .send(params ? params : {})
            .set(headers ? headers : {})
            .timeout(this.timeoutDelay)
            .end(callback);
          break;
        default:
          observer.error(new UnknownException("Unexpected method: " + method));
          return;
      }

      return () => {
        /*
         * when the subscriber called unsubscribe
         * so let's cancel it.
         */
        if (agent && agent.abort) {
          agent.abort();
        }
      };
    });
  }

  public readJson(name: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const data = require("~stubs/" + name + ".json");
      observer.next(data);
      observer.complete();
    });
  }

  //===================================== XML HELPERS ==================================================

  // L'objet Node est inconnu pour samsung !
  /** @const @enum */
  XMLNodeType =
    window.Node && 1 === Node.ELEMENT_NODE
      ? Node
      : {
          ELEMENT_NODE: 1,
          ATTRIBUTE_NODE: 2,
          TEXT_NODE: 3,
          CDATA_SECTION_NODE: 4,
          //		ENTITY_REFERENCE_NODE : 5,
          //		ENTITY_NODE : 6,
          //		PROCESSING_INSTRUCTION_NODE : 7,
          //		COMMENT_NODE : 8,
          //		DOCUMENT_NODE : 9,
          //		DOCUMENT_TYPE_NODE : 10,
          //		DOCUMENT_FRAGMENT_NODE : 11,
          //		NOTATION_NODE : 12
        };

  /*
   ** Convert XML DOM to object
   */
  public processXML(xmlNode: any): any {
    const nodes: any = {};

    if (xmlNode?.attributes) {
      for (let i = 0; i < xmlNode.attributes.length; i++) {
        const attribute = xmlNode.attributes[i];
        nodes[attribute.nodeName] = [];
        nodes[attribute.nodeName] = attribute.nodeValue;
      }
    }

    if (xmlNode?.childNodes) {
      const max = xmlNode.childNodes.length;
      if (xmlNode.hasChildNodes()) {
        for (let j = 0; j < max; j++) {
          const anode = xmlNode.childNodes[j];
          let index = 0;

          switch (anode.nodeType) {
            case this.XMLNodeType.ELEMENT_NODE:
              if (Object.prototype.hasOwnProperty.call(nodes, anode.nodeName)) {
                index = nodes[anode.nodeName].length;
              } else {
                nodes[anode.nodeName] = [];
              }

              nodes[anode.nodeName][index] = this.processXML(anode);
              break;

            case this.XMLNodeType.ATTRIBUTE_NODE:
              break;

            case this.XMLNodeType.CDATA_SECTION_NODE:
            case this.XMLNodeType.TEXT_NODE:
              // eslint-disable-next-line no-case-declarations
              const value = anode.nodeValue.replace(/^\s+$/, "");
              if (value !== "") {
                nodes.$ = value;
              }
              break;
          }
        }
      }
    }

    return nodes;
  }
}
