import { isHtmlElement } from "./HTMLElementHelper";

let stats: any;
let domPanel: any;
let mode = 0;

export function showDebugStats(className?: string): void {
  if (!stats) {
    const Stats = require("stats.js");
    stats = new Stats();

    // regular FPS/memory update loop
    requestAnimationFrame(function loop() {
      if (stats) {
        stats.update();
        requestAnimationFrame(loop);
      }
    });

    // our extra panel counting DOM elements
    domPanel = new Stats.Panel("", "#ff0", "#002");
    stats.addPanel(domPanel);
    const TIMEOUT = 1000;
    setTimeout(function timeoutFunc() {
      // Only update DOM node graph when we have time to spare to call
      // numDomNodes(), which is a fairly expensive function.
      if (domPanel) {
        domPanel.update(document.getElementsByTagName("*").length, 1500);
        setTimeout(timeoutFunc, TIMEOUT);
      }
    }, TIMEOUT);

    const statsDom = stats.dom as HTMLElement;
    stats.showPanel(statsDom.childElementCount - 1);

    if (className) {
      statsDom.className = className;
      statsDom.style.cssText = "";
    }

    // hack for enforcing LTR stat display
    for (let panel = statsDom.firstElementChild; panel; panel = panel.nextElementSibling) {
      if (isHtmlElement(panel)) panel.dir = "ltr";
    }
    document.body.appendChild(stats.dom);
  }
}

export function hideDebugStats(): void {
  if (stats) {
    try {
      (stats.dom as HTMLElement).parentElement?.removeChild(stats.dom);
    } catch (error) {
      //
    }
    stats = undefined;
    domPanel = undefined;
  }
}

export function cycleDebugStats(): void {
  if (stats) {
    stats.showPanel(++mode % stats.dom.children.length);
  }
}
