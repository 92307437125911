import "./dynamicBackground.scss";

import { BrowsableItem } from "~models/browsableItem";
import { ItemCollection } from "~models/itemCollection";
import { DOMHelper, IListComponent } from "~ui-lib";

import { ItemAge } from "./gestionAge/itemAge";

export enum DynamicBackgroundOverlayType {
  none = "",
  blur = "blur",
  gradient = "gradient",
  unitGradient = "unitGradient",
}

export class DynamicBackground {
  private _backgroundElt: HTMLElement;
  private _overlayElt: HTMLElement;
  private _backgroundTimer = 0;
  private _opIDGenerator = 0;
  private _itemCollectionList?: IListComponent<ItemCollection>;
  private _focusedViewUnregister?: () => void;
  private _swimlaneFocusedIdUnregister?: () => void;

  constructor(
    root: HTMLElement,
    overlay: DynamicBackgroundOverlayType = DynamicBackgroundOverlayType.gradient,
    defaultSrc?: string
  ) {
    this._backgroundElt = DOMHelper.createDivWithParent(root, "dynamicBackground");
    this._overlayElt = DOMHelper.createDivWithParent(this._backgroundElt, "dynamicBackgroundOverlay", overlay);
    if (defaultSrc) {
      this._src(defaultSrc);
    }
  }

  private _src = (src: string) => {
    this._backgroundElt.style.backgroundImage = `url("${src}")`;
    this._backgroundElt.style.opacity = "1";
  };

  animSrc = (src: string, duration: number) => {
    this._backgroundElt.style.opacity = "0";
    window.clearInterval(this._backgroundTimer);
    const opId = ++this._opIDGenerator;

    this._backgroundTimer = window.setTimeout(() => {
      if (src === undefined || src === "") {
        this._backgroundElt.style.backgroundImage = "";
        return;
      }
      const bgImg = new Image();
      bgImg.onload = () => {
        bgImg.onload = null;
        if (opId == this._opIDGenerator) {
          this._src(bgImg.src);
        }
      };
      bgImg.src = src;
    }, duration);
  };

  setOverlay = (overlay: DynamicBackgroundOverlayType) => {
    this._overlayElt.classList.value = overlay;
  };

  private _updateBackgroundOnFocusChange = () => {
    const swimlaneList = this._itemCollectionList?.focusedView$.value?.delegate as
      | IListComponent<BrowsableItem>
      | undefined;
    const model = swimlaneList?.modelFromId(swimlaneList.focusedId$.value);
    if (model) {
      this.animSrc(model?.getBackgroundImgUrl?.() ?? "", 500);
    }
  };

  setItemCollectionList = (list: IListComponent<ItemCollection>) => {
    this._itemCollectionList = list;
    this._focusedViewUnregister = list.focusedView$.didChange((newV, oldV) => {
      // when the current swimlane focus change, unregister the focus listener from the previous swimlane & register a new one
      this._swimlaneFocusedIdUnregister?.();
      // update the background image (as we changed focus from one swimlane to another)
      this._updateBackgroundOnFocusChange();
      // and register focus changes on the new swimlane
      this._swimlaneFocusedIdUnregister = (newV?.delegate as
        | IListComponent<BrowsableItem>
        | undefined)?.focusedId$.didChange(this._updateBackgroundOnFocusChange);
    });
  };

  setAgeCollectionList = (list: IListComponent<ItemAge>) => {
    this._focusedViewUnregister = list.focusedView$.didChange((newV, oldV) => {
      const model = list?.modelFromId(list.focusedId$.value);
      if (model) {
        this.animSrc(model.background, 800);
      }
    });
  };

  onRelease = () => {
    this._focusedViewUnregister?.();
    this._swimlaneFocusedIdUnregister?.();
  };
}
