/* eslint-disable @typescript-eslint/no-explicit-any */
import { Log } from "../log";
import { IView } from "../types";

// all the delegate functions we can call
type delegateFunctions = "onRelease" | "onNav" | "onFocused" | "onUnfocused" | "onShown" | "onHidden" | "onMouseMove";
const returningFunctions = ["onNav"];

export const callWithDelegateFallback = <V extends IView, F extends (...args: any[]) => ReturnType<F>>(
  obj: V | undefined,
  funcName: delegateFunctions,
  ...args: any[]
): ReturnType<F> | undefined => {
  let current: any = obj;
  const returning = returningFunctions.includes(funcName);
  while (current) {
    const func = current[funcName];
    if (func && typeof func == "function") {
      // bind in order to make sure it's the right "this" when we call the function
      const boundFunc = func.bind(current);
      let results;
      try {
        results = boundFunc(...args);
      } catch (error) {
        Log.ui.error(`callWithDelegateFallback ${funcName} error: ${error}`);
      }
      // if it's a returning function, drill down until we've exhausted delegates
      // otherwise, just return
      if (returning && results) return results;
    }
    current = current.delegate as any;
  }
};
