import "./cardSettingsSwimlane.scss";

import { CGUPage } from "~pages/cgu/cguPage";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../bud-lite-tv/src/lib";
import { navigationStack } from "../main";
import { ParametersTab } from "../pages/cmp/parameters/parametersPage";
import { PrivatePolicyTab } from "../pages/cmp/privacyPolicy/privacyPolicy";
import { CardSettingsView } from "../views/settings/cardSettingsView";

export enum settingType {
  mentionsLegales = "mentions légales",
  cgu = "CGU",
  cmp = "gérer mes traceurs",
  politique = "politique de confidentialité",
  licences = "licences",
}

export class cardSettingsSwimlane extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, null, ""));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource([
        settingType.mentionsLegales,
        settingType.cgu,
        settingType.politique,
        settingType.cmp,
      ]),
      viewFactory: model => new CardSettingsView(model),
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: this.onSelect,
    });
  }
  onSelect = (model: settingType): boolean => {
    switch (model) {
      case settingType.cmp:
        navigationStack.pushPage(new ParametersTab());
        return true;

      case settingType.cgu:
        navigationStack.pushPage(new CGUPage(model));
        return true;

      case settingType.mentionsLegales:
        navigationStack.pushPage(new CGUPage(model));
        return true;

      case settingType.politique:
        navigationStack.pushPage(new PrivatePolicyTab());
        return true;
    }
    return false;
  };
}
