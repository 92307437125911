/* eslint-disable @typescript-eslint/no-explicit-any */
import { Artwork } from "~models/artwork";
import { BrowsableItem } from "~models/browsableItem";
import { Media } from "~models/media";
import { Metadata } from "~models/metadata";
import { IModel } from "~ui-lib";

export class Unit extends BrowsableItem implements IModel {
  metadata: Metadata;
  media: Media | null;

  constructor(
    id: string,
    type: string,
    title: string,
    summary: any,
    artworks: Array<Artwork>,
    metadata: Metadata,
    extras: any,
    media: Media | null
  ) {
    super(id, type, title, summary, artworks, extras);

    this.metadata = metadata;
    this.media = media;
  }
}
