import { IListComponent, Listenable, request, RequestOptions } from "..";
import { Log } from "../log";
import { IReleasable } from "../types";

export interface IModel {
  id: string;
}

export type IModelSource<M> = IReleasable & {
  /**
   * The version of the data in the model. Every time the data is reset, the version is incremented
   * This is used to cancel a list refresh sequence (which is async) of the source version changes in the meantime (ListenableSource mainly)
   */
  readonly version: number;
  /**
   * @description function to fetch data from a source
   * @param upToIndex how many elements to retrieve (on top of what's been returned already). If undefined, consider everything will be returned
   * @returns index component
   */
  fetch(count?: number): Promise<M[]>;

  /**
   * @description should return true if there aren't any items to retrieve
   */
  isEmpty(): Promise<boolean>;
  /**
   * @description should return true if all items have been fetched
   */
  isComplete(): boolean;

  /**
   * @description reset the internal pointers so that data can be fetched again from the source
   * The contract needs to be that any reset call will increment the version
   */
  reset(): void;

  listComponent?: IListComponent;
};

export class StaticModelSource<M> implements IModelSource<M> {
  source: Promise<M[]> | M[];
  remainingSource: M[] | undefined;
  version = 0;

  constructor(source: Promise<M[]> | M[]) {
    this.source = source;
  }
  onRelease(): void {}

  async fetch(count?: number): Promise<M[]> {
    if (this.remainingSource == undefined) {
      this.remainingSource = await this.source;
    }

    if (count == undefined) {
      const returnedData = this.remainingSource;
      this.remainingSource = [];
      return returnedData;
    } else {
      const returnedData = this.remainingSource.slice(0, count);
      this.remainingSource = this.remainingSource.slice(count);
      return returnedData;
    }
  }

  async isEmpty(): Promise<boolean> {
    if (this.remainingSource == undefined) {
      this.remainingSource = await this.source;
    }
    return this.remainingSource.length == 0;
  }
  isComplete(): boolean {
    return this.remainingSource?.length == 0;
  }

  reset(): void {
    this.remainingSource = undefined;
    this.version++;
  }
}

export class ListenableSource<M> extends StaticModelSource<M> {
  source$: Listenable<M[]>;
  listComponent?: IListComponent;

  unregisterListener: () => void;

  /**
   * create a model source from a listenable. The specificity is that if the listenable changes, the source automatically updates the list it's the source for, with the provided parameters
   * @param listenable the listenable to use as a source
   * @param keepSelectionOnChange (defaults to false) if true, tries to set the selection (and therefore the scroll index) to where it was before reset. WARNING: if the focused ID is not in the new model array, the whole source is fetched/read.
   * @param animateOnChange (defaults true) if true, any scrolling related to resetting the content will be animated
   */
  constructor(listenable: Listenable<M[]>, keepSelectionOnChange?: boolean, animateOnChange?: boolean) {
    super(listenable.value);
    this.source$ = listenable;
    // TODO: onRelease!
    this.unregisterListener = this.source$.didChange(value => {
      // if source changed, update our source, reset our state
      this.source = value;
      this.listComponent?.resetContent(keepSelectionOnChange, animateOnChange);
    });
  }

  onRelease(): void {
    this.unregisterListener();
  }
}

/**
 * @description Parameters for fetchModelSource
 * @param urlGenerator a function taking a page number in, returning a string to fetch
 * constraints on the api: it needs to return an array of objects, which will be modeled as M by the modelFactory
 * @param modelFactory from a json object, generate our model
 * @param method optional. The request method, defaults to GET
 * @param headers optional. the list of headers to pass to the POST/GET request. Defaults to empty
 */
export interface FetchModelSourceParams<M> extends RequestOptions {
  urlGenerator: (page: number) => string;
  modelFactory: (json: unknown) => [M[], boolean];
}

export class FetchModelSource<M> implements IModelSource<M> {
  // initial params
  params: FetchModelSourceParams<M>;

  // accumulated state
  data: M[] | undefined;
  version = 0;

  lastReturnedIndex = 0;
  pageToFetch = 0;
  complete = false;

  constructor(params: FetchModelSourceParams<M>) {
    this.params = params;
  }
  onRelease(): void {}

  async fetch(count?: number): Promise<M[]> {
    if (!this.data) this.data = [];

    const firstReturnedIndex = this.lastReturnedIndex;
    // retrieve enough data
    while (!this.complete && this.data.length - firstReturnedIndex < (count || Number.MAX_SAFE_INTEGER)) {
      // need to fetch more. We know our page size, we know how many items we already have
      const url = this.params.urlGenerator(this.pageToFetch);
      this.pageToFetch++;
      try {
        const response = await request(url, this.params);
        const [additionalData, complete] = this.params.modelFactory(response.json());
        this.data = [...this.data, ...additionalData];
        this.complete = complete;
      } catch (error) {
        Log.net.error("modelSource fetch error", error);
        // just stop on error
        this.complete = true;
      }
    }

    // now we have enough data! return what was asked for
    const additionalDataCount = Math.min(count || Number.MAX_SAFE_INTEGER, this.data.length - firstReturnedIndex);
    const results = this.data.slice(this.lastReturnedIndex, this.lastReturnedIndex + additionalDataCount);
    this.lastReturnedIndex += additionalDataCount;
    return results;
  }

  async isEmpty(): Promise<boolean> {
    if (!this.data) {
      await this.fetch(1);
      // we didn't "consume" that data!
      this.lastReturnedIndex = 0;
    }
    return !this.data || this.data.length == 0;
  }

  isComplete(): boolean {
    return this.complete;
  }

  reset(): void {
    this.data = undefined;
    this.lastReturnedIndex = 0;
    this.pageToFetch = 0;
    this.complete = false;
    this.version++;
  }
}
