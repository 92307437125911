"use strict";

import "./loaderSearchPage.scss";

import { DOMHelper, View } from "~ui-lib";

const anim_start = require("~images/loader/OKOO_LOADER.gif");

export class LoaderSearchView extends View {
  private DOMAnim: HTMLImageElement;
  private intervalID: number;
  private animationDuration = 2000;
  private fadeDurationInterval = 100;
  private timerEndAnimID = -1;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "loader-overlaySearch"));

    this.intervalID = -1;

    const loader = DOMHelper.createDivWithParent(this.rootElement, "loader-container", null);
    // use image src in order to reload gif animation from start
    this.DOMAnim = DOMHelper.createImg(loader, "loader-anim");
    this.DOMAnim.style.backgroundColor = "transparent";
  }
  start(): void {
    this.DOMAnim.src = "";
    this.DOMAnim.src = anim_start;
  }
  end(onComplete?: () => void): void {
    // this.timerEndAnimID = window.setTimeout(() => {
    this.fadeOut(() => {
      onComplete?.();
    });
    // }, this.animationDuration);
  }
  private fadeOut(onSuccess?: () => void): void {
    this.intervalID = window.setInterval(() => {
      this.hide(onSuccess);
    }, this.fadeDurationInterval);
  }
  private hide(onSuccess?: () => void): void {
    clearInterval(this.intervalID);
    // clearTimeout(this.timerEndAnimID);
    onSuccess?.();
  }

  onRelease(): void {
    clearTimeout(this.timerEndAnimID);
    clearInterval(this.intervalID);
    this.DOMAnim.src = "";
    this.intervalID = -1;
  }
}
