import "./partnersList.scss";

import {
  createListComponent,
  DOMHelper,
  IListComponent,
  StaticModelSource,
  View,
} from "../../../../bud-lite-tv/src/lib";
import { Position } from "../../../tools/cmp/didomi";
import { VendorId } from "../../../tools/cmp/didomiStub";
import { CheckboxPartners } from "./checkBoxPartners";

export type Partners =
  | {
      id: VendorId;
      state: Position;
      title: string;
    }
  | {
      id: "All";
      state: Position;
      title: string;
    };

export class PartnersList extends View {
  private _list: IListComponent<Partners>;
  constructor(partnersList: Partners[]) {
    super(DOMHelper.createDivWithParent(null, "PartnersList"));
    const checkBox = DOMHelper.createDivWithParent(this.rootElement, "ContainerCheckBoxPartnersList", null, "");

    const modelSourceData = this._createModelSource(partnersList);

    this._list = this.delegate = createListComponent({
      rootElement: checkBox,
      modelSource: new StaticModelSource<Partners>(modelSourceData),
      viewFactory: model => {
        return new CheckboxPartners(model);
      },
      onSelect: (model, index) => {
        const view = this._list.viewFromIndex(index);

        if (view instanceof CheckboxPartners) {
          const checked = view.toggle();

          if (model.id === "All") {
            for (let i = 0; i < modelSourceData.length; i++) {
              if (i !== index) {
                const currentView = this._list.viewFromIndex(i);
                if (currentView instanceof CheckboxPartners) {
                  currentView.setState(checked);
                }
              }
            }
          } else {
            const allParameterView = this._list.viewFromId("All");
            if (allParameterView instanceof CheckboxPartners) {
              allParameterView.setState(
                modelSourceData.find(data => {
                  return data.id !== "All" && data.state === Position.denied;
                }) === undefined
              );
            }
          }
        }

        return false;
      },
      horizontal: false,
      pageSize: 5,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: true,
    });
  }

  private _createModelSource = (partnersList: Partners[]) => {
    const header = {
      id: "All" as const,
      state:
        partnersList.find(partner => partner.state === Position.denied) !== undefined
          ? Position.denied
          : Position.allow,
      title: "Tous les partenaires",
    };
    const modelSource: Partners[] = [];

    for (const partner of [header, ...partnersList]) {
      // Filter some partners

      modelSource.push(partner);
    }

    return modelSource;
  };
}
