import "./mainMenu.scss";

import { AgePage } from "~pages/gestionAge/agePage";
import { HomeTab } from "~pages/home/homeTab";
import { ProfilTab } from "~pages/profil/profilTab";
// import { CategoriesTab } from "~pages/category/categoriesTab";
import { pushTabWithMenu } from "~pages/rootPage";
import { createListComponent, DOMHelper, IListComponent, IPage, StaticModelSource, View } from "~ui-lib";

import { Plugin } from "../../datas/plugin";
import { ItemAge } from "../../pages/gestionAge/itemAge";
import { SearchTab } from "../../pages/search/searchTab";
import { ItemMenu, ItemMenuComponent, MainMenuItemSlug } from "./itemMenu";

export class WipTab extends View {
  constructor(htmlEltId: string, message: string) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, "wip", message));
  }
}

class WipPage extends View implements IPage {
  constructor(htmlEltId: string, message: string) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, "wip focused", message));
  }
}

export class MainMenuComponent extends View {
  private static _instance: MainMenuComponent;

  private _list?: IListComponent<ItemMenu>;
  private _itemSelected?: ItemMenuComponent = undefined;
  private _slugsIds: { slug: string; modelSourceId: string }[] = [];
  private _modelSource?: StaticModelSource<ItemMenu>;
  private _root: HTMLElement;
  private _clockInterval: number;
  public static getInstance(): MainMenuComponent {
    if (!MainMenuComponent._instance) {
      MainMenuComponent._instance = new MainMenuComponent();
      MainMenuComponent._instance.cacheable = true;
    }

    return MainMenuComponent._instance;
  }

  private constructor() {
    super(DOMHelper.createDivWithParent(null, "MainMenu", "mainMenu"));
    this._root = this.rootElement;
    this._fetchSource();

    // creating clock
    const clockElement = DOMHelper.createDivWithParent(this.rootElement, "MainMenuClock");
    function checkTime(i: number): string {
      return i < 10 ? "0" + i : i + "";
    }
    function refreshClock() {
      const now = new Date();
      const h = checkTime(now.getHours());
      const m = checkTime(now.getMinutes());
      clockElement.innerText = h + ":" + m;
    }
    this._clockInterval = window.setInterval(refreshClock, 1000);
    refreshClock();
  }

  private _fetchSource = () => {
    Plugin.getInstance()
      .fetchMenu()
      .subscribe(
        (menuItems: ItemMenu[]) => {
          // Here use it to create the UI
          this._createList(menuItems.filter(item => item.visible));
        },
        (error: any) => {
          // Here use it to trigger and display an error
          console.log("[MENU] Error !", error);
        },
        () => {
          console.log("[MENU] Complete !");
        }
      );
  };

  private _createList(source: ItemMenu[]) {
    //this.rootElement.style.paddingTop = (1080 / 2 - ((58 + 26) / 2) * source.length).toString() + "px"; // padding-top = (heightMenu / 2) - ((heightItem + margin) / 2 * xItems)
    this._modelSource = new StaticModelSource(source);
    this.delegate = this._list = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: this._modelSource,
        viewFactory: model => {
          this._slugsIds.push({ slug: model.slug, modelSourceId: model.id + "" });
          if (model.order == 0) {
            const itemMenuComponent = new ItemMenuComponent(model, this._root);
            itemMenuComponent.rootElement.style.marginTop = "44px";
            itemMenuComponent.rootElement.style.marginBottom =
              1080 / 2 - ((58 + 26) / 2) * (source.length - 1) - 102 + "px";
            return itemMenuComponent;
          }
          return new ItemMenuComponent(model, this._root);
        },
        horizontal: false,
        pageSize: 7,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
        onSelect: this._onItemSelect,
      },
      list => {
        this._list = list;
        this.selectMenuItemSlug(MainMenuItemSlug.home);
      }
    );
  }

  private _onItemSelect = (item: ItemMenu) => {
    const id = item.id;
    const slug: MainMenuItemSlug = item.slug as MainMenuItemSlug;
    this._selectMenuItem(id);
    switch (slug) {
      case MainMenuItemSlug.age:
        pushTabWithMenu(
          new AgePage(false, itemAge => {
            pushTabWithMenu(new HomeTab(itemAge), "homePage", MainMenuItemSlug.home);
          }),
          "agePage",
          slug
        );
        //pushTabWithMenu(new WipTab("ageTab", "Age page : work in progress"), "agePage", slug);
        return true;
      case MainMenuItemSlug.search:
        pushTabWithMenu(new SearchTab(), "searchPage", slug);
        //navigationStack.pushPage(new WipPage("searchTab", "Search page : work in progress"));
        return true;
      case MainMenuItemSlug.home:
        // pushTabWithMenu(new WipTab("homePage", "Home page : work in progress"), "homePage", slug);
        pushTabWithMenu(new HomeTab(), "homePage", slug);
        return true;
      case MainMenuItemSlug.profil:
        pushTabWithMenu(new ProfilTab(), "settingsPage", slug);
        // pushTabWithMenu(new WipTab("favoritesTab", "Favorites page : work in progress"), "profilPage", slug);
        return true;
      default:
        return false;
    }
  };

  private _selectMenuItem = (id: string) => {
    const itemSelected = this._list?.viewFromId(id) as ItemMenuComponent | undefined;
    this._itemSelected?.unSelect();
    this._itemSelected = itemSelected;
    itemSelected?.select();
  };

  private _idFromSlug = (slug: MainMenuItemSlug) => {
    return this._slugsIds.find(item => item.slug === slug)?.modelSourceId;
  };

  selectMenuItemSlug = (slug: MainMenuItemSlug) => {
    const id = this._idFromSlug(slug);
    id && this._selectMenuItem(id);
  };

  public static setMenuAge = (selectAge: ItemAge) => {
    const itemAge = MainMenuComponent._instance._list?.viewFromId(
      MainMenuComponent._instance._idFromSlug(MainMenuItemSlug.age)
    ) as ItemMenuComponent | undefined;
    itemAge?.setAge(selectAge);
  };
  onUnfocused(): boolean {
    this._itemSelected?.onFocused(true);
    return true;
  }
  onFocused(): boolean {
    this._itemSelected?.onfocusMenu();
    return true;
  }
  onRelease() {
    window.clearInterval(this._clockInterval);
  }
}
export { MainMenuItemSlug };
