import { platform, PlatformType } from "../../../bud-lite-tv/src/lib";
import { parseMarkerPiano } from "../../datas/parser";
import { Storage } from "../../libs/storage";
import { Tile } from "../../pages/rootPage";
import { Didomi } from "../cmp/didomi";
import { getVisitorMode } from "../cmp/visitorMode";
import { formatParam } from "./stringFormatter";

type PianoEventName = "page.display" | "publisher.impression" | "click.action";

export enum ParamClickZone {
  mea = "mea",
  hero = "programme",
  playlist = "playlist",
}

const PIANO_PAGE_TYPE_NAMES = ["accueil", "compte", "autre", "programme", "collection", "video", "recherche"] as const;
export type PianoPageTypeName = typeof PIANO_PAGE_TYPE_NAMES[number];

export const parsePianoPageTypeEnum = (value: unknown) => {
  return PIANO_PAGE_TYPE_NAMES.find(element => element === value);
};

type PianoParams = {
  // Context: all pages & all events
  site: string;
  origin_page: string | undefined;
  origin_page_type: PianoPageTypeName | undefined;
  visitor_age: string | undefined;
  visitor_category: string | undefined;
  app_version: string;
  // Specific params
};

type PianoParamsWithPage = PianoParams & {
  page: string;
  page_type: PianoPageTypeName;
};

type PianoGDPR = "essential" | "optional" | "mandatory";
type PianoGDPRMode = "opt-in" | "essential" | "opt-out";

type PianoAnalytics = {
  setConfigurations: (configuration: { site: number; collectDomain: string }) => void;
  sendEvent: (eventName: PianoEventName, params: PianoParams) => void;
  consent: {
    setMode: (mode: PianoGDPRMode) => void;
    getMode: () => PianoGDPRMode;
  };
};

/**
 * Age parameters
 */

interface AgeCustomParams {
  visitor_age: CustomParamAge | undefined;
  visitor_category: CustomParamAgeCategories;
}

enum CustomParamAgeCategories {
  preSchool = "pre-school",
  kids = "kids",
  tweens = "tweens",
  transverse = "transverse",
}

let ageParams: AgeCustomParams = {
  visitor_age: undefined,
  visitor_category: CustomParamAgeCategories.transverse,
};

enum CustomParamAge {
  Age3To4years = "3_a_4_ans",
  Age5To6Years = "5_a_6_ans",
  Age7To8Years = "7_a_8_ans",
  Age9To10Years = "9_a_10_ans",
  Age11AndMore = "11_ans_et_plus",
}

export const setPianoAge = (age: number) => {
  ageParams = getAgeCustomParams(age);
};

export const getAgeCustomParams = (age: number): AgeCustomParams => {
  switch (age) {
    case 3:
    case 4:
      return {
        visitor_age: CustomParamAge.Age3To4years,
        visitor_category: CustomParamAgeCategories.preSchool,
      };
    case 5:
    case 6:
      return {
        visitor_age: CustomParamAge.Age5To6Years,
        visitor_category: CustomParamAgeCategories.preSchool,
      };
    case 7:
    case 8:
      return {
        visitor_age: CustomParamAge.Age7To8Years,
        visitor_category: CustomParamAgeCategories.kids,
      };
    case 9:
      return {
        visitor_age: CustomParamAge.Age9To10Years,
        visitor_category: CustomParamAgeCategories.kids,
      };
    case 10:
      return {
        visitor_age: CustomParamAge.Age9To10Years,
        visitor_category: CustomParamAgeCategories.tweens,
      };
    case 11:
    case 12:
      return {
        visitor_age: CustomParamAge.Age11AndMore,
        visitor_category: CustomParamAgeCategories.tweens,
      };
  }

  // default
  return {
    visitor_age: undefined,
    visitor_category: CustomParamAgeCategories.transverse,
  };
};

/**
 * Piano Analytics instance
 *
 * This variable can be undefined when PA is not yet initialized
 */
let pianoAnalytics: PianoAnalytics | undefined = undefined;
declare global {
  interface Window {
    pdl:
      | Partial<{
          /**
           * Activating the Piano Analaytics Consents feature
           * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#activating-the-consents-feature
           */
          requireConsent: boolean;
          consent_items: Partial<{
            PA: Partial<{
              /**
               * Allow customization of properties sent by Piano Analytics
               * according to user consent mode
               *
               * optional: opt-in
               * essential: exempt, opt-out
               * mandatory: exempt, opt-out, opt-in
               * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#manage-default-consent-modes
               */
              properties: Partial<Record<keyof PianoParams, PianoGDPR>>;
              /**
               * Allow customization of events sent by Piano Analytics
               *
               * Any custom events need to be added to this array to be able to be
               * sent by PA SDK.
               */
              events: Record<PianoEventName, PianoGDPR>;
            }>;
          }>;
        }>
      | undefined;
  }
}

const getPianoConfiguration = () => {
  const PIANO_IDS = {
    prod: {
      lg: {
        site: 633153,
        server: "logs1238",
      },
      samsung: {
        site: 633155,
        server: "logs1238",
      },
      hisense: {
        site: 640338,
        server: "logs1238",
      },
      philips: {
        site: 640340,
        server: "logs1238",
      },
    },
    preprod: {
      lg: {
        site: 633154,
        server: "logs1238",
      },
      samsung: {
        site: 633156,
        server: "logs1238",
      },
      hisense: {
        site: 640341,
        server: "logs1238",
      },
      philips: {
        site: 640342,
        server: "logs1238",
      },
    },
  } as const;
  return PIANO_IDS[__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy" ? "prod" : "preprod"][
    platform.type === PlatformType.tizen
      ? "samsung"
      : platform.type === PlatformType.hisense
      ? "hisense"
      : platform.type === PlatformType.philips
      ? "philips"
      : "lg"
  ];
};

const GDPRPianoProperty: Record<keyof PianoParamsWithPage | "*" | "click", PianoGDPR> = {
  site: "mandatory",
  page: "mandatory",
  page_type: "mandatory",
  origin_page: "mandatory",
  origin_page_type: "mandatory",
  visitor_age: "mandatory",
  visitor_category: "mandatory",
  app_version: "mandatory",
  click: "mandatory",
  /**
   * `*` is set to mandatory to mark any custom field as mandatory.
   * By default, any field that has not been marked with a valid PianoGDPR
   * mode are not received on PA dashboard if the user did not accept
   * tracking on the Consent Management Page.
   */
  "*": "mandatory",
};

export const initializePianoAnalytics = () => {
  if (pianoAnalytics === undefined) {
    /**
     * window.pdl NEEDS to be imported BEFORE importing Piano Analytics.
     * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#pre-requisite
     */
    window.pdl = {
      ...window.pdl,
      /**
       * Enable controlling consent mode with .setMode method
       * Rerence: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#pre-requisite
       */
      requireConsent: true,
      /**
       * Defining GDPR rules for Piano Analytics properties
       */
      consent_items: {
        PA: {
          properties: GDPRPianoProperty,
          events: {
            "page.display": "mandatory",
            "publisher.impression": "mandatory",
            "click.action": "mandatory",
          },
        },
      },
    };

    /**
     * Importing Piano Analytics module.
     *
     * PA is imported using `require` keyword to avoid being imported
     * when this script is imported in other files, insuring that
     * window.pdl is defined BEFORE imported  PA.
     */
    const pianoAnalyticsModule = require("piano-analytics-js");
    pianoAnalytics = pianoAnalyticsModule.pianoAnalytics as PianoAnalytics;

    const pianoConfiguration = getPianoConfiguration();

    pianoAnalytics.setConfigurations({
      site: pianoConfiguration.site,
      collectDomain: `https://${pianoConfiguration.server}.xiti.com`,
    });
  }
};

// Reload Privacy Mode
/**
 * PA is the only vendor that is using Visitor Mode (CMP => More Info => Checkbox (opposition au dépot du traceur))
 * - VisitorMode = "disabled" => opt-out
 * - VisitorMode = "enabled" & vendor enabled => opt-in
 * - VisitorMode = "enabled" & vendor disabled => essential
 */
const setPianoPrivacyMode = () => {
  if (pianoAnalytics !== undefined) {
    const visitorMode = getVisitorMode().value;
    switch (visitorMode) {
      case "disabled": {
        pianoAnalytics.consent.setMode("opt-out");
        break;
      }
      case "enabled": {
        if (Didomi.isVendorAllowedToTrack("atinterne-D22mcTNf")) {
          pianoAnalytics.consent.setMode("opt-in");
        } else {
          pianoAnalytics.consent.setMode("essential");
        }
        break;
      }
    }
  } else {
    Log.app.error("Failed to set Piano Analytics consent mode since it is not yet initialized.");
  }
};

/**
 * Keep track of the current page and the previous "origin" page
 *
 * These params are updated on every "page.display" event
 * Previous `page` & `pageType` will be added as `origin_page` & `origin_page_type` dynamically
 */
const _currentPageParams: {
  page: string | undefined;
  pageType: PianoPageTypeName | undefined;
  originPage: string | undefined;
  originPageType: PianoPageTypeName | undefined;
} = {
  page: undefined,
  pageType: undefined,
  originPage: undefined,
  originPageType: undefined,
};

export const getOriginPageParams = () => {
  return _currentPageParams;
};

type IPianoMap = {
  /**
   * page.display is the only event that will update
   */
  "page.display": PianoParamsWithPage;
  "click.action": PianoParams;
  "publisher.impression": PianoParams;
};

export const sendPianoAnalytic = <T extends PianoEventName>(
  /**
   * Piano Event
   */
  eventName: T,
  /**
   * Regular Piano Parameters
   *
   * Omitted values are added dynamically on every event
   */
  params: Omit<
    IPianoMap[T],
    "visitor_age" | "visitor_category" | "origin_page" | "origin_page_type" | "site" | "app_version"
  >,
  /**
   * Any additionnal params to pass with the event
   */
  additionnalParams: Record<string, string | boolean | number>
) => {
  if (pianoAnalytics !== undefined) {
    //get chosen age
    if (Storage.getItem("age") && ageParams.visitor_age === undefined) {
      setPianoAge(Number(Storage.getItem("age")));
    }
    // Omitted Piano Params
    const dynamicsParams = {
      // Site
      site: `${getPianoConfiguration().site}`,
      visitor_age: ageParams.visitor_age,
      visitor_category: ageParams.visitor_category,
      app_version: __APP_VERSION__,
    };

    let pianoAnalyticsParams: PianoParamsWithPage | null = null;
    if (eventName === "page.display") {
      /**
       * page.display is the only event that can update page & page_type params.
       *
       * IMPORTANT:
       * We need to mark params as "IPianoMap["page.display"]" to have access to .page & .pageType params
       * This is a limitation from current version of Typescript (4.2.3)
       * `eventName` is not being infered as litteral constant after if statement.
       */
      const paramsPageDisplay = params as IPianoMap["page.display"];

      // Updating _currentPageParams
      _currentPageParams.originPage = _currentPageParams.page;
      _currentPageParams.originPageType = _currentPageParams.pageType;
      _currentPageParams.page = paramsPageDisplay.page;
      _currentPageParams.pageType = paramsPageDisplay.page_type;

      // Adding ommitted params
      pianoAnalyticsParams = {
        ...paramsPageDisplay,
        ...dynamicsParams,
        page: _currentPageParams.page,
        page_type: _currentPageParams.pageType,
        origin_page: _currentPageParams.originPage,
        origin_page_type: _currentPageParams.originPageType,
      };
    } else {
      /**
       * We are not in `page.dislay` event, we did not pass `page` / `pageType` params
       */

      if (_currentPageParams.page !== undefined && _currentPageParams.pageType) {
        // Adding ommitted params + current page + origin page
        pianoAnalyticsParams = {
          ...params,
          page: _currentPageParams.page,
          page_type: _currentPageParams.pageType,
          origin_page: _currentPageParams.originPage,
          origin_page_type: _currentPageParams.originPageType,
          ...dynamicsParams,
        };
      } else {
        // this happens when the first page displayed by the app is CMP pages, because cmp pages don't send "page.display"
        pianoAnalyticsParams = {
          ...params,
          page: _currentPageParams.page as any,
          page_type: _currentPageParams.pageType as any,
          origin_page: _currentPageParams.originPage,
          origin_page_type: _currentPageParams.originPageType,
          ...dynamicsParams,
        };
      }
    }

    if (pianoAnalyticsParams !== null) {
      /**
       * Send event with piano analytics basic event +
       * any additionnal params
       */
      setPianoPrivacyMode();
      pianoAnalytics.sendEvent(eventName, {
        // Piano Analytics Params + Dynamic Params + Current Page
        ...pianoAnalyticsParams,
        // Additionnal Params added to the request
        ...additionnalParams,
      });
    } else {
      Log.app.error("Failed to send Piano Analytics. Is any event sent before any `page.display` event ?");
    }
  } else {
    Log.app.error("Failed to send Piano Analytics since it is not yet initialized.");
  }
};

export const getAdditionalProperties = (receivedProperties: unknown): Record<string, string> => {
  const properties: Record<string, string> = {};

  if (typeof receivedProperties === "object" && receivedProperties !== null) {
    for (const [key, value] of Object.entries(receivedProperties)) {
      if (typeof value === "string" || typeof value === "boolean" || typeof value === "number") {
        properties[key] = `${value}`;
      }
    }
  }

  return properties;
};

export const sendClickPianoEvent = (
  item: Tile,
  zone: ParamClickZone | string,
  isPopup: boolean,
  position?: number,
  searchString?: string
) => {
  const currentPage = _currentPageParams;

  const markerPiano = parseMarkerPiano(item.extras);
  if (markerPiano === undefined) {
    Log.analytics.error("Failed to parse Marker Piano");
    return;
  }

  const additionalProperties = markerPiano.additional_properties;
  const contextualProperties = markerPiano.contextual_properties;

  const properties: Record<string, string | number | boolean> = {
    ...additionalProperties,
  };

  if (!("content_type" in properties) && contextualProperties && typeof contextualProperties.page_type === "string") {
    properties["content_type"] = contextualProperties.page_type;
  }

  if (!("content_title" in properties) && contextualProperties && typeof contextualProperties.page === "string") {
    properties["content_title"] = contextualProperties.page;
  }

  const currentPosition = position != undefined ? position + 1 : position;

  switch (currentPage.pageType) {
    case "accueil":
      properties["zone"] =
        zone === ParamClickZone.hero || zone === ParamClickZone.mea
          ? zone
          : zone + "_" + formatParam(item.itemCollection?.title);
      properties["click"] = "vignette";
      if (currentPosition) properties["position"] = currentPosition;
      break;
    case "recherche":
      if (currentPosition) properties["position"] = currentPosition;
      properties["click"] = isPopup ? "vignette_non_adaptee" : "vignette";

      if (searchString) {
        properties["ise_keyword"] = searchString;
      } else {
        properties["zone"] = zone.replace(/-/g, "_");
      }

      break;
    case "compte":
      console.log("We are in compte page");
      break;
    case "autre":
      console.log("We are in autre page");
      break;
    default:
      Log.analytics.error(`No page of "${currentPage.pageType}" type found`);
  }

  if (Object.keys(properties).length !== 0) {
    sendPianoAnalytic("click.action", {}, properties);
  }
};
