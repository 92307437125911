export const Config = {
  delaySplashScreen: 2000,
  server: {
    prod: "https://api-mobile.yatta.francetv.fr",
    preprod: "https://api-mobile.yatta.ftv-preprod.fr",
  },
  player: {
    prod: "https://k7.ftven.fr",
    preprod: "https://k7.ftven-preprod.fr",
  },
  ads: {
    prod: "https://metaproxy.francetvpub.fr",
    preprod: "https://metaproxy.francetvpub.fr",
  },
  didomi: {
    prod: {
      organizationId: "france-tv",
      apiUrl: "https://consent.france.tv",
      privateAPIKey: "3ce0ddb3-c3cc-4378-ba9e-e861e3b073dd",
      secretAPIKey: "FZxUbdgjMxWgDKeYeWrTJ7fgAW8b8Uyypyx42cyGzf22pWtNaULgWZeEntFp7xWd",
    },
    preprod: {
      organizationId: "france-tv-preprod",
      apiUrl: "https://consent.francetv-preprod.fr",
      privateAPIKey: "aaa66b23-55ab-453a-98f7-ed62746aff4c",
      secretAPIKey: "GHjLWfih96aBeinTMrRPtpHdiBwGBEmFkAy9rnzyDdM9WzjpxJ9in9PrDdPJZ9j8",
    },
  } as const,
};
