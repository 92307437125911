import "./optionsProfilSwimlane.scss";

import { Storage } from "~libs/storage";
import { AgePage } from "~pages/gestionAge/agePage";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { OptionsProfilView } from "~views/settings/optionsProfilView";

import { HomeTab } from "../pages/home/homeTab";
import { pushTabWithMenu } from "../pages/rootPage";
import { sendPianoAnalytic } from "../tools/analytics/piano";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";

export enum optionsType {
  age = "age",
  sound = "sound",
}
export class OptionsProfilSwimlane extends View {
  listComponent?: IListComponent<optionsType>;
  constructor() {
    super(DOMHelper.createDivWithParent(null, "OptionsProfilSwimlane", "optionsProfilSwimlane"));
    this.listComponent = this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource([optionsType.age, optionsType.sound]),
      viewFactory: model => new OptionsProfilView(model),
      horizontal: true,
      pageSize: 2,
      spatialFocus: true,
      onSelect: this.onSelect,
    });
  }
  onSelect = (model: optionsType): boolean => {
    switch (model) {
      case optionsType.age:
        pushTabWithMenu(
          new AgePage(false, itemAge => {
            pushTabWithMenu(new HomeTab(itemAge), "homePage", MainMenuItemSlug.home);
          }),
          "AgePage",
          MainMenuItemSlug.age
        );
        return true;

      case optionsType.sound: {
        const toggleStatus = ((): "on" | "off" => {
          const storageMuted = Storage.getItem("muted");
          if (storageMuted === null || storageMuted === "false") {
            Storage.setItem("muted", "true");
            return "off";
          } else {
            Storage.setItem("muted", "false");
            return "on";
          }
        })();
        this.listComponent?.resetContent();
        sendPianoAnalytic(
          "click.action",
          {},
          { click: "toggle", toggle_name: "effets_sonores", toggle_status: toggleStatus }
        );
        return true;
      }
    }
    return false;
  };
}
