export function convertDuration(seconds: number, format?: string): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  if (format == "HHMMSS") {
    let hoursString = "";
    let minutesString = "";
    let secondsString = "";
    if (hours < 10) {
      hoursString = "0" + hours;
    }
    if (minutes < 10) {
      minutesString = "0" + minutes;
    }
    if (seconds < 10) {
      secondsString = "0" + seconds;
    }

    return hoursString + ":" + minutesString + ":" + secondsString;
  } else {
    let str = "";
    if (hours > 0) {
      str = hours + "h";
    }
    if (minutes > 0) {
      str += minutes + "min";
    }
    return str !== "" ? str : "1min";
  }
}
