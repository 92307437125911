import "./cardSettingsView.scss";

import { DOMHelper, View } from "~ui-lib";

export class CardSettingsView extends View {
  constructor(item: string) {
    super(DOMHelper.createDivWithParent(null, null, "Cardview"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "cguBox");
    DOMHelper.createSpanWithParent(box, null, "cguText", item.toString());
  }
}
