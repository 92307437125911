import { Log } from "../log";
import { Counter } from "./counter";
import { proxyMappings } from "./proxyMappings";

const requestStats = new Counter("Request");

export interface RequestOptions {
  method?: "get" | "post" | "put" | "delete";
  queryParams?: any;
  body?: any;
  ignoreCache?: boolean;
  headers?: { [key: string]: string };
  // 0 (or negative) to wait forever
  timeout?: number;
}

export const DEFAULT_REQUEST_OPTIONS = {
  method: "get",
  queryParams: {},
  body: null,
  ignoreCache: false,
  headers: {
    Accept: "application/json, text/javascript, text/plain",
  },
  // default max duration for a request
  timeout: 5000,
};

export interface RequestResult {
  ok: boolean;
  timedOut: boolean;
  url: string;
  requestOptions?: RequestOptions;
  status: number;
  statusText: string;
  data: string;
  json: <T>() => T;
  responseHeader: string;
}

function queryParams(params: any = {}) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
}

function withQuery(url: string, params: any = {}) {
  const queryString = queryParams(params);
  return queryString ? url + (url.indexOf("?") === -1 ? "?" : "&") + queryString : url;
}

function parseXHRResult(url: string, requestOptions: RequestOptions | undefined, xhr: XMLHttpRequest): RequestResult {
  return {
    ok: xhr.status >= 200 && xhr.status < 300,
    url: url,
    timedOut: false,
    status: xhr.status,
    statusText: xhr.statusText,
    requestOptions: requestOptions,
    responseHeader: xhr.getAllResponseHeaders(),
    data: xhr.responseText,
    json: <T>() => {
      try {
        return JSON.parse(xhr.responseText) as T;
      } catch (error) {
        Log.net.error("JSON: parsing failed, URL:", url);
        Log.net.error("Request options", JSON.stringify(requestOptions));
        Log.net.error(
          "XHR",
          JSON.stringify({
            status: xhr.status,
            statusText: xhr.statusText,
            response: xhr.response,
            timeout: xhr.timeout,
            responseHeaders: xhr.getAllResponseHeaders(),
            withCredentials: xhr.withCredentials,
          })
        );
        throw error;
      }
    },
  };
}

function errorResponse(
  url: string,
  requestOptions: RequestOptions | undefined,
  xhr: XMLHttpRequest,
  timedOut: boolean,
  message: string | null = null
): RequestResult {
  Log.net.error("XHR error", url, xhr.status, xhr.statusText);
  return {
    ok: false,
    timedOut: timedOut,
    url: url,
    requestOptions: requestOptions,
    status: xhr.status,
    statusText: xhr.statusText,
    responseHeader: xhr.getAllResponseHeaders(),
    data: message || xhr.statusText,
    json: <T>() => ({} as T),
  };
}

export function request(url: string, options?: RequestOptions) {
  const method = options?.method ?? DEFAULT_REQUEST_OPTIONS.method;
  const body = options?.body ?? DEFAULT_REQUEST_OPTIONS.body;
  const queryParams = options?.queryParams ?? DEFAULT_REQUEST_OPTIONS.queryParams;
  const ignoreCache = options?.ignoreCache ?? DEFAULT_REQUEST_OPTIONS.ignoreCache;
  const headers = options?.headers ?? DEFAULT_REQUEST_OPTIONS.headers;
  const timeout = options?.timeout ?? DEFAULT_REQUEST_OPTIONS.timeout;

  // if check if proxied
  Object.keys(proxyMappings).forEach(proxiedUrl => {
    url = url.replace(proxiedUrl, proxyMappings[proxiedUrl]);
  });

  requestStats.increase();

  return new Promise<RequestResult>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    try {
      xhr.open(method, withQuery(url, queryParams));
    } catch (error) {
      reject(errorResponse(url, options, xhr, false, error));
    }

    if (headers) {
      Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));
    }

    if (ignoreCache) {
      xhr.setRequestHeader("Cache-Control", "no-cache");
    }

    xhr.timeout = timeout;

    xhr.onload = evt => {
      requestStats.decrease();
      resolve(parseXHRResult(url, options, xhr));
    };

    xhr.onerror = evt => {
      requestStats.decrease();
      reject(errorResponse(url, options, xhr, false));
    };

    xhr.ontimeout = evt => {
      requestStats.decrease();
      reject(errorResponse(url, options, xhr, true, "Timed out"));
    };

    if ((method === "post" || method === "put") && body) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
}
