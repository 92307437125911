import { Listenable } from "./helpers/Listenable";

export { createNavigationStack, NavigationStackParams, INavigationStack, InputMode } from "./components/navigation";
export {
  createListComponent,
  ListComponentParams,
  ScrollingMode,
  IListComponent,
  isListComponent,
} from "./components/listComponent";
export * from "./components/switchComponent";
export * from "./components/modelSource";

export * from "./helpers";
export * from "./types";
export * from "./platform";

export enum DIR {
  ltr = "ltr",
  rtl = "rtl",
}

export const DIR$ = new Listenable<DIR>(DIR.ltr);
// listen to RTL updates
DIR$.didChange((dir: DIR) => {
  document.dir = dir;
});
