import "./programSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { ProgramView } from "~views/programView";

export class ProgramSwimlane extends View {
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "", "programSwimlane"));
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as Program[]),
      viewFactory: (model: any) => {
        return new ProgramView(model);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 5,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: onSelectTile,
    });
  }
}
