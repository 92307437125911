import "./programView.scss";

import { checkImage } from "~tools/tool";
import { DOMHelper, View } from "~ui-lib";

import { Program } from "../models/program";
import { AgePage } from "../pages/gestionAge/agePage";

export class ProgramView extends View {
  constructor(item: Program) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemProgramVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemProgramViewBoxZoom");
    const background = DOMHelper.createDivWithParent(box, null, "backgroundProgram");
    let elementImgSrc = "";
    const fallbackTitle = DOMHelper.createDivWithParent(background, null, "titleProgramFallback", item.title);
    if (item.artworks) {
      const srcFromArtworks = item.getSquareImgUrl();
      if (srcFromArtworks !== "") {
        elementImgSrc = srcFromArtworks;
      }
    }
    checkImage(
      elementImgSrc,
      () => {
        DOMHelper.createDivImg(background, null, "elementImg", elementImgSrc);
        fallbackTitle.classList.add("hide");
      },
      () => {
        const backgroundFallback = DOMHelper.createDivWithParent(background, null, "backgroundProgramFallback");
        DOMHelper.createDivImg(
          backgroundFallback,
          null,
          "elementImg",
          require("~images/fallback/default_okoo_square.png")
        );
        DOMHelper.addClass(box, "isFallback");
      }
    );
    if (item.extras && item.extras.age_min && AgePage.selectAge && AgePage.selectAge.age < item.extras.age_min) {
      const limitAgeBox = DOMHelper.createDivWithParent(box, null, "limitAgeBox");
      DOMHelper.createDivWithParent(limitAgeBox, null, "limitAge", "dès " + item.extras.age_min + " ans");
    }
  }

  onFocused = () => {
    // ItemInfoControllerSingleton.focusVideo(this.item);
  };
}
