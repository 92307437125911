import "./menuHorizontal.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { ButtonCmp, ButtonConsentManagement } from "../../../views/cmp/cmpButton/buttonCmp";
import { Arrow } from "./arrowConsentManagement";

export enum TextHorizontalMenu {
  refuseAll = "Tout refuser",
  arrow = "Flèche",
  acceptAll = "Tout accepter",
}

export class MenuHorizontal extends View {
  private isScroll = false;

  constructor(items: ButtonCmp[]) {
    super(DOMHelper.createDivWithParent(null, "MenuHorizontal", null, null));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(items),
      viewFactory: model => {
        switch (model.text) {
          case TextHorizontalMenu.arrow:
            return new Arrow(false);
          default:
            return new ButtonConsentManagement(model);
        }
      },
      horizontal: true,
      pageSize: 3,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: false,
      onSelect: this._onItemSelect,
    });
  }

  _onItemSelect(item: ButtonCmp) {
    switch (item.text) {
      case TextHorizontalMenu.acceptAll:
        item.onPress();
        return true;
      case TextHorizontalMenu.refuseAll:
        item.onPress();
        return true;
      case TextHorizontalMenu.arrow:
        (() => {
          const text = document.getElementById("TextCMP");
          const arrow = document.getElementById("ArrowImage");
          if (text && arrow) {
            if (this.isScroll) {
              text.style.top = "256px";
              arrow.style.transform = "rotate(0deg)";
              this.isScroll = false;
            } else {
              text.style.top = "-360px";
              arrow.style.transform = "rotate(180deg)";
              this.isScroll = true;
            }
          }
        })();
        return true;
      default:
        return false;
    }
  }
}
