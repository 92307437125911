import "./searchHeroSwimlaneList.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { SearchHeroSwimlane } from "./searchHeroSwimlane";

export class SearchHeroSwimlaneList extends View {
  private _list: IListComponent;
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "SearchHeroSwimlaneList", "searchHeroSwimlaneList"));
    const sortedItemsArray = this.sortItemsToSwimlane(source.items);
    this.delegate = this._list = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(sortedItemsArray),
      viewFactory: model => {
        const view = new SearchHeroSwimlane(model);
        return view;
      },
      horizontal: false,
      pageSize: 1,
      visibleBefore: 0,
      visibleAfter: 1,
    });
  }

  private sortItemsToSwimlane = (items: Program[]) => {
    const alphabet = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const tempArray: Program[] = [];
    const finalArray: { swimlaneItems: Program[]; title: string; id: number }[] = [];
    let title = "";
    let itemIndex = 9;
    const maxIndex = items.length - 1;

    //sort program array alphabetically by title
    //numbers and special characters are added before A
    items.sort(function (a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

    items.forEach((element, index) => {
      const firstLetter = element.title[0].toUpperCase();
      //store the first ten items,
      //it's important that at least one starts with a letter and not a special character
      //we don't separate letters and special characters
      if ((index <= itemIndex || title == "") && index != maxIndex) {
        tempArray.push(element);
        //create title depending on how many different letters the stored items start with, ignore special characters
        if (alphabet.includes(firstLetter) && !title.includes(firstLetter)) {
          title = title == "" ? firstLetter : title + "-" + firstLetter;
        }
      } else {
        //after ten items continue adding the items that start with the same letter as the last item added
        //or items that start with special character
        if ((!alphabet.includes(firstLetter) || title.includes(firstLetter)) && index != maxIndex) {
          tempArray.push(element);
        } else {
          if (index != maxIndex) {
            //make a copy of the array, push everything on to the final array and reset
            const shallowCopy = tempArray.slice(0);
            finalArray.push({ swimlaneItems: shallowCopy, title: title, id: index });
            tempArray.length = 0;
            title = "";

            //push the current element otherwise it will get lost
            tempArray.push(element);
            title = firstLetter;

            //update item index
            itemIndex = index + 9 <= maxIndex ? index + 9 : maxIndex;
          } else {
            tempArray.push(element);
            if (!title.includes(firstLetter)) {
              title += "-" + firstLetter;
            }
            finalArray.push({ swimlaneItems: tempArray, title: title, id: index });
          }
        }
      }
    });

    return finalArray;
  };
}
