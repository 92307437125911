import "./playerProgress.scss";

import { debounce, DOMHelper, Keys, Listenable, platform, PlatformType, Point, screenRectOf, View } from "~ui-lib";

import { Player, PlayerState } from "../../libs/player";

const pad = function (thing: number | string): string | number {
  return thing < 10 ? "0" + thing : thing;
};

const getFormatedTime = function (timeInSeconds: number): string {
  let timeT = "00:00:00";
  let ht = "";
  const totalTimeHour = Math.floor(timeInSeconds / 3600),
    totalTimeMinute = Math.floor((timeInSeconds % 3600) / 60),
    totalTimeSecond = Math.floor(timeInSeconds % 60);
  if (!isNaN(totalTimeHour) && !isNaN(totalTimeMinute) && !isNaN(totalTimeSecond)) {
    if (totalTimeHour > 0) {
      ht = pad(totalTimeHour) + ":";
    } else {
      ht = "00:";
    }
    timeT = ht + pad(totalTimeMinute) + ":" + pad(totalTimeSecond);
  }
  return timeT;
};

export class PlayerProgress extends View {
  progressDOM: HTMLElement;
  currentTimeDOM: HTMLElement;
  durationTimeDOM: HTMLElement;
  timelineDOM: HTMLElement;

  TIME_INCREMENT_FW = 30;
  TIME_INCREMENT_RW = 30;

  onCurrentTimeUnregister: () => void;
  onDurationUnregister: () => void;
  progressSeekTimeUnregister: () => void;

  player: Player;
  isLive$: Listenable<boolean>;

  constructor(isLive$: Listenable<boolean>, player: Player) {
    super(DOMHelper.createDivWithParent(null, null, "playerProgressContainer"));

    this.isLive$ = isLive$;
    this.player = player;

    this.currentTimeDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerCurrentTime", "");
    this.durationTimeDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerDuration", "");
    this.timelineDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerTimeline");
    this.progressDOM = DOMHelper.createDivWithParent(this.timelineDOM, null, "playerTimelinePercent");
    DOMHelper.createDivWithParent(this.progressDOM, null, "round");
    // initial setting
    this.updateProgressUI();
    this.onCurrentTimeUnregister = this.player.currentTime$.didChange(this.updateProgressUI);
    this.onDurationUnregister = this.player.duration$.didChange(this.updateProgressUI);
    this.progressSeekTimeUnregister = this.player.seekTime$.didChange(this.updateProgressUI);
  }

  private _getPlayerPosition = () => {
    return this.player.seekTime$.value === undefined ? this.player.position() : this.player.seekTime$.value;
  };

  private _seek = debounce((callback?: () => void) => {
    callback?.();
  }, 800);

  private _launchSeek = (key: Keys.left | Keys.right) => {
    if (
      [PlayerState.PLAYING, PlayerState.PAUSED, PlayerState.BUFFERING].indexOf(this.player.state$.value) !== -1 &&
      !this.isLive$.value
    ) {
      if (this.player.state$.value !== PlayerState.PAUSED) {
        this.player.sendEStat(PlayerState.PAUSED);
      }
      this.player.pause();
      let position =
        key === Keys.left
          ? Math.max(0, this._getPlayerPosition() - this.TIME_INCREMENT_RW)
          : Math.min(this._getPlayerPosition() + this.TIME_INCREMENT_FW, this.player.duration());
      // FIX from SAMSUNG : try not to jump to last possible second and limit it to jumping to video.duration-5
      if (platform.type === PlatformType.tizen && key === Keys.right) {
        if (this._getPlayerPosition() <= this.player.duration() - 5) {
          position = Math.min(position, this.player.duration() - 5);
        } else {
          // if the current postion is between duration-5 and duration, we don't jump because it will cause a rewind instead of forward
          position = this._getPlayerPosition();
        }
      }
      // END FIX
      this.player.setSeekTime(~~position);
      this._seek(() => {
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
      });
    }
  };

  // arrow function so that it's automatically bound to this
  updateProgressUI = () => {
    const time = this.player.seekTime$.value ?? this.player.currentTime$.value;
    this.currentTimeDOM.innerText = getFormatedTime(time);
    this.durationTimeDOM.innerText = getFormatedTime(this.player.duration$.value);
    this.progressDOM.style.width = `${(time / this.player.duration$.value) * 100}%`;
  };

  onRelease = () => {
    this.onCurrentTimeUnregister();
    this.onDurationUnregister();
    this.progressSeekTimeUnregister();
  };

  onNav = (key: Keys): boolean => {
    switch (key) {
      /*case Keys.select:
        this.player.seek();
        return true;*/

      /*case Keys.down:
        this.player.play();
        return false; // Must handle seek reset and remove focus from scrubber*/

      case Keys.left:
      case Keys.right:
        this._launchSeek(key);
        return true;

      case Keys.play:
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
        return true;

      case Keys.pause:
        this.player.sendEStat(PlayerState.PAUSED);
        this.player.pause();
        return true;

      case Keys.playPause:
        this.player.sendEStat(
          this.player.state$.value === PlayerState.PLAYING ? PlayerState.PAUSED : PlayerState.PLAYING
        );
        this.player.playPause();
        return true;

      /*case Keys.back:
        if (this.player.state$.value != PlayerState.SEEKING) return false;
        this.player.play();
        return true;*/

      default:
        return false;
    }
  };

  onMouseDown = (point: Point) => {
    const controlRect = screenRectOf(this.timelineDOM);
    if (controlRect) {
      const widhPercentage = (point.x - controlRect.origin.x) / controlRect.size.width;

      this._seek(() => {
        this.player.sendEStat(PlayerState.PAUSED);
        this.player.pause();
        let position = this.player.duration$.value * widhPercentage;
        // FIX from SAMSUNG : try not to jump to last possible second and limit it to jumping to video.duration-5
        if (platform.type === PlatformType.tizen && position > this._getPlayerPosition()) {
          if (this._getPlayerPosition() <= this.player.duration() - 5) {
            position = Math.min(position, this.player.duration() - 5);
          } else {
            // if the current postion is between duration-5 and duration, we don't jump because it will cause a rewind instead of forward
            position = this._getPlayerPosition();
          }
        }
        // END FIX
        this.player.setSeekTime(position);
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
      });
    }
    return true;
  };
}
