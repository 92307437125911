import { IListenable, Listenable } from "../../../bud-lite-tv/src/lib";

const VISITOR_MODE_KEY = "VisitorMode";

export type VisitorModeType = "enabled" | "disabled";
const defaultVisitorMode: VisitorModeType = "enabled";

function initializeVisitorMode() {
  const visitorModeListener$ = new Listenable<VisitorModeType>(defaultVisitorMode);

  visitorModeListener$.didChange(mode => {
    localStorage.setItem(VISITOR_MODE_KEY, mode);
  });

  const mode = localStorage.getItem(VISITOR_MODE_KEY);

  switch (mode) {
    case "enabled":
    case "disabled": {
      visitorModeListener$.value = mode;
      break;
    }
    default: {
      /**
       * Mode is:
       * - Not initialized (mode === null)
       * - or have the wrong value
       */

      // Returning default value;
      visitorModeListener$.value = defaultVisitorMode;
      break;
    }
  }

  return visitorModeListener$;
}
const visitorMode$ = initializeVisitorMode();

export const setVisitorMode = (mode: VisitorModeType) => {
  visitorMode$.value = mode;
};

export const getVisitorMode = (): IListenable<VisitorModeType> => {
  return visitorMode$;
};
