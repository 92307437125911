/**
 * All the types, interfaces & classes used throughout the library
 *
 * @module Types
 */

export * from "./view";

export interface Size {
  width: number;
  height: number;
}

/**
 * extension to the {@link Size} structure
 *
 * It is used when getting the size of a DOM element, to differentiate "intrinsic" size & "external" size (made by padding and margins and borders)
 * used only by the {@link sizeOf} helper function
 */
export interface ExtraSize extends Size {
  extraWidth: number;
  extraHeight: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface Rect {
  origin: Point;
  size: Size;
}

export function pointInRect(point: Point | undefined, rect: Rect | undefined): boolean {
  return (
    point != undefined &&
    rect != undefined &&
    point.x >= rect.origin.x &&
    point.x < rect.origin.x + rect.size.width &&
    point.y >= rect.origin.y &&
    point.y < rect.origin.y + rect.size.height
  );
}

export function centerOf(rect?: Rect): Point | undefined {
  if (!rect) return undefined;
  return { x: rect.origin.x + rect.size.width / 2, y: rect.origin.y + rect.size.height / 2 };
}

function SQR(x: number, y: number) {
  return x * x + y * y;
}

/** return square distance between points
 * to compare distances between UI elements it's much faster to use this than the actual distance
 * as there's no need to do a Math.sqrt
 */
export function distanceBetweenPointsSQR(point1?: Point, point2?: Point): number {
  if (!point1 || !point2) return Infinity;

  return SQR(point1.x - point2.x, point1.y - point2.y);
}

/** return square distance between a point and the nearest point on a rectangle
 * to compare distances between UI elements it's much faster to use this than the actual distance
 * as there's no need to do a Math.sqrt
 */
export function distanceBetweenPointAndRectSQR(point?: Point, rect?: Rect): number {
  if (!point || !rect) return Infinity;

  const x_min = rect.origin.x;
  const x_max = rect.origin.x + rect.size.width;
  const y_min = rect.origin.y;
  const y_max = rect.origin.y + rect.size.height;

  if (point.x < x_min) {
    if (point.y < y_min) return SQR(x_min - point.x, y_min - point.y);
    if (point.y <= y_max) return x_min - point.x;
    return SQR(x_min - point.x, y_max - point.y);
  } else if (point.x <= x_max) {
    if (point.y < y_min) return y_min - point.y;
    if (point.y <= y_max) return 0;
    return point.y - y_max;
  } else {
    if (point.y < y_min) return SQR(x_max - point.x, y_min - point.y);
    if (point.y <= y_max) return point.x - x_max;
    return SQR(x_max - point.x, y_max - point.y);
  }
}

/** return distance between points
 * for faster comparisons between distances use distanceBetweenPoints
 * it's much faster to use this than the actual distance as there's no need to do a Math.sqrt
 */
export function distanceBetweenPoints(point1?: Point, point2?: Point): number {
  if (!point1 || !point2) return Infinity;
  return Math.sqrt(distanceBetweenPointsSQR(point1, point2));
}

/** return  distance between a point and the nearest point on a rectangle
 * for faster comparisons between distances use distanceBetweenPointAndRectSQR
 * it's much faster to use this than the actual distance as there's no need to do a Math.sqrt
 */
export function distanceBetweenPointAndRect(point?: Point, rect?: Rect): number {
  if (!point || !rect) return Infinity;
  return Math.sqrt(distanceBetweenPointAndRectSQR(point, rect));
}

export interface ExtraSize extends Size {
  extraWidth: number;
  extraHeight: number;
}

//const = readonly | let = read and write
export const enum Keys {
  up = "ArrowUp",
  down = "ArrowDown",
  left = "ArrowLeft",
  right = "ArrowRight",
  back = "Backspace",
  select = "Enter",
  one = "1",
  two = "2",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
  seven = "7",
  eight = "8",
  nine = "9",
  zero = "0",
  red = "ColorF0Red",
  green = "ColorF1Green",
  yellow = "ColorF2Yellow",
  blue = "ColorF3Blue",
  forward = "MediaFastForward",
  rewind = "MediaRewind",
  play = "MediaPlay",
  pause = "MediaPause",
  playPause = "MediaPlayPause",
  stop = "MediaStop",
  exit = "Exit",
  next = "MediaTrackNext",
  previous = "MediaTrackPrevious",
  pageUp = "PageUp",
  pageDown = "PageDown",
}

/** @description IFocusable base interface. If an element can get focus & is put in the focus tree, it needs to define those functions
 */
export interface IFocusable {
  /** @description Is this element rejecting focus?
   * @returns true if visible (and therefore accepting focus), false otherwise. If returning false, the parent should try to move the focus to the "next in line"
   */
  rejectsFocus?: () => boolean;

  /** @description called after gaining focus
   */
  onFocused?: () => void;

  /** @description called after focus is lost
   */
  onUnfocused?: () => void;
}

export interface IShowable {
  /** @description called after being shown
   */
  onShown?: () => void;
  /** @description called after being hidden
   */
  onHidden?: () => void;
}

export interface IReleasable {
  onRelease?: () => void;
}

export interface INavigable {
  /** @description called to handle key event
   * @param key the event type
   * @returns true if nav was handled, false otherwise
   */
  onNav?: (key: Keys) => boolean;
}

export interface IMousable {
  /** gives a chance to the view to catch a mouse wheel event
   * @param deltaY amount scrolled
   * @param point the coordinates where the scroll occured, in screen rect
   * @param target: the element the scroll occured in
   */
  onMouseWheel?: (deltaY: number, point: Point, target: HTMLElement) => boolean;
  /** gives a chance to the view to catch a mouse move event
   * @param point the coordinates of the move, in screen rect
   * @param target: the element the move occured in
   */
  onMouseMove?: (point: Point, target: HTMLElement) => boolean;
  /** gives a chance to the view to catch a click & interrupt its processing
   * @param point the coordinates of the click, in screen rect
   * @param target: the element the click occured in
   */
  onMouseDown?: (point: Point, target: HTMLElement) => boolean;
  rejectsMouseFocus?: (childElement: HTMLElement) => boolean;
}

export interface IDelegate extends IReleasable, IFocusable, IShowable, INavigable, IMousable {}
