import "./miseEnAvantProgram.scss";

import { ItemCollection } from "~models/itemCollection";
import { checkImage } from "~tools/tool";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

type ItemImages = {
  iconUnfocused: string | null;
  iconFocused: string | null;
};
class ButtonView extends View {
  private images: ItemImages;
  private divImage: HTMLElement;
  constructor(rootElement: HTMLElement, text: string) {
    super(DOMHelper.createDivWithParent(rootElement, null, "meaButton"));
    const images: ItemImages = { iconUnfocused: null, iconFocused: null };
    try {
      images.iconUnfocused = require(`~images/pictos_options/play.png`);
      images.iconFocused = require(`~images/pictos_options/playFocused.png`);
    } catch (error) {
      console.error("[itemMenu] Error !", error);
    }
    this.images = images;
    this.divImage = DOMHelper.createDivImg(this.rootElement, null, "playIconProgram", images.iconFocused);
    //TODO: check if already played content or first time change text
    DOMHelper.createDivWithParent(this.rootElement, null, "meaButtonText", text);
  }
  onFocused() {
    this.divImage.style.background = `url(${this.images.iconFocused})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }
  onUnfocused() {
    this.divImage.style.background = `url(${this.images.iconUnfocused})`;
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }
}

export class MiseEnAvantProgram extends View {
  constructor(source: ItemCollection, onSelectButton?: () => boolean) {
    super(DOMHelper.createDivWithParent(null, "MEAProgramTop", "list"));
    const backgroundSrc = source.getBackgroundImgUrl();
    const logoSrc = source.getLogoImgUrl();

    checkImage(
      backgroundSrc,
      () => {
        const background = DOMHelper.createDivImg(this.rootElement, null, "MEABackground", backgroundSrc);
        background.style.backgroundPosition = "top right";
      },
      () => {
        DOMHelper.createDivImg(this.rootElement, null, "MEABackground");
      }
    );
    checkImage(
      logoSrc,
      () => {
        const titleImg = DOMHelper.createDivImg(this.rootElement, null, "titleImg", logoSrc);
        titleImg.style.backgroundSize = "contain";
      },
      () => {
        const boxText = DOMHelper.createDivWithParent(this.rootElement, null, "boxTitleText");
        DOMHelper.createDivWithParent(boxText, null, "titleText", source.title);
      }
    );
    DOMHelper.createImg(this.rootElement, null, "logoImg", require("~images/pictos_okoo/logo_okoo.png"), "");

    DOMHelper.createDivWithParent(this.rootElement, null, "meaSummary", source.summary);
    if (onSelectButton) {
      this.delegate = createListComponent({
        rootElement: this.rootElement,
        modelSource: new StaticModelSource(["button"]),
        viewFactory: () => {
          return new ButtonView(this.rootElement, source.extras);
        },
        onSelect: (item, index) => {
          if (onSelectButton !== undefined) {
            return onSelectButton();
          } else {
            return false;
          }
        },
        horizontal: true,
      });
    }
  }
}
