import "./arrowConsentManagement.scss";

import { DOMHelper, View } from "../../../../bud-lite-tv/src/lib";

export class Arrow extends View {
  private readonly _visible: boolean;
  constructor(visible = true) {
    super(DOMHelper.createDivWithParent(null, null, "arrowCMP", null));
    this._visible = visible;
    DOMHelper.createDivImg(this.rootElement, "ArrowImage", null, require("~images/cmp/shape.png"));
    this.rootElement.style.marginLeft = "463px";
    this.rootElement.style.marginRight = "489px";
    if (!this._visible) this.rootElement.style.display = "none";
  }
  rejectsFocus = () => !this._visible;
}
