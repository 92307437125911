import "./miseEnAvantView.scss";

import { checkImage } from "~tools/tool";
import { DOMHelper, View } from "~ui-lib";

import { Extrait } from "../models/extrait";
import { Integrale } from "../models/integrale";
import { Program } from "../models/program";
import { Unit } from "../models/unit";

export class MiseEnAvantView extends View {
  constructor(item: Program | Unit | Integrale | Extrait) {
    super(DOMHelper.createDivWithParent(null, item.id, "meaView"));
    const boxElement = DOMHelper.createDivWithParent(this.rootElement, null, "meaViewBoxZoom");
    const backgroundElement = DOMHelper.createDivWithParent(boxElement, null, "meaViewBackground");
    let label: string | undefined;
    if (item.extras.label_edito) {
      label = item.extras.label_edito;
    } else if (item.extras.program) {
      label = item.extras.program.extras.label_edito ? item.extras.program.extras.label_edito : undefined;
    }
    if (label) {
      DOMHelper.createDivWithParent(backgroundElement, null, "meaViewLabel", label);
    }
    let elementImgSrc = "";
    const placehoderTitle = DOMHelper.createDivWithParent(
      backgroundElement,
      null,
      "meaViewPlaceholderTitle",
      item.title
    );
    if (item.artworks) {
      const srcFromArtworks = item.getMEATileImgUrl();
      if (srcFromArtworks !== "") {
        elementImgSrc = srcFromArtworks;
      }
    }
    checkImage(
      elementImgSrc,
      () => {
        DOMHelper.createDivImg(backgroundElement, null, "meaViewImg", elementImgSrc);
        placehoderTitle.classList.add("hide");
      },
      () => {
        const fallbackBacgroundElement = DOMHelper.createDivWithParent(
          backgroundElement,
          null,
          "meaViewFallbackBackground"
        );
        DOMHelper.createDivImg(
          fallbackBacgroundElement,
          null,
          "meaViewFallbackLogo",
          require("~images/fallback/default_okoo_square.png")
        );
        DOMHelper.createDivWithParent(backgroundElement, null, "meaViewFallbackTitle", item.title);
        placehoderTitle.classList.add("hide");
      }
    );
  }
}
