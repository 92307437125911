import "./buttonCmp.scss";

import { DOMHelper, View } from "../../../../bud-lite-tv/src/lib";

export interface ButtonCmp {
  id: number;
  width: number;
  text: string;
  onPress: () => void;
}

export class ButtonConsentManagement extends View {
  constructor(item: ButtonCmp) {
    super(DOMHelper.createDivWithParent(null, `buttonCMP${item.text}`, "buttonCMP"));
    const text = DOMHelper.createDivWithParent(
      this.rootElement,
      `TextButtonCMP${item.text}`,
      "textButtonCMP",
      item.text
    );
    this.rootElement.style.width = item.width.toString() + "px";
  }
}
