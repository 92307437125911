import "./programTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { Plugin } from "~pages/../datas/plugin";
import { createListComponent, DOMHelper, IDelegate, IListComponent, isInDOM, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { parseMarkerPiano, parseMarkerPianoPageDisplay } from "../../datas/parser";
import { MiseEnAvantProgram } from "../../swimlaneViews/miseEnAvantProgram";
import { VideoSwimlane } from "../../swimlaneViews/videoSwimlane";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { pushPlayerPage } from "../rootPage";

class EmptyView extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, null, "EmptySlot"));
  }
  rejectsFocus = () => true;
}
export class ProgramTab extends View {
  private readonly _source: Program;
  listComponent?: IListComponent<Program | ItemCollection> & IDelegate;
  private _randomPlaylistIndex?: number = undefined;
  private _randomVideoIndex?: number = undefined;
  private _defaultFocusId?: string;
  private _defaultFocusRowIndex = -1;
  private _defaultFocusIndexVideo = -1;
  private _focusedViewDidChangeUnregister?: () => void;
  private _impressionEventSent = false;
  /**
   * Source has been fetched.
   * Meaning that source data is not partial.
   */
  private _sourceDetailled?: ItemCollection = undefined;

  // defaultFocusId : is set by deeplink on a video that has program
  constructor(source: Program, defaultFocusId?: string) {
    super(DOMHelper.createDivWithParent(null, "ProgramTab"));
    this._source = source;
    this._defaultFocusId = defaultFocusId;
    this._fetchSource();
  }

  onShown() {
    if (this._sourceDetailled !== undefined) {
      this._sendAnalyticsPageDisplay();
    }
    if (this.listComponent && this._randomPlaylistIndex !== undefined && this._randomVideoIndex !== undefined) {
      this.listComponent.setFocusOnIndex(this._randomPlaylistIndex, { animate: false }).then(() => {
        (this.listComponent?.viewFromIndex(this._randomPlaylistIndex) as VideoSwimlane).setFocusOnIndex(
          this._randomVideoIndex!
        );
        this._randomPlaylistIndex = undefined;
        this._randomVideoIndex = undefined;
      });
    }

    if (this.listComponent && this._defaultFocusRowIndex !== -1 && this._defaultFocusIndexVideo !== -1) {
      //setTimeout is needed so that the focus happens after all the items have loaded
      setTimeout(() => {
        this.listComponent?.setFocusOnIndex(this._defaultFocusRowIndex, { animate: false }).then(() => {
          (this.listComponent?.viewFromIndex(this._defaultFocusRowIndex) as VideoSwimlane).setFocusOnIndex(
            this._defaultFocusIndexVideo
          );
          this._defaultFocusRowIndex = -1;
          this._defaultFocusIndexVideo = -1;
        });
      }, 100);
    }

    // on every shown of the page the impression of the button "" should be resent when focused
    this._impressionEventSent = false;
  }

  private _isArrayOfItemCollection(value: unknown): boolean {
    let result = true;
    if (value instanceof Array) {
      value.forEach((item: unknown) => {
        if (!(item instanceof ItemCollection)) {
          result = false;
        }
      });
    }
    return result;
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailled(this._source)
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[DETAILLED] fetch from !", this._source);
          console.log("[DETAILLED] Next !", value);
          if (this._isArrayOfItemCollection(value[0].items)) {
            this._onSourceReady(value[0]);
          } else {
            const collection = value[0];
            this._onSourceReady(
              new ItemCollection(
                collection.id,
                collection.type,
                collection.title,
                collection.summary,
                [collection],
                collection.artworks,
                collection.extras
              )
            );
          }
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[DETAILLED] Error !", error);
        },
        () => {
          console.log("[DETAILLED] Complete !");
        }
      );
  }

  private _onSourceReady = (item: ItemCollection) => {
    const sourceMEA = new ItemCollection(
      "mise_en_avant_98465",
      "mise_en_avant",
      item.title || "",
      item.summary || "",
      [],
      item.artworks,
      "lire un épisode" || "reprendre"
    );

    this._sourceDetailled = item;
    this._sendAnalyticsPageDisplay();

    if (item.items.length) {
      const items = item.items as ItemCollection[];
      const emptySource = new ItemCollection("empty_53164", "empty", "empty", null, [], [], null);
      items.unshift(emptySource);
      items.unshift(sourceMEA);
      const indexFirstPLaylist = 2; // !! important because we added some item in items
      const onSelectButton = () => {
        this._randomPlaylistIndex =
          Math.floor(Math.random() * (items.length - indexFirstPLaylist)) + indexFirstPLaylist;
        this._randomVideoIndex = Math.floor(Math.random() * items[this._randomPlaylistIndex].items.length);
        const randomVideoSource = items[this._randomPlaylistIndex].items[this._randomVideoIndex];
        const markerPianoVideo = parseMarkerPiano(randomVideoSource.extras);
        sendPianoAnalytic(
          "click.action",
          {},
          { feature: "bouton_commencer_regarder", ...markerPianoVideo?.additional_properties, click: "lire_un_episode" }
        );
        pushPlayerPage(randomVideoSource);
        return true;
      };

      // find indexes of video to focus when come back from deeplink
      if (this._defaultFocusId !== undefined) {
        this._defaultFocusRowIndex = -1;
        this._defaultFocusIndexVideo = -1;
        for (let rowIndex = 0; rowIndex < items.length && this._defaultFocusRowIndex === -1; rowIndex++) {
          const rowItems = items[rowIndex].items;
          if (Array.isArray(rowItems)) {
            for (let itemIndex = 0; itemIndex < rowItems.length && this._defaultFocusIndexVideo === -1; itemIndex++) {
              if (rowItems[itemIndex].id === this._defaultFocusId) {
                this._defaultFocusRowIndex = rowIndex;
                this._defaultFocusIndexVideo = itemIndex;
              }
            }
          }
        }
      }
      //

      this.listComponent = this.delegate = createListComponent(
        {
          rootElement: DOMHelper.createDivWithParent(this.rootElement, "VerticalSwimlaneComponent", "list"),
          modelSource: new StaticModelSource(items),
          viewFactory: model => {
            if (model.type == "empty") {
              return new EmptyView();
            } else if (model.type == "mise_en_avant") {
              return new MiseEnAvantProgram(model, onSelectButton);
            } else if (model instanceof ItemCollection) {
              return new VideoSwimlane(model, true);
            } else return new VideoSwimlane(model, true);
          },
          horizontal: false,
          pageSize: 3,
          visibleAfter: 1,
          spatialFocus: true,
          arrowFactory: verticalArrowFactory,
          mouseFocusInPageOnly: true,
        },
        list => {
          const defaultIndex = 0;
          list.setFocusOnIndex(defaultIndex);
          if (isInDOM(list.rootElement)) {
            // when programTab is loaded from a deeplink, the app had push a playerPgae so the list in not in the dom
            // when the list is not in the dom, the setFocusONindex will not be managed correctly
            // this prt of code will be called in the onShow after quitting de playerPage
            if (this._defaultFocusRowIndex !== -1 && this._defaultFocusIndexVideo !== -1) {
              list.setFocusOnIndex(this._defaultFocusRowIndex, { animate: false }).then(() => {
                (list.viewFromIndex(this._defaultFocusRowIndex) as VideoSwimlane).setFocusOnIndex(
                  this._defaultFocusIndexVideo
                );
                this._defaultFocusRowIndex = -1;
                this._defaultFocusIndexVideo = -1;
              });
            }
          }
        }
      );

      // Listening to focused of the view containing the button
      // if no items there is no button, so we don't to listen
      // the "impression" should be sent only sent by each page shown
      this._focusedViewDidChangeUnregister = this.listComponent.focusedView$.didChange(view => {
        if (view instanceof MiseEnAvantProgram && !this._impressionEventSent) {
          sendPianoAnalytic(
            "publisher.impression",
            {},
            {
              feature: "bouton_commencer_regarder",
            }
          );
          this._impressionEventSent = true;
        }
      });
    } else {
      this.listComponent = this.delegate = createListComponent({
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "VerticalSwimlaneComponent", "list"),
        modelSource: new StaticModelSource([sourceMEA]),
        viewFactory: model => {
          return new MiseEnAvantProgram(model);
        },
        horizontal: false,
        pageSize: 1,
        visibleAfter: 1,
        spatialFocus: true,
        arrowFactory: verticalArrowFactory,
        mouseFocusInPageOnly: true,
      });
      const noResultPage = DOMHelper.createDivWithParent(this.rootElement, null, "noResultPage");
      DOMHelper.createDivWithParent(noResultPage, null, "noResultTitle", "Oups !");
      DOMHelper.createDivWithParent(noResultPage, null, "noResultSubtitle", "Il n'y a pas de résultat.");
      DOMHelper.createDivImg(noResultPage, null, "noResultIcon", require(`~images/buttonProgram/noResult.png`));
    }
  };

  private _sendAnalyticsPageDisplay = () => {
    if (this._sourceDetailled !== undefined) {
      const markerPiano = parseMarkerPianoPageDisplay(this._sourceDetailled.extras);

      if (markerPiano !== undefined) {
        const page_state =
          this._sourceDetailled.items && this._sourceDetailled.items.length > 0
            ? "page_avec_contenu"
            : "page_sans_contenu";

        markerPiano.additional_properties.page_state = page_state;
        sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
        return;
      }
    }
    Log.analytics.error("Failed to send piano analytics");
  };

  onRelease = () => {
    this._focusedViewDidChangeUnregister?.();
  };
}
