import { Log } from "../log";
import { View } from "../types/view";

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const keyGenerator = (object: any): string => {
  switch (typeof object) {
    case "object":
      if (object.id !== undefined) {
        switch (typeof object.id) {
          case "number":
            return (object.id as number).toString();

          case "string":
            return object.id as string;

          default:
            Log.ui.error(`can't index, wrong type for id in ${JSON.stringify(object, null, 2)}!`);
        }
      } else Log.ui.error(`can't index, no id in ${JSON.stringify(object, null, 2)}!`);
      break;

    case "number":
      return (object as number).toString();

    case "string":
      return object as string;

    default:
      throw `can't index, wrong type of ${JSON.stringify(object, null, 2)}!`;
  }
  return "#wrongId#";
};

export function objFilter<T>(obj: { [key: string]: T }, predicate: (key: string, value: T) => boolean) {
  return Object.keys(obj)
    .filter(key => predicate(key, obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});
}

export class BaseComponent<M, V> extends View {
  componentId: string;

  // our list of ids for every elemeent
  ids: string[] = [];
  // a map for the views we're displaying - by (unique) ids
  viewMap: { [id: string]: V } = {};
  modelMap: { [id: string]: M } = {};
  offsetMap: { [id: string]: number } = {};

  constructor(rootElement: HTMLElement) {
    super(rootElement);
    this.componentId = rootElement.id;
  }

  viewFromId = (id?: string): V | undefined => {
    if (id == undefined) return undefined;
    const view = this.viewMap[id];
    if (!view) {
      // Log.ui.error(`no views with id ${id} on ${this.componentId}`);
      return;
    }
    return view;
  };

  modelFromId = (id?: string): M | undefined => {
    if (id == undefined) return undefined;
    const model = this.modelMap[id];
    if (model === undefined) {
      // Log.ui.error(`no model with id ${id} on ${this.componentId}`);
      return;
    }
    return model;
  };

  indexFromId = (id?: string): number | undefined => {
    if (id == undefined) return undefined;
    const index = this.ids.indexOf(id);
    if (index === -1) {
      // Log.ui.error(`no views with id ${id} on ${this.componentId}`);
      return;
    }
    return index;
  };

  viewFromIndex = (index?: number): V | undefined => {
    if (index == undefined || this.ids.length == 0) return undefined;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return;
    }
    return this.viewFromId(this.ids[index]);
  };

  modelFromIndex = (index?: number): M | undefined => {
    if (index == undefined || this.ids.length == 0) return undefined;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return;
    }
    return this.modelFromId(this.ids[index]);
  };

  offsetFromIndex = (index?: number) => {
    if (index == undefined || this.ids.length == 0) return 0;

    if (index < 0 || index > this.ids.length - 1) {
      // Log.ui.warn(`index ${index} out of bounds on ${this.componentId} [0 - ${this.ids.length - 1}]`);
      return 0;
    }
    return this.offsetMap[this.ids[index]] ?? 0;
  };
}

// export the imports (to prevent potential circular imports)
export * from "./listComponent";
export * from "./switchComponent";
export * from "./modelSource";
