/* eslint-disable @typescript-eslint/no-explicit-any */

export class Metadata {
  extras: any;
  duration: number;
  casting?: Array<Person>;
  productionYear?: number;
  rating?: Rating;

  constructor(extras: any, duration: number, casting?: Array<Person>, productionYear?: number, rating?: Rating) {
    this.extras = extras;
    this.casting = casting;
    this.duration = duration;
    this.productionYear = productionYear;
    this.rating = rating;
  }
}

export class Rating {
  title: string;
  code: string;
  level: number;

  constructor(title: string, code: string, level: number) {
    this.title = title;
    this.code = code;
    this.level = level;
  }

  picto(): string | null {
    let picto = null;
    switch (this.code) {
      case "10":
        picto = require("~images/pictos_csa/-10.png");
        break;
      case "12":
        picto = require("~images/pictos_csa/-12.png");
        break;
      case "16":
        picto = require("~images/pictos_csa/-16.png");
        break;
      case "18":
        picto = require("~images/pictos_csa/-18.png");
        break;
      default:
        break;
    }

    return picto;
  }
}

export class Person {
  title: string;
  type: string;

  constructor(title: string, type: string) {
    this.title = title;
    this.type = type;
  }
}
