import "./cmpMenu.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../../../bud-lite-tv/src/lib";
import { ButtonCmp, ButtonConsentManagement } from "../cmpButton/buttonCmp";

export enum CmpMenuText {
  saveChoice = "Valider mes choix",
  acceptAll = "Tout accepter",
  rejectAll = "Tout refuser",
  privacyPolicy = "Politique de confidentialité",
  myConsent = "Mes consentements",
  ourPartners = "Nos partenaires",
}

export class CmpMenu extends View {
  constructor(items: ButtonCmp[]) {
    super(DOMHelper.createDivWithParent(null, "CmpMenu"));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(items),
      viewFactory: model => {
        return new ButtonConsentManagement(model);
      },
      horizontal: false,
      pageSize: 5,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: false,
      onSelect: this._onItemSelect,
    });
  }

  _onItemSelect = (item: ButtonCmp) => {
    switch (item.text) {
      case CmpMenuText.saveChoice:
        item.onPress();
        return true;
      case CmpMenuText.acceptAll:
        item.onPress();
        return true;
      case CmpMenuText.rejectAll:
        item.onPress();
        return true;
      case CmpMenuText.privacyPolicy:
        item.onPress();
        return true;
      case CmpMenuText.myConsent:
        item.onPress();
        return true;
      case CmpMenuText.ourPartners:
        item.onPress();
        return true;
      default:
        return false;
    }
  };
}
