/**
 * @description Cookie box
 */
export class CookieStorage implements Storage {
  // hopefully noone will ever need those
  length = 0;
  key = (): string => "";

  clear = (): void => {
    document.cookie = "";
  };

  /**
   *@description Get cookie storage value
   *@param sKey The cookie key to find
   *@returns The stored value
   */
  getItem = (sKey: string): string | null => {
    if (!sKey || !RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=").test(document.cookie)) {
      return null;
    }
    return unescape(
      document.cookie.replace(
        new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"),
        "$1"
      )
    );
  };

  /**
   * @description Create a new cookie
   * @param sKey New cookie key
   * @param sValue New cookie value
   */
  setItem = (sKey: string, sValue: string): void => {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/.test(sKey)) {
      return;
    }
    this.removeItem(sKey);
    let sExpires = "";
    const dateCookie = new Date();
    dateCookie.setTime(dateCookie.getTime() + 3600 * 1000 * 24 * 365);
    sExpires = "; expires=" + dateCookie.toUTCString();
    document.cookie = escape(sKey) + "=" + escape(sValue) + sExpires;
  };

  /**
   * @description Delete cookie
   * @param sKey Cookie key to delete
   */
  removeItem = (sKey: string): void => {
    if (!sKey || !RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=").test(document.cookie)) {
      return;
    }
    const oExpDate = new Date(0);
    document.cookie = escape(sKey) + "=; expires=" + oExpDate.toUTCString();
  };
}
