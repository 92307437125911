import "./agePage.scss";

import { Storage } from "~libs/storage";
import { DynamicBackground } from "~pages/dynamicBackground";
import { createListComponent, DOMHelper, IListComponent, IPage, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { MainMenuComponent } from "~views/mainMenu/mainMenu";

import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { getAgeCustomParams, setPianoAge } from "../../tools/analytics/piano";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { HomeTab } from "../home/homeTab";
import { AgeTileView, ItemAge } from "./itemAge";
export class AgePage extends View implements IPage {
  public static selectAge: ItemAge | undefined = undefined;
  listComponent?: IListComponent<ItemAge>;
  private _background?: DynamicBackground;
  title: string;
  subTitle: string;
  private sound: string | undefined;
  private audio: HTMLAudioElement | undefined;
  private _isFirstPage: boolean;
  private _logo: HTMLElement | undefined;
  private _onSelectAge: (itemAge: ItemAge) => void;

  constructor(isFirstPage: boolean, onSelectAge: (itemAge: ItemAge) => void) {
    super(DOMHelper.createDivWithParent(null, "AgePage"));
    this._onSelectAge = onSelectAge;
    this._isFirstPage = isFirstPage;
    if (isFirstPage) {
      this.title = "Choisir un âge";
      this.subTitle = `Le contenu proposé est automatiquement adapté en fonction de l'âge sélectionné.`;
    } else {
      this.title = "Changer d'âge";
      this.subTitle = `Modifier l'âge permet de découvrir d'autres contenus.`;
    }

    this.sound = require(`./../../../static/sounds/choose_age.mp3`);
    this.audio = new Audio(String(this.sound));
    this.audio.autoplay = true;
    this.audio.muted = JSON.parse(Storage.getItem("muted") || "false");
    this.audio.play();

    this._fetchSource();
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: "choix_age", page_type: "compte" }, {});
  }

  private _fetchSource = () => {
    Plugin.getInstance()
      .fetchAge()
      .subscribe(
        (items: ItemAge[]) => {
          // Here use it to create the UI
          this._onASourceReady(items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[AGE] Error !", error);
        },
        () => {
          Log.api.info("[AGE] Complete !");
        }
      );
  };

  private _onASourceReady(source: ItemAge[]) {
    this._background = new DynamicBackground(this.rootElement);
    DOMHelper.createDivWithParent(this.rootElement, null, "contentAgePageTitle", this.title);
    DOMHelper.createDivWithParent(this.rootElement, null, "contentAgePageSubTitle", this.subTitle);

    this._logo = DOMHelper.createImg(
      this.rootElement,
      null,
      "contentAgePageLogo" + (this._isFirstPage ? " nomenu" : ""),
      require("~images/pictos_okoo/logo_okoo.png"),
      ""
    );

    // Play sound when enter the page "Choisi ton âge !" so wait for 1s to display and focus age
    setTimeout(() => {
      const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentAgePageSwimlane");
      this.listComponent = this.delegate = createListComponent(
        {
          rootElement: contentRoot,
          modelSource: new StaticModelSource(source),
          viewFactory: model => {
            return new AgeTileView(this, model);
          },
          arrowFactory: horizontalArrowFactory,
          pageSize: this._isFirstPage ? 10 : 8,
          visibleAfter: 1,
          visibleBefore: 1,
          horizontal: true,
          onSelect: this.onSelect,
        },
        mainList => {
          if (Storage.getItem("age") != null) {
            const a = Number(Storage.getItem("age"));
            mainList.setFocusOnIndex(a - 3);
          } else {
            mainList.setFocusOnIndex(0);
          }
        }
      );
      this._background?.setAgeCollectionList(this.listComponent);
    }, 1000);
  }

  onRelease = () => {
    this._background?.onRelease();
  };

  onSelect = (model: ItemAge): boolean => {
    this.sound = require(`./../../../static/sounds/go_age.mp3`);
    this.audio = new Audio(String(this.sound));
    this.audio.muted = JSON.parse(Storage.getItem("muted") || "false");
    this.audio.play();

    AgePage.selectAge = model;

    const visitorAgeClicked = getAgeCustomParams(model.age).visitor_age;
    if (this._isFirstPage) {
      setPianoAge(model.age);
      if (visitorAgeClicked !== undefined) {
        sendPianoAnalytic("click.action", {}, { feature: "popin_choix_age", click: visitorAgeClicked });
      }
    } else {
      // if not first page (open by menu or profil/button), the click should contains the old value of visitor_age and visitor_category
      if (visitorAgeClicked !== undefined) {
        sendPianoAnalytic("click.action", {}, { feature: "popin_choix_age", click: visitorAgeClicked });
      }
      setPianoAge(model.age);
    }
    navigationStack.destroyStack();

    Storage.setItem("age", model.age.toString());
    MainMenuComponent.setMenuAge(model);
    HomeTab._currentAge = model;
    this._onSelectAge(model);

    return true;
  };
}
