"use strict";
// this is the first thing to call in main. It defines the polyfills depending on the platform type,
// and does platform specific work (key registering for example)
require("~ui-lib/platform");

require("./log");

import "./main.scss";

import { logKeyHandler } from "@dotscreen/log-tv";

import { createNavigationStack, debounce, mapKeyboardEvent, platform, PlatformType } from "~ui-lib";

export const navigationStack = createNavigationStack({
  rootElement: document.getElementById("navigationStack")!,
  mouseSupport: true,
  wheelThrottle: platform.type == PlatformType.other ? 500 : undefined,
  onExit: () => {
    console.warn("exit! show menu first?");
    exitApp();
  },
});

import { startMultitaskingManager } from "~tools/multitasking";

import { LaunchPage } from "./pages/launch/launchPage";
import { ExitPopup } from "./pages/popup/exitPopup";
import { initializePianoAnalytics } from "./tools/analytics/piano";
import { networkManager } from "./tools/networkManager";

initializePianoAnalytics();

// the app entry point. Nothing should be done / created / instantiated before that is called
function startApp() {
  document.addEventListener(
    "keydown",
    ev => {
      if (platform.type === PlatformType.philips) {
        ev.preventDefault(); // by default Philips TVs close the app on back pressed regardless of the current page.
      }
      const key = mapKeyboardEvent(ev);
      // if log handles key we don't want the app to process the key
      if (logKeyHandler(key, "keydown")) return;
      navigationStack.keyHandler(key);
    },
    false
  );

  if (!__IS_RELEASE__) {
    // debug version
    document.querySelector("#versionApp")!.innerHTML = `${__APP_VERSION__}-${__APP_HASH__}-${
      __IS_PRODUCTION__ ? "prod" : "dev"
    }`;
    // // debug counter
    // showDebugStats("debugStats");
  }

  startMultitaskingManager();
  networkManager.init();
  navigationStack.pushPage(new LaunchPage());
}

export function exitApp(): boolean {
  const exitPage = new ExitPopup();
  navigationStack.pushPage(exitPage);

  const _hide = debounce(() => {
    navigationStack.removePage(exitPage);
  }, 2000);

  _hide();

  return false;
}

window.onload = startApp;
