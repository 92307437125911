import { DOMHelper, IView, ListComponentParams, Point, pointInRect } from "..";
import { screenRectOf } from "../helpers/HTMLElementHelper";
import { Log } from "../log";
import { callWithDelegateFallback } from "./../helpers/delegate";
import { Listenable } from "./../helpers/Listenable";
import { isOnClick } from "./declare";
import { isOnHover, ListComponent } from "./listComponent";
import { _navigationStack, InputMode } from "./navigation";

export class MouseListComponent<M, V extends IView = IView> extends ListComponent<M, V> {
  lastMousePos: Point = { x: 0, y: 0 };
  arrowPrev?: IView;
  arrowNext?: IView;
  focusedArrow$ = new Listenable<IView | undefined>(undefined);
  focusedArrowUnregister: () => void;
  // it's already listened to in ListComponent
  _focusedUnregister?: () => void;
  inputModeUnregister?: () => void;

  constructor(params: ListComponentParams<M, V>) {
    super(params);

    this.arrowPrev = this.params.arrowFactory?.("prev");
    this.arrowNext = this.params.arrowFactory?.("next");
    this.arrowPrev && this.rootElement.appendChild(this.arrowPrev?.rootElement);
    this.arrowNext && this.rootElement.appendChild(this.arrowNext?.rootElement);

    // if we have an anHover method, handle the mecanism here
    let hoverTimer: number | undefined;
    // no need to keep the unregister func, the listenable is our own so it'll be destroyed along with listeners
    this.focusedArrowUnregister = this.focusedArrow$.didChange(focusedArrow => {
      // whenever the focus arrow changes, stop the timer
      window.clearTimeout(hoverTimer);
      window.clearInterval(hoverTimer);
      if (focusedArrow && isOnHover(this.params.arrowScrollMethod)) {
        hoverTimer = window.setTimeout(() => {
          window.clearTimeout(hoverTimer);
          Log.ui_list.log("hover timer delay over, scrolling");

          if (isOnHover(this.params.arrowScrollMethod)) {
            this.setScrollIndexFromArrow(
              (this.scrollIndex$.value ?? 0) +
                (focusedArrow == this.arrowPrev ? -1 : 1) * this.params.arrowScrollMethod.onHover.scrollBy
            );
            hoverTimer = window.setInterval(async () => {
              Log.ui_list.log("hover interval, scrolling");
              if (isOnHover(this.params.arrowScrollMethod)) {
                this.setScrollIndexFromArrow(
                  (this.scrollIndex$.value ?? 0) +
                    (focusedArrow == this.arrowPrev ? -1 : 1) * this.params.arrowScrollMethod.onHover.scrollBy
                );

                const maxScroll =
                  Math.floor(Math.max(this.ids.length, 0) / this.params.crossSectionWidth) - this.params.pageSize;
                if (
                  this.scrollIndex$.value == 0 ||
                  (this.scrollIndex$.value == maxScroll && this._modelSource?.isComplete())
                ) {
                  window.clearInterval(hoverTimer);
                }
              }
            }, this.params.arrowScrollMethod.onHover.interval);
          }
        }, this.params.arrowScrollMethod.onHover.delay);
      }
    });

    if (this.arrowPrev && this.arrowNext) {
      this.updateArrowPrevVisibility(this.scrollIndex$.value ?? 0);
      this.updateArrowNextVisibility();
      this.inputModeUnregister = _navigationStack!.inputMode$.didChange(inputMode => {
        switch (inputMode) {
          case InputMode.keys:
            this.focusedArrow$.value = undefined;
            break;

          case InputMode.pointer:
            break;
        }
        this.updateArrowPrevVisibility(this.scrollIndex$.value ?? 0);
        this.updateArrowNextVisibility();
      });

      this._focusedUnregister = this.focused$.didChange(() => {
        this.updateArrowPrevVisibility(this.scrollIndex$.value ?? 0);
        this.updateArrowNextVisibility();
      });
    }
  }

  onRelease(): void {
    super.onRelease();
    this.inputModeUnregister?.();
    this._focusedUnregister?.();
    this.focusedArrowUnregister();
  }

  onUnfocused(): void {
    super.onUnfocused();
    DOMHelper.removeClass(this.focusedArrow$.value?.rootElement, "focused");
    this.focusedArrow$.value = undefined;
  }

  async setScrollIndexFromArrow(scrollIndex: number): Promise<void> {
    // keep the focus "under" the arrow
    if (this.focusedArrow$.value == this.arrowPrev) {
      this.focusedIndex$.value = Math.max(0, scrollIndex * this.params.crossSectionWidth);
    } else {
      this.focusedIndex$.value =
        (Math.min(this.ids.length, scrollIndex + this.params.pageSize) - 1) * this.params.crossSectionWidth;
    }
    await this.setScrollIndex(scrollIndex, true);
  }

  updateArrowPrevVisibility = (scrollIndex: number): void => {
    if (_navigationStack?.inputMode$.value == InputMode.keys || !this.focused$.value) {
      this.arrowPrev && (this.arrowPrev.rootElement.style.opacity = "0");
      return;
    }

    // check if we are showing less than the page size - in this case, no arrows
    if (this._modelSource?.isComplete() == true && this.ids.length <= this.params.pageSize) {
      this.arrowPrev && (this.arrowPrev.rootElement.style.opacity = "0");
      return;
    }

    if (this.params.arrowShowOnBoundaries == false) {
      // prev before scrolling
      if (scrollIndex <= 0) {
        if (this.arrowPrev && this.arrowPrev.rootElement.style.opacity != "0") {
          this.arrowPrev.rootElement.style.opacity = "0";
          // need to clear the focusedArrow if it went away
          if (this.focusedArrow$.value == this.arrowPrev) {
            this.focusedArrow$.value = undefined;
            // this.focusNearPoint(this.lastMousePos, false);
          }
        }
      } else {
        if (this.arrowPrev && this.arrowPrev.rootElement.style.opacity == "0")
          this.arrowPrev.rootElement.style.opacity = "1";
      }
    } else {
      this.arrowPrev && (this.arrowPrev.rootElement.style.opacity = "1");
    }
  };

  updateArrowNextVisibility = async (): Promise<void> => {
    if (_navigationStack?.inputMode$.value == InputMode.keys || !this.focused$.value) {
      this.arrowNext && (this.arrowNext.rootElement.style.opacity = "0");
      return;
    }

    // check if we are showing less than the page size - in this case, no arrows
    if (this._modelSource?.isComplete() == true && this.ids.length <= this.params.pageSize) {
      this.arrowNext && (this.arrowNext.rootElement.style.opacity = "0");
      return;
    }

    if (this.params.arrowShowOnBoundaries == false) {
      // next after scrolling
      const maxScrollLine =
        Math.floor(Math.max(this.ids.length, 0) / this.params.crossSectionWidth) - this.params.pageSize;
      if (this.scrollIndex$.value && this.scrollIndex$.value >= maxScrollLine && this._modelSource?.isComplete()) {
        if (this.arrowNext && this.arrowNext.rootElement.style.opacity != "0") {
          this.arrowNext.rootElement.style.opacity = "0";
          // need to clear the focusedArrow if it went away
          if (this.focusedArrow$.value == this.arrowNext) {
            this.focusedArrow$.value = undefined;
            // this.focusNearPoint(this.lastMousePos, false);
          }
        }
      } else {
        if (this.arrowNext && this.arrowNext.rootElement.style.opacity == "0")
          this.arrowNext.rootElement.style.opacity = "1";
      }
    }
  };

  async setScrollIndex(scrollIndex: number, animate = true): Promise<void> {
    // const focusedArrow = this.focusedArrow$?.value;
    await super.setScrollIndex(scrollIndex, animate);
    this.updateArrowPrevVisibility?.(scrollIndex);
    await this.updateArrowNextVisibility?.();
  }

  focusFromSubView(target: HTMLElement): void {
    // check arrows
    // priority to arrows if they exist
    const arrows = [this.arrowPrev, this.arrowNext];
    let focusedArrow: IView | undefined = undefined;
    arrows.forEach(arrow => {
      if (arrow?.rootElement.style.opacity != "0" && arrow?.rootElement.contains(target)) {
        DOMHelper.addClass(arrow?.rootElement, "focused");
        focusedArrow = arrow;
      } else {
        DOMHelper.removeClass(arrow?.rootElement, "focused");
      }
    });

    // if focus wasn't on an arrow, then regular focus on an element
    if (!focusedArrow) {
      // special handling for mouse: am I allowed to focus?
      super.focusFromSubView(target);
      // don't handle focus here, it could mess the overall focus state if moving the cursor while scrolling for example
    } else {
      // otherwise, don't focus an element but update the focus tree
      this.updateFocusTree();
    }

    this.focusedArrow$.value = focusedArrow;
  }

  onMouseMove(point: Point, target: HTMLElement): void {
    this.lastMousePos = point;
    this.focusFromSubView(target);
    // this.focusNearPoint(this.lastMousePos, false);
  }

  onMouseDown(point: Point, target: HTMLElement): boolean {
    this.lastMousePos = point;
    switch (this.focusedArrow$.value ?? false) {
      case this.arrowPrev:
      case this.arrowNext:
        if (isOnClick(this.params.arrowScrollMethod)) {
          this.setScrollIndexFromArrow(
            (this.scrollIndex$.value ?? 0) +
              (this.focusedArrow$.value == this.arrowPrev ? -1 : 1) * this.params.arrowScrollMethod.onClick.scrollBy
          );
        }
        return true;

      default: {
        // we need to make sure the nearest view contains the cursor to do select
        const nearestIndex = this.viewIndexNearPoint(this.lastMousePos);
        const nearestModel = this.modelFromIndex(nearestIndex);
        const nearestSubView = this.viewFromIndex(nearestIndex);

        return (
          (nearestModel &&
            pointInRect(this.lastMousePos, screenRectOf(nearestSubView?.rootElement)) &&
            this.params.onSelect?.(nearestModel, nearestIndex ?? 0)) ||
          false
        );
      }
    }
  }

  onMouseWheel(deltaY: number, point: Point, target: HTMLElement): boolean {
    this.lastMousePos = point;
    if (!this.params.horizontal) {
      (async () => {
        Log.ui_list.warn(
          "start scroll to",
          this.scrollIndex$.value ?? 0 + (deltaY < 0 ? -1 : 1) * this.params.wheelScrollBy
        );
        await this.setScrollIndex((this.scrollIndex$.value ?? 0) + (deltaY < 0 ? -1 : 1) * this.params.wheelScrollBy);
        // need to find the subview & do a mousemove on it
        const nearestIndex = this.viewIndexNearPoint(this.lastMousePos);
        const nearestSubView = this.viewFromIndex(nearestIndex);
        Log.ui_list.warn("scroll over, doing mouse move", nearestIndex, nearestSubView?.rootElement.id);
        callWithDelegateFallback(nearestSubView, "onMouseMove", point, target);
      })();
      return true;
    } else return false;
  }

  rejectsMouseFocus(childElement: HTMLElement): boolean {
    if (!this.params.mouseFocusInPageOnly) return false;
    let rejectsMouseFocus = false;
    this.onEveryVisible(index => {
      if (
        !rejectsMouseFocus &&
        this.viewFromIndex(index)?.rootElement == childElement &&
        !(this.viewFromIndex(index)?.rejectsFocus?.() ?? false)
      ) {
        rejectsMouseFocus =
          rejectsMouseFocus ||
          !(
            index >= (this.scrollIndex$.value ?? 0) * this.params.crossSectionWidth &&
            index < ((this.scrollIndex$.value ?? 0) + this.params.pageSize) * this.params.crossSectionWidth
          );
      }
    });

    // if I'm rejecting the focus, I also need to unfocus my arrows
    rejectsMouseFocus && DOMHelper.removeClass(this.arrowPrev?.rootElement, "focused");
    rejectsMouseFocus && DOMHelper.removeClass(this.arrowNext?.rootElement, "focused");

    return rejectsMouseFocus;
  }
}
