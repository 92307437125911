export type Listener<T> = (newValue: T, oldValue: T) => void;

export type IListenable<T> = {
  readonly value: T;
  willChange: (listener: Listener<T>) => () => void;
  didChange: (listener: Listener<T>) => () => void;
};

export class Listenable<T> implements IListenable<T> {
  private _currentValue: T;
  private _willChangeListeners: Listener<T>[] = [];
  private _didChangeListeners: Listener<T>[] = [];

  constructor(initialValue: T) {
    this._currentValue = initialValue;
  }
  get value(): T {
    return this._currentValue;
  }
  set value(newValue: T) {
    if (this._currentValue != newValue) {
      const oldValue = this._currentValue;
      this._willChangeListeners.forEach(listener => listener(newValue, oldValue));
      this._currentValue = newValue;
      this._didChangeListeners.forEach(listener => listener(newValue, oldValue));
    }
  }
  /**
   * @description Register to value changes
   * @param listener the listener callback function
   * @returns the unregister function
   */
  willChange = (listener: Listener<T>): (() => void) => {
    if (!this._willChangeListeners.includes(listener)) {
      this._willChangeListeners.push(listener);
      // listener(this._currentValue, this._currentValue);
    }
    return () => {
      this._willChangeListeners = this._willChangeListeners.filter(
        registeredListener => registeredListener != listener
      );
    };
  };
  /**
   * @description Register to value changes
   * @param listener the listener callback function
   * @returns the unregister function
   */
  didChange = (listener: Listener<T>): (() => void) => {
    if (!this._didChangeListeners.includes(listener)) {
      this._didChangeListeners.push(listener);
      // listener(this._currentValue, this._currentValue);
    }
    return () => {
      this._didChangeListeners = this._didChangeListeners.filter(registeredListener => registeredListener != listener);
    };
  };
}
