export function formatAllValues(params: any) {
  for (const [key, value] of Object.entries(params)) {
    if (typeof value === "string") {
      params[key] = formatParam(value);
    }
  }
}

export function formatParam(str: string | null | undefined) {
  if (str) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Replace accented characters
    str = str.toLowerCase();
    str = str.replace(/[^a-z0-9\s_]/g, "");
    str = str
      .split(/\s/)
      .filter(str => str)
      .join("_");
    str = str.replace(/\s/g, "_");
    return str;
  }
  return "";
}

export function formatProgramName(str: string | null | undefined) {
  if (str) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Replace accented characters
    str = str.toLowerCase();
    str = str.replace(/[^a-z0-9]/g, "");
    return str;
  }
  return "";
}
