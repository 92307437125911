import "./cmpCheckbox.scss";

import { DOMHelper, View } from "../../../../bud-lite-tv/src/lib";

export class CmpCheckBoxView extends View {
  private _indicatorElement: HTMLElement;
  private _imageIndicatorElement: HTMLDivElement;
  private _enabled = false;
  public controlled = false;

  constructor(enabled: boolean) {
    super(DOMHelper.createDivWithParent(null, null, "checkbox"));

    /**
     * Initiliazing DOM
     */
    this._indicatorElement = DOMHelper.createDivWithParent(this.rootElement, null, null, null);
    this._imageIndicatorElement = DOMHelper.createDivImg(this._indicatorElement, null, "imgDot");

    /**
     * Setting default state
     */
    this.setState(enabled);
  }

  private _updateUI = () => {
    this._indicatorElement.className = this._enabled ? "dotAllow" : "dot";
    this._imageIndicatorElement.style.background = `url(${
      this._enabled ? require("~images/checkBox/check.png") : require("~images/checkBox/cross.png")
    }) 50% 50% no-repeat`;
  };

  public setState = (enabled: boolean) => {
    this._enabled = enabled;
    this._updateUI();
    return enabled;
  };

  public toggle = () => {
    return this.setState(!this._enabled);
  };
}
