import { ILogger, initNamespacedLog } from "@dotscreen/log-tv";

import { platform } from "./platform";

// specific use inside this library
const namespaces = ["keys", "ui", "ui_nav", "ui_list", "ui_switch", "net", "perf"] as const;
type LocalNamespacedLog = { [key in typeof namespaces[number]]: ILogger };

export const Log: LocalNamespacedLog = initNamespacedLog({
  namespaces,
}) as LocalNamespacedLog;

Log.ui.warn("Useragent:        " + navigator.userAgent);
Log.ui.warn("Platform type:    " + platform.type);
Log.ui.warn("Platform version: " + platform.version);
Log.ui.warn("device Name:      " + platform.deviceName);
Log.ui.warn("device Id:        " + platform.deviceId);
