import "./menuVertical.scss";

import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { ButtonCmp, ButtonConsentManagement } from "../../../views/cmp/cmpButton/buttonCmp";

export enum TextVerticalMenu {
  policyOf0 = "Politique de ...",
  policyOf1 = "...confidentialité",
  ourPartners = "Nos partenaires",
  setUp = "Paramétrer",
}

export class MenuVertical extends View {
  constructor(items: ButtonCmp[], defaultIndex = 0) {
    super(DOMHelper.createDivWithParent(null, "MenuVertical", null, null));
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource(items),
        viewFactory: model => {
          return new ButtonConsentManagement(model);
        },
        horizontal: false,
        pageSize: 3,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: true,
        onSelect: this._onItemSelect,
      },
      mainList => {
        this.handleTextButton(mainList);
        if (defaultIndex < items.length) mainList.setFocusOnIndex(defaultIndex);
      }
    );
  }

  handleTextButton(list: IListComponent<ButtonCmp>) {
    list.focusedId$.didChange(elementId => {
      const text = document.getElementById(`TextButtonCMP${TextVerticalMenu.policyOf0}`);
      if (text) {
        text.style.transitionDuration = "0.2s";
        text.innerText = elementId === "0" ? TextVerticalMenu.policyOf1 : TextVerticalMenu.policyOf0;
      }
    });
  }

  _onItemSelect = (item: ButtonCmp) => {
    switch (item.text) {
      case TextVerticalMenu.policyOf0:
        item.onPress();
        return true;
      case TextVerticalMenu.ourPartners:
        item.onPress();
        return true;
      case TextVerticalMenu.setUp:
        item.onPress();
        return true;
      default:
        return false;
    }
  };
}
