import { IListenable, Listenable, platform, PlatformType } from "~ui-lib";

import { connectionParam } from "../../typings/webOSTypes";
import { navigationStack } from "../main";
import { OfflinePopup } from "../pages/popup/offlinePopup";

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    webOSDev?: {
      connection: {
        getStatus: (arg0: connectionParam) => void;
      };
    };
  }
}

class NetworkManager {
  private readonly _isOnline$ = new Listenable(true);
  private readonly _popup: OfflinePopup = new OfflinePopup();
  private _isInit = false;

  init() {
    if (this._isInit) {
      Log.app.warn("NetworkManager already init ");
      return;
    }
    Log.app.log("NetworkManager init platform.type: ", platform.type);
    this._isInit = true;
    switch (platform.type) {
      case PlatformType.tizen:
        try {
          webapis.network.addNetworkStateChangeListener(() => {
            webapis.network.isConnectedToGateway() ? this.onOnline() : this.onOffline();
          });
        } catch (e: unknown) {
          Log.app.error("NetworkManager init failed", e);
        }
        break;
      case PlatformType.other:
        window.addEventListener("offline", () => {
          this.onOffline();
        });
        window.addEventListener("online", () => {
          this.onOnline();
        });
        break;
    }
  }

  onOffline() {
    Log.app.log("NetworkManager offline");
    navigationStack.pushPage(this._popup);
    this._isOnline$.value = false;
  }

  onOnline() {
    Log.app.log("NetworkManager online");
    navigationStack.removePage(this._popup);
    this._isOnline$.value = true;
  }

  get isOnline$(): IListenable<boolean> {
    return this._isOnline$;
  }
}

export const networkManager = new NetworkManager();
