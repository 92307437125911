import "./offlinePopup.scss";

import { DOMHelper, IPage, Keys, View } from "~ui-lib";

import { exitApp } from "../../main";

// caching img because popup is displayed when offline
const img = new Image();
img.src = require("/static/images/connexion/offline.png");

export class OfflinePopup extends View implements IPage {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "OfflinePopup"));
    DOMHelper.createDivWithParent(this.rootElement, null, "offlinePopupText", "Oups ! Il n'y a pas de connexion.");
    DOMHelper.createDivWithParent(this.rootElement, null, "offlinePopupImage");
  }

  onNav = (key: Keys) => {
    if (key === Keys.back) {
      exitApp();
      return true;
    }
    return false;
  };
}
