/* eslint-disable @typescript-eslint/no-explicit-any */

export class Media {
  start_date: Date | null;
  end_date: Date | null;
  extras: any;
  broadcast: Broadcast | null;

  constructor(extras: any, broadcast: Broadcast | null, start_date: Date | null, end_date: Date | null) {
    this.start_date = start_date;
    this.end_date = end_date;
    this.extras = extras;
    this.broadcast = broadcast;
  }
}

export class Broadcast {
  start_date: Date;
  end_date: Date;
  extras: any;

  constructor(start_date: Date, end_date: Date, extras: any) {
    this.start_date = start_date;
    this.end_date = end_date;
    this.extras = extras;
  }
}
