import { DIR, ExtraSize, IListComponent, Point, Rect } from "..";
import { Log } from "../log";
import { IView } from "../types";

declare global {
  interface HTMLElement {
    _dsSize?: ExtraSize;
    _dsTranslate?: Point;
    _dsListComponent?: IListComponent;
    _dsView?: IView;
    _dsListScrollElement?: boolean;
  }
}
// default base implementation for HTML "views"
// used for many endpoints, like video tile, channel tile, section, etc

export function isHtmlElement(value: unknown): value is HTMLElement {
  // Check if the value has a .go function. If so, it's an IValidator
  return value !== undefined
    ? typeof (value as HTMLElement).className === "string" && typeof (value as HTMLElement).id === "string"
    : false;
}

export function screenRectOf(element?: HTMLElement, expandBy = 0): Rect | undefined {
  if (!element) return undefined;
  const domRect = element.getBoundingClientRect();
  if (domRect.x == 0 && domRect.y == 0 && domRect.width == 0 && domRect.height == 0) {
    // assume we're not in the DOM yet, use the _dsTranslate as a reference
    domRect.x = element._dsTranslate?.x || 0;
    domRect.y = element._dsTranslate?.y || 0;
    domRect.width = (element._dsSize?.width || 0) + (element._dsSize?.extraWidth || 0);
    domRect.height = (element._dsSize?.height || 0) + (element._dsSize?.extraHeight || 0);
  }
  return {
    origin: { x: domRect.left - expandBy, y: domRect.top - expandBy },
    size: { width: domRect.width + 2 * expandBy, height: domRect.height + 2 * expandBy },
  };
}

/**
 * @description get dimensions of an element (cached after first call). Combination of getBoundingRect &
 * @param element the HTMLElement to measure
 * @returns the dimensions of the element
 */
export const sizeOf = (element?: HTMLElement): ExtraSize => {
  if (!element) return { width: 0, height: 0, extraWidth: 0, extraHeight: 0 };
  if (element._dsSize === undefined) {
    const styles = getComputedStyle(element);

    let marginLeft = parseFloat(styles.marginLeft);
    if (isNaN(marginLeft)) {
      Log.ui.warn("marginLeft NaN for ", element.id, element.className);
      marginLeft = 0;
    }
    let marginRight = parseFloat(styles.marginRight);
    if (isNaN(marginRight)) {
      Log.ui.warn("marginRight NaN for ", element.id, element.className);
      marginRight = 0;
    }
    let marginTop = parseFloat(styles.marginTop);
    if (isNaN(marginTop)) {
      Log.ui.warn("marginTop NaN for ", element.id, element.className);
      marginTop = 0;
    }
    let marginBottom = parseFloat(styles.marginBottom);
    if (isNaN(marginBottom)) {
      Log.ui.warn("marginBottom NaN for ", element.id, element.className);
      marginBottom = 0;
    }

    let paddingLeft = parseFloat(styles.paddingLeft);
    if (isNaN(paddingLeft)) {
      Log.ui.warn("paddingLeft NaN for ", element.id, element.className);
      paddingLeft = 0;
    }
    let paddingRight = parseFloat(styles.paddingRight);
    if (isNaN(paddingRight)) {
      Log.ui.warn("paddingRight NaN for ", element.id, element.className);
      paddingRight = 0;
    }
    let paddingTop = parseFloat(styles.paddingTop);
    if (isNaN(paddingTop)) {
      Log.ui.warn("paddingTop NaN for ", element.id, element.className);
      paddingTop = 0;
    }
    let paddingBottom = parseFloat(styles.paddingBottom);
    if (isNaN(paddingBottom)) {
      Log.ui.warn("paddingBottom NaN for ", element.id, element.className);
      paddingBottom = 0;
    }

    let borderLeft = parseFloat(styles.borderLeft);
    if (isNaN(borderLeft)) {
      Log.ui.warn("borderLeft NaN for ", element.id, element.className);
      borderLeft = 0;
    }
    let borderRight = parseFloat(styles.borderRight);
    if (isNaN(borderRight)) {
      Log.ui.warn("borderRight NaN for ", element.id, element.className);
      borderRight = 0;
    }
    let borderTop = parseFloat(styles.borderTop);
    if (isNaN(borderTop)) {
      Log.ui.warn("borderTop NaN for ", element.id, element.className);
      borderTop = 0;
    }
    let borderBottom = parseFloat(styles.borderBottom);
    if (isNaN(borderBottom)) {
      Log.ui.warn("borderBottom NaN for ", element.id, element.className);
      borderBottom = 0;
    }
    // default width / height to something non-null
    let width = parseFloat(styles.width);
    if (isNaN(width)) {
      Log.ui.warn("width NaN for ", element.id, element.className);
      width = 10;
    }
    let height = parseFloat(styles.height);
    if (isNaN(height)) {
      Log.ui.warn("height NaN for ", element.id, element.className);
      height = 10;
    }

    element._dsSize = {
      width,
      height,
      extraWidth: borderLeft + marginLeft + paddingLeft + borderRight + marginRight + paddingRight,
      extraHeight: borderTop + marginTop + paddingTop + borderBottom + marginBottom + paddingBottom,
    };
  }

  return {
    ...element._dsSize,
  };
};

export const clean = (element?: HTMLElement | null): void => {
  // OUT!
  try {
    element?.parentElement?.removeChild(element);
  } catch (error) {
    //
  }
};

export const setOrigin = (element: HTMLElement, origin?: Point, dir = DIR.ltr): void => {
  if (!origin) return;
  element.style.position != "absolute" && (element.style.position = "absolute");
  // support older webkit browsers, prefix with -webkit- in CSS.
  if (origin.x == 0 && origin.y == 0) {
    element.style.webkitTransform = element.style.transform = "";
  } else {
    element.style.webkitTransform = element.style.transform = `translate3d(${(dir == DIR.rtl ? -1 : 1) * origin.x}px, ${
      origin.y
    }px, 0px)`;
  }
  element._dsTranslate = origin;
};

export const isInDOM = (element: HTMLElement): boolean => {
  // figure if we're in the DOM or not
  let topMostParent = element.parentElement;
  while (topMostParent?.parentElement) topMostParent = topMostParent.parentElement;

  // if not in the DOM, no need to do anything because it won't work
  return topMostParent?.tagName === "HTML";
};
