import "./checkBoxPartners.scss";

import { DOMHelper, View } from "../../../../bud-lite-tv/src/lib";
import { Position } from "../../../tools/cmp/didomi";
import { CmpCheckBoxView } from "../../../views/cmp/cmpCheckbox/cmpCheckbox";
import { Partners } from "./partnersList";

export class CheckboxPartners extends View {
  private _checkboxView: CmpCheckBoxView;
  private _partner: Partners;

  constructor(partner: Partners) {
    super(DOMHelper.createDivWithParent(null, "CheckboxPartners"));
    this._partner = partner;

    DOMHelper.createDivWithParent(this.rootElement, partner.id === "All" ? "TitleAll" : null, "title", partner.title);
    this._checkboxView = new CmpCheckBoxView(partner.state === Position.allow);
    this.rootElement.appendChild(this._checkboxView.rootElement);
  }

  public toggle = () => {
    this._partner.state = this._partner.state === Position.allow ? Position.denied : Position.allow;
    return this._checkboxView.toggle();
  };

  public setState = (enabled: boolean) => {
    this._partner.state = enabled ? Position.allow : Position.denied;
    this._checkboxView.setState(enabled);
  };
}
