import "./searchTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { AgePage } from "~pages/gestionAge/agePage";
import { LoaderSearchView } from "~pages/loaderSearchPage/loaderSearchPage";
import { KeyComponent } from "~pages/search/keyComponent";
import { EmptySearchSwimlane } from "~swimlaneViews/emptySearchSwimlane";
import { MixedSwimlane } from "~swimlaneViews/mixedSwimlane";
import { SearchProgramSwimlane } from "~swimlaneViews/searchProgramSwimlane";
import { VideoSwimlane } from "~swimlaneViews/videoSwimlane";
import {
  createListComponent,
  debounce,
  DOMHelper,
  Listenable,
  ListenableSource,
  StaticModelSource,
  View,
} from "~ui-lib";

import { Plugin } from "../../datas/plugin";
import { Program } from "../../models/program";
import { SearchHeroSwimlaneList } from "../../swimlaneViews/searchHeroSwimlaneList";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { SearchTextBoxView } from "./searchTextBox";

export class SearchTab extends View {
  private _textToSearch$ = new Listenable("");
  private _keyComponent: KeyComponent;
  private _searchTextBoxView: SearchTextBoxView;
  private _updateFocusUnregister?: () => void;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "SearchTab", "searchTab"));
    this._searchTextBoxView = new SearchTextBoxView(this.rootElement, this._textToSearch$);
    this._keyComponent = new KeyComponent(this._textToSearch$);
    this._keyComponent.cacheable = true;
    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "SearchTabList", "searchTabList"),
        horizontal: false,
        visibleAfter: 0,
        visibleBefore: 0,
        pageSize: 2,
        modelSource: new StaticModelSource<"keyboard" | "result">(["keyboard", "result"]),
        viewFactory: model => {
          if (model === "keyboard") {
            return this._keyComponent;
          }
          return new SearchResultView(this._textToSearch$);
        },
      },
      list => {
        list.setFocusOnIndex(1);
        this._updateFocusUnregister = list.focusedId$.didChange((newId, oldId) => {
          if (newId === "keyboard" && oldId !== "keyboard" && oldId !== undefined) {
            this._keyComponent?.lowercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(0);
            this._keyComponent.digitKeyboard?.listOfKeysComponent.setFocusOnIndex(0);
            this._keyComponent.UppercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(0);
          }
        });
      }
    );
  }

  onRelease = () => {
    this._searchTextBoxView.onRelease();
    this._updateFocusUnregister?.();
  };
}

export enum SearchTabListItemType {
  defaultSearch = "defaultSearch", // SearchHeroSwimlaneList : list of swimlane by letters
  emptySearch = "emptySearch", // button new search and message no result
  defaultNoResult = "defaultNoResult", // default hero swimlane when no result
  programs = "programs",
  collections = "collections",
  videos = "videos",
}

type PageState = "recherche" | "recherche_avec_resultat" | "recherche_sans_resultat";

export class SearchResultView extends View {
  private _textToSearch$ = new Listenable("");
  private _programResults: ItemCollection = new ItemCollection("empty_53161", "empty", "empty", null, [], [], null);
  private _collectionResults: ItemCollection = new ItemCollection("empty_53162", "empty", "empty", null, [], [], null);
  private _videoResults: ItemCollection = new ItemCollection("empty_53163", "empty", "empty", null, [], [], null);
  private _loader = new LoaderSearchView();
  private _mainListData$ = new Listenable<SearchTabListItemType[]>([]);
  private _sourceChild: ItemCollection = new ItemCollection("empty_53164", "empty", "empty", null, [], [], null);
  private _textToSearchUnregister?: () => void;
  private _mainListUnregister?: () => void;
  constructor(textToSearch$: Listenable<string>) {
    super(DOMHelper.createDivWithParent(null, "SearchResult"));
    this._textToSearch$ = textToSearch$;
    this.startLoader();
    this._fetchSources();
  }

  private _fetchSources() {
    Plugin.getInstance()
      .fetchDefaultSearchByAge(AgePage.selectAge)
      .subscribe(
        value => {
          // Here use it to create the UI
          Log.api.log("[SearchPage] fetchDefaultSearchByAge next !", value);
          this._onSourceReady(value[0]);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.log("[SearchPage] fetchDefaultSearchByAge Error !", error);
        }
      );

    Plugin.getInstance()
      .fetchChildCategories(AgePage.selectAge)
      .subscribe(
        value => {
          // Here use it to get content if no result
          Log.api.log("[SearchPage] fetchChildCategories next !", value);
          if (value.length >= 2) this._sourceChild = value[1];
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.log("[SearchPage] fetchChildCategories Error !", error);
        }
      );
  }

  private _fetchSourcesSearch(searchString: string) {
    Plugin.getInstance()
      .fetchSearch(searchString)
      .subscribe(
        value => {
          // Here use it to get content searched
          Log.api.log("[SearchPage] fetchSearch next !", value);
          this.setSources(value[0]);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.log("[SearchPage] fetchSearch Error !", error);
        }
      );
  }

  onShown() {
    this._sendPianoAnalyticPageDisplay(this._pageState, true);
  }

  private _pageState: PageState = "recherche";

  private _sendPianoAnalyticPageDisplay = (pageState: PageState, onShown = false) => {
    // Do not allow the same page state to emit multiple hits (ex: on query change)
    // However, always send a hit when the page is shown again (ex: back)
    if (this._pageState === pageState && !onShown) {
      return;
    }
    this._pageState = pageState;
    const additionalParams:
      | { page_state: "recherche" | "recherche_avec_resultat" }
      | { page_state: "recherche_sans_resultat"; ise_keyword: string } =
      this._pageState === "recherche_sans_resultat"
        ? { page_state: this._pageState, ise_keyword: this._textToSearch$.value }
        : { page_state: this._pageState };
    sendPianoAnalytic("page.display", { page: "recherche", page_type: "recherche" }, additionalParams);
  };

  private _onSourceReady(source: ItemCollection) {
    this._mainListData$.value = [SearchTabListItemType.defaultSearch];
    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "SearchResultList", "searchResultList"),
        modelSource: new ListenableSource(this._mainListData$, false),
        viewFactory: model => {
          switch (model) {
            case SearchTabListItemType.defaultSearch:
              return new SearchHeroSwimlaneList(source);
            case SearchTabListItemType.programs:
              this._programResults.items.push(
                new Program("newSearch", "newSearch", "nouvelle recherche", null, [], null)
              );
              return new SearchProgramSwimlane(this._programResults, "Résultats programmes", this._textToSearch$.value);
            case SearchTabListItemType.defaultNoResult:
              return new SearchProgramSwimlane(this._sourceChild, "Suggestions programmes");
            case SearchTabListItemType.collections:
              return new MixedSwimlane(this._collectionResults, "Résultats collections", 5, this._textToSearch$.value);
            case SearchTabListItemType.videos:
              return new VideoSwimlane(this._videoResults, false, "SearchPage", this._textToSearch$.value);
            case SearchTabListItemType.emptySearch:
              return new EmptySearchSwimlane(this._textToSearch$);
          }
        },
        horizontal: false,
        visibleAfter: 2,
        visibleBefore: 1,
        pageSize: 1,
      },
      list => {
        list.setFocusOnIndex(0);
        if (this._loader && this._loader.rootElement.parentElement) {
          this._loader.end(() => {
            this.rootElement.removeChild(this._loader.rootElement);
          });
        }
      }
    );
    // hide content behind loader
    this._mainListUnregister = this._mainListData$.didChange(() => {
      if (this._loader && this._loader.rootElement.parentElement) {
        this._loader.end(() => {
          this.rootElement.removeChild(this._loader.rootElement);
        });
      }
    });

    const debouncedSearch = debounce(async (textToSearch: string, isCancel: boolean) => {
      if (isCancel) return;
      this.startLoader();
      if (this._textToSearch$.value.length > 0) {
        this._fetchSourcesSearch(textToSearch);
      } else {
        this._fetchSources();
      }
    }, 1000);

    this._textToSearchUnregister = this._textToSearch$.didChange(textToSearch => {
      if (textToSearch.length > 0) {
        debouncedSearch(textToSearch, false);
      } else {
        debouncedSearch(textToSearch, true);
        this._mainListData$.value = [SearchTabListItemType.defaultSearch];
        this._sendPianoAnalyticPageDisplay("recherche");
      }
    });
  }
  private setSources = (source: ItemCollection) => {
    const sourceList = [];
    for (const item in source.items) {
      if (source.items[item].type == "playlist_program") {
        this._programResults = source.items[item];
        sourceList.push(SearchTabListItemType.programs);
      } else if (source.items[item].type == "playlist_collection") {
        this._collectionResults = source.items[item];
        sourceList.push(SearchTabListItemType.collections);
      } else if (source.items[item].type == "playlist_video") {
        this._videoResults = source.items[item];
        sourceList.push(SearchTabListItemType.videos);
      }
    }
    if (sourceList.length == 0) {
      if (this._sourceChild.type != "empty") {
        this._mainListData$.value = [SearchTabListItemType.emptySearch, SearchTabListItemType.defaultNoResult];
      } else {
        this._mainListData$.value = [SearchTabListItemType.emptySearch];
      }
      this._sendPianoAnalyticPageDisplay("recherche_sans_resultat");
    } else {
      this._mainListData$.value = [...sourceList];
      this._sendPianoAnalyticPageDisplay("recherche_avec_resultat");
    }
  };

  private startLoader = () => {
    // Show loader and start anim
    this.rootElement.appendChild(this._loader.rootElement);
    this._loader.start();
  };

  onRelease = () => {
    this._loader?.onRelease();
    this._mainListUnregister?.();
    this._textToSearchUnregister?.();
  };
}
