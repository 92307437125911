/**
 * The ListComponent related structures.
 *
 * A ListComponent is created by calling {@link createListComponent} which returns an interface {@link IListComponent}
 *
 * A ListComponent is handling a list of elements. The elements to handle are input as a model via a kind of {@link IModelSource}.
 *
 * @module ListComponent
 */
import { clean, debounce, delay, DIR, DIR$, IListenable, IView, Keys, Listenable, Point, setOrigin, sizeOf } from "..";
import { callWithDelegateFallback } from "../helpers/delegate";
import { isInDOM, screenRectOf } from "../helpers/HTMLElementHelper";
import { Log } from "../log";
import { centerOf, distanceBetweenPointAndRectSQR, IDelegate } from "../types";
import { DOMHelper } from "./../helpers/DOMHelper";
import { BaseComponent, IModelSource, keyGenerator, objFilter } from "./declare";
import { MouseListComponent } from "./mouseListComponent";
import { _navigationStack } from "./navigation";

type OnClick = {
  onClick: {
    /** how many elements should be scrolled on an arrow mouse click?
     * @default <page_size>
     */
    scrollBy: number;
  };
};

type OnHover = {
  onHover: {
    /** time delay before the first "hover scroll". A good default value is 500 ms
     */
    delay: number;
    /** how long between subsequent "hover scroll". A good default value is 200ms (5 times per second)
     */
    interval: number;
    /** how many elements should be scrolled when hovering over the arrow?. With the previous default values, scrolling 1 by 1 feels natural
     */
    scrollBy: number;
  };
};

export function isOnClick(value: unknown): value is OnClick {
  return value !== undefined ? typeof (value as OnClick).onClick === "object" : false;
}

export function isOnHover(value: unknown): value is OnHover {
  return value !== undefined ? typeof (value as OnHover).onHover === "object" : false;
}

/** @internal */
export function isListComponent(value: unknown): value is IListComponent {
  // Check if the value has a .go function. If so, it's an IValidator
  return value !== undefined ? typeof (value as IListComponent).onContentReady === "function" : false;
}

/** The user interface of the ListComponent object, created by {@link createListComponent}
 * @typeParam M the type of model. It's infered by the modelSource or the viewFactory in the params
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface IListComponent<M = {}> {
  /** the DOM element managed by this view
   */
  readonly rootElement: HTMLElement;
  /** the index of the first element showing on the left/top of the list */
  readonly scrollIndex$: IListenable<number | undefined>;
  /** the index of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedIndex$: IListenable<number | undefined>;
  /** the id of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedId$: IListenable<string | undefined>;
  /** the View of the element that has the focus at the moment. It can be undefined in the case there aren't any elements in the list (empty model array) */
  readonly focusedView$: IListenable<IView | undefined>;
  /** is the list focused (part of the focused tree) or not. */
  readonly focused$: IListenable<boolean>;
  /** has the focus changed from a mouse event or key event */
  readonly focusedFromMouse$: IListenable<boolean>;
  /** is the list shown or not. If the parent of the list is hidden, the list is hidden. */
  readonly shown$: IListenable<boolean>;

  /**
   * @description async - clears all data in the list (views and model), refetches everything from the source, recreates views
   * @param keepSelection (defaults to false) if true, tries to set the selection (and therefore the scroll index) to where it was before reset. WARNING: if the focused ID is not in the new model array, the whole source is fetched/read.
   * @param animate (defaults to true) if true, any scrolling related to resetting the content will be animated
   */
  resetContent(keepSelection?: boolean, animate?: boolean): Promise<void>;
  /**
   * @description returns a promise which resolves when the content has been reloaded. It's resolved at the end of the {@link resetContent} call. It's useful to
   * wait for content to be ready in case we know the content was reset but we aren't responsible of that reset.
   */
  onContentReady(): Promise<void>;
  /**
   * @description focuses an index
   * @param index index of model in model source
   * @param options Various options for focus
   */
  setFocusOnIndex(
    index: number,
    options?: {
      /** if true, actually sets the focus, rewriting the whole focus tree
       * if false, sets the "focus to be" in navigation lands in this list
       * @default true
       */
      focus?: boolean;
      /** if true, any scrolling related to the focus change will be animated.
       * @default true
       */
      animate?: boolean;
      /** if true, a focus change will trigger a scrolling to keep the focused element in view
       * @default true
       */
      scroll?: boolean;
    }
  ): Promise<void>;
  /**
   * @description focuses an index
   * @param id id of the model in model source (id is generated from the model with the keyGenerator)
   * @param options Various options for focus
   */
  setFocusOnId(
    id?: string,
    options?: {
      /** if true, actually sets the focus, rewriting the whole focus tree
       * if false, sets the "focus to be" in navigation lands in this list
       * @default true
       */
      focus?: boolean;
      /** if true, any scrolling related to the focus change will be animated.
       * @default true
       */
      animate?: boolean;
      /** if true, a focus change will trigger a scrolling to keep the focused element in view
       * @default true
       */
      scroll?: boolean;
    }
  ): Promise<void>;
  /**
   * @description retrieves the actual model from an id
   * @param id string generated from the model
   */
  modelFromId(id?: string): M | undefined;
  /**
   * @description retrieves a view from an id
   * @param id string generated from the model
   */
  viewFromId(id?: string): IView | undefined;
  /**
   * @description retrieves a view from an index
   * @param index number
   */
  viewFromIndex(index?: number): IView | undefined;
  /**
   * @description retrieves the actual model from an index
   * @param index number
   */
  modelFromIndex(index?: number): M | undefined;
}

/**
 * Defines the type of scrolling mode for the ListComponent (with the keys, the mouse has its own mechanism)
 */
export enum ScrollingMode {
  /**
   * Scroll page by page.
   *
   * The {@link pageSize} should therefore be specified.
   * If for example the pageSize is set to 4, when the focus is on index 3 (last index of the page):
   * - going right will scroll the whole list by 4 elements to the left - which means the first visible element will be index 4.
   * - going left when the first element is 4 will scroll the whole list by 4 elements to the right - reverting the first visible index to 0
   */
  page = "page",
  /**
   * Scroll by moving a page around.
   *
   * The {@link pageSize} should therefore be specified. If for example the pageSize is set to 4, when the focus is on index 3 (last index of the page)
   * - going right will scroll the whole list by 1 element, making the first visible element index 1  (pushing the page boundary)
   * - going left will only scroll the page to the right if going "over" the first index (pushing the page boundary)
   */
  slidingWindow = "slidingWindow",
  /**
   * Scroll by moving a page around but keeping the focus on the last element of the page until there's no more elements at the left of the page
   *
   * The {@link pageSize} should therefore be specified. If for example the pageSize is set to 4, when the focus is on index 3 (last index of the page)
   * - going right will scroll the page left by one element, leaving the focus on the last index of the page
   * - going left will scroll the page right by one element, until the first element is back on the first index of the model list. After that the focus moves back to the beginning of the list
   */
  elasticWindow = "elasticWindow",
}

/** Parameters to create a ListComponent Object
 * @typeParam M the type of model. It's infered by the modelSource or the viewFactory
 * @typeParam V the type of returned view. V needs to extend {@link IView}. It's infered from the return type of the provided viewFactory function
 */
export interface ListComponentParams<M, V extends IView> {
  /** object handling requests to get data back
   */
  modelSource: Promise<IModelSource<M>> | IModelSource<M>;
  /** the DOM element the list will use as its root */
  rootElement: HTMLElement;
  /** function which, provided a model, should return an list view element
   * @param item an item from the model
   * @param index the index of the passed item in the modelSource array
   * @returns a view representing the model
   */
  viewFactory: (item: M, index: number) => V;
  /** is the list horizontal? reacts on up/down. Otherwise on left/right */
  horizontal: boolean;

  /** callback function called when an element is selected.
   * @param item an item from the model
   * @param index the index of the passed item in the modelSource array
   * @returns true if the action was handled, false to let the parent elements have the chance to handle the selection
   * @default undefined
   */
  onSelect?: (model: M, index: number) => boolean;
  /** which element should has focus on start. Defaults to none which in turn means first element if any elements are created
   * @default undefined
   */
  defaultFocusId?: string;
  /** if true, will forcefully set width & height to the children's size after layout (kinda expensive for big lists).
   * @default false
   */
  fitToChildren?: boolean;
  /** How many elements per row(line). Defaults to 1 (one row if horizontal, or on column if vertical)
   * @default 1
   */
  crossSectionWidth?: number;
  /** How many rows(lines) in a focus page. A focus page is the amount of element the focus can move around without scrolling the view.
   * @default 20
   */
  pageSize?: number;
  /**
   * (optional) How many rows(lines) should be visible *before* the first row(line) of the page.
   * @default 0
   */
  visibleBefore?: number;
  /** (optional) How many rows(lines) should be visible *after* the last row(line). Defaults to 0
   * @default 0
   */
  visibleAfter?: number;
  /** how should focus movement scroll the list
   * @default ScrollingMode.page
   */
  scrollingMode?: ScrollingMode;
  /** should focus tried to be set based on where (spatially) the previous focus was? Otherwise focus reverts to its last value
   * @default false
   */
  spatialFocus?: boolean;
  /** how long a scroll duration should be - in ms
   * @default 400
   */
  scrollDuration?: number;
  /** offset the position of the focused element
   * @default 0
   */
  anchorOffset?: number;
  /** an additional offset specific for each element. If not specified, no element-specific offset
   * @param item an item from the model
   * @param index the index of the passed item in the modelSource array
   * @returns the offset (in pixel) specific to the view corresponding to the model; this provided offset is added to the computed translation from the previous elements.
   *
   * for example: a list has 3 elements; the first 2 elements are 100px high; therefore the third element has an translation of 2 * 100px;
   * if viewOffset for that item is 100, the view will end up at 300px translation rather than 200px.
   * @default undefined
   */
  viewOffset?: (item: M, index: number) => number | undefined;
  /** force a specific RTL or LTR layout
   * @default undefined (adapts to global layout)
   */
  dir?: DIR;
  /** how many elements should be scrolled on a wheel event?
   * @default <page_size>
   */

  wheelScrollBy?: number;
  /** a view factory for the arrow overlays of the list
   * @default undefined
   */
  arrowFactory?: (position: "prev" | "next") => IView;
  /** the scroll method for both arrows.
   * @default onClick, move by page
   */
  arrowScrollMethod?: OnClick | OnHover;
  /** show left arrow if showing the first element?
   * @default false
   */
  arrowShowOnBoundaries?: boolean;
  /** hovering with the mouse on elements outside of the page won't move the focus if set to true
   * @default false
   */
  mouseFocusInPageOnly?: boolean;
  /** if set to true, the list won't try to position children via a transform, won't scroll either
   * @default false
   */
  noTransform?: boolean;
}

interface ListComponentParamsMandatory<M, V extends IView = IView> {
  modelSource: Promise<IModelSource<M>> | IModelSource<M>;
  rootElement: HTMLElement;
  viewFactory: (item: M, index: number) => V;
  horizontal: boolean;
  onSelect: (model: M, index: number) => boolean;
  defaultFocusId?: string;
  fitToChildren: boolean;
  crossSectionWidth: number;
  pageSize: number;
  visibleBefore: number;
  visibleAfter: number;
  scrollingMode: ScrollingMode;
  spatialFocus: boolean;
  scrollDuration: number;
  anchorOffset: number;
  viewOffset: (item: M, index: number) => number | undefined;
  dir?: DIR;
  wheelScrollBy: number;
  arrowFactory?: (position: "prev" | "next") => IView;
  arrowScrollMethod: OnClick | OnHover;
  arrowShowOnBoundaries: boolean;
  mouseFocusInPageOnly: boolean;
  noTransform: boolean;
}

/**
 * @description creates a listComponent
 * @typeParam M the type of model. It's infered by the modelSource or the viewFactory in the params
 * @typeParam V the type of returned view. V needs to extend {@link IView}. It's infered from the return type of the provided viewFactory function in the params
 * @param params listComponent's creation parameters
 * @param onReadyCallback callback function called when the list has been populated
 */
export function createListComponent<M, V extends IView = IView>(
  params: ListComponentParams<M, V>,
  onReadyCallback?: (listComponent: IListComponent<M>) => void
): IListComponent<M> & IDelegate {
  const listComponent = _navigationStack?.mouseSupport ? new MouseListComponent(params) : new ListComponent(params);
  (async () => {
    await listComponent.onContentReady();
    // and we tell the consumer the list is ready
    onReadyCallback?.(listComponent);
  })();

  return listComponent;
}

/** @internal */
export class ListComponent<M, V extends IView = IView> extends BaseComponent<M, V> implements IListComponent {
  params: ListComponentParamsMandatory<M, V>;
  rootElement: HTMLElement;
  scrollElement: HTMLElement;

  private RTLUnregister?: () => void;
  private focusedIndexUnregister: () => void;
  private focusedIdWillChangeUnregister: () => void;
  private focusedIdDidChangeUnregister: () => void;
  private focusedUnregister: () => void;

  scrollIndex$ = new Listenable<number | undefined>(undefined);
  focusedIndex$ = new Listenable<number | undefined>(undefined);
  focusedId$ = new Listenable<string | undefined>(undefined);
  focusedView$ = new Listenable<IView | undefined>(undefined);
  focused$ = new Listenable(false);
  shown$ = new Listenable(false);
  focusedFromMouse$ = new Listenable(false);

  // private state
  private _lastElementScrollOffset = 0;
  /** our current scroll operation ID. It's increased on every scroll. After a scroll completes, it's checked for match. If a scoll occured while a scroll was already going on, it's ignored */
  private _scrollOpIdGenerator = 0;
  protected _modelSource?: IModelSource<M>;

  /** our list of potential waiters on onReady */
  private _onContentReadyResolve: (() => void | PromiseLike<void>)[] = [];

  /**
   * @description the constructor
   * @param params the parameters defining the behavior of the list
   * @returns index component
   */
  constructor(params: ListComponentParams<M, V>) {
    super(params.rootElement);
    if (params.visibleBefore && params.visibleBefore < 0) {
      Log.ui_list.error("Visible start should always be positive. Defaulting to 0");
      params.visibleBefore = 0;
    }
    this.params = {
      ...params,
      // optional values
      onSelect: params.onSelect ?? (() => false),
      crossSectionWidth: params.crossSectionWidth ?? 1,
      pageSize: params.pageSize ?? 20,
      visibleBefore: params.visibleBefore ?? 0,
      visibleAfter: params.visibleAfter ?? 0,
      scrollingMode: params.scrollingMode ?? ScrollingMode.page,
      spatialFocus: params.spatialFocus ?? false,
      scrollDuration: params.scrollDuration ?? 400,
      fitToChildren: params.fitToChildren ?? false,
      anchorOffset: params.anchorOffset ?? 0,
      viewOffset: params.viewOffset ?? (() => 0),
      wheelScrollBy: params.wheelScrollBy ?? params.pageSize ?? 20,
      arrowScrollMethod: params.arrowScrollMethod ?? { onClick: { scrollBy: params.pageSize ?? 20 } },
      arrowShowOnBoundaries: params.arrowShowOnBoundaries ?? false,
      mouseFocusInPageOnly: params.mouseFocusInPageOnly ?? false,
      noTransform: params.noTransform ?? false,
    };
    // listen to RTL updates
    if (!this.params.dir) {
      this.RTLUnregister = DIR$.didChange(() => {
        this.resetListLayout();
        // update the scrollElement to reflect the change now - remove/reset the transition to not have it animated
        updateScrollPosition(this.scrollElement, this.scrollElement._dsTranslate || { x: 0, y: 0 }, 0);
      });
    }
    // keep all listenables in sync
    this.focusedIndexUnregister = this.focusedIndex$.didChange(focusedIndex => {
      this.focusedId$.value = focusedIndex != undefined ? this.ids[focusedIndex] : undefined;
      this.focusedView$.value = this.viewFromIndex(focusedIndex);
    });

    this.focusedIdWillChangeUnregister = this.focusedId$.willChange(focusedId => {
      const newFocusedView = this.viewFromId(focusedId);
      if (this.focused$.value) {
        callWithDelegateFallback(newFocusedView, "onFocused", false);
      }
    });

    this.focusedIdDidChangeUnregister = this.focusedId$.didChange((focusedId, oldFocusedId) => {
      const index = focusedId != undefined ? this.ids.indexOf(focusedId) : -1;
      this.focusedIndex$.value = index == -1 ? undefined : index;

      // maintain focus from ids - ids can't change, indexes can
      const newFocusedView = this.viewFromId(focusedId);
      const oldFocusedView = this.viewFromId(oldFocusedId);
      if (this.focused$.value) {
        newFocusedView?.rootElement && DOMHelper.addClass(newFocusedView?.rootElement, "focused");
        callWithDelegateFallback(newFocusedView, "onFocused", false);
        this.updateFocusTree();
      }
      oldFocusedView?.rootElement && DOMHelper.removeClass(oldFocusedView?.rootElement, "focused");
      callWithDelegateFallback(oldFocusedView, "onUnfocused");
      this.focusedView$.value = newFocusedView;
    });

    this.focusedUnregister = this.focused$.didChange(focused => {
      if (focused) {
        this.focusedView$.value?.rootElement && DOMHelper.addClass(this.focusedView$.value?.rootElement, "focused");
        callWithDelegateFallback(this.focusedView$.value, "onFocused", false);
        this.updateFocusTree();
      } else {
        this.focusedView$.value?.rootElement && DOMHelper.removeClass(this.focusedView$.value?.rootElement, "focused");
        callWithDelegateFallback(this.focusedView$.value, "onUnfocused");
      }
    });
    this.rootElement = params.rootElement;
    this.rootElement._dsListComponent = this;
    this.scrollElement = DOMHelper.createDivWithParent(this.rootElement);
    this.scrollElement._dsListScrollElement = true;
    if (this.params.dir) this.scrollElement.dir = this.params.dir;

    this.resetContent(false, false);
  }

  public onRelease(): void {
    this.ids = [];
    // remove views from the DOM
    Object.values(this.viewMap).forEach(view => {
      // only clean/hide a view if it's still "ours". Cacheable views (a persistent rootMenu) could be moved across multiple lists
      if (view?.rootElement.parentElement == this.scrollElement) {
        clean(view?.rootElement);
        callWithDelegateFallback(view, "onHidden");
      }
      !(view.cacheable ?? false) && callWithDelegateFallback(view, "onRelease");
    });
    this.viewMap = {};
    this.modelMap = {};
    this._lastElementScrollOffset = 0;
    this.offsetMap = {};

    this.RTLUnregister?.();
    this.focusedIndexUnregister();
    this.focusedIdWillChangeUnregister();
    this.focusedIdDidChangeUnregister();
    this.focusedUnregister();
    this._modelSource?.onRelease?.();
  }

  resetListLayout = debounce(() => {
    if (!isInDOM(this.rootElement)) return;
    Log.ui_list.log(`${this.rootElement.id} resetListLayout`);
    this.offsetMap = {};
    this.updateListLayout();
  }, 10);

  updateListLayout = (): void => {
    this._lastElementScrollOffset = 0;

    if (!isInDOM(this.rootElement)) return;

    const that = this;
    let maxAlong = 0;
    let offsetAcross = 0;
    let overallAcross = 0;

    this.ids.forEach((id, index) => {
      const view = this.viewFromId(id);
      if (view) {
        const model = that.modelFromId(id);
        const viewOffset = model ? this.params.viewOffset?.(model, index) : 0;

        const viewSize = sizeOf(view.rootElement);
        const widthAlong = that.params.horizontal
          ? viewSize.width + viewSize.extraWidth
          : viewSize.height + viewSize.extraHeight;
        const widthAcross = that.params.horizontal
          ? viewSize.height + viewSize.extraHeight
          : viewSize.width + viewSize.extraWidth;
        if (that.offsetMap[id] == undefined) {
          if (this.params.noTransform === false) {
            const origin = that.params.horizontal
              ? { x: that._lastElementScrollOffset, y: offsetAcross }
              : { x: offsetAcross, y: that._lastElementScrollOffset };

            Log.ui_list.trace(
              `${this.rootElement.id} updateListLayout - view ${id} setOrigin ${JSON.stringify(origin)}`
            );
            setOrigin(view.rootElement, origin, this.params.dir ?? DIR$.value);
            that.offsetMap[id] = that._lastElementScrollOffset - (viewOffset ?? 0);
          }
        }

        // make sure the view comes back here (if it's cacheable & got dragged into another list)
        if (
          view.rootElement.parentElement != this.scrollElement &&
          index >= ((this.scrollIndex$.value ?? 0) - this.params.visibleBefore) * this.params.crossSectionWidth &&
          index <
            ((this.scrollIndex$.value ?? 0) + this.params.pageSize + this.params.visibleAfter) *
              this.params.crossSectionWidth
        ) {
          this.scrollElement.appendChild(view.rootElement);
          callWithDelegateFallback(view, "onShown");
        }

        // now decide how the offsets will change
        // if the list is N items wide, then it needs to increase the scrollOffset if globalIndex % N ==  N-1 (last one of the "row")
        const column = index % that.params.crossSectionWidth;
        // maintain max size along the list
        maxAlong = Math.max(maxAlong, widthAlong);

        // only count visible items for overall along (and across?)
        overallAcross = Math.max(overallAcross, offsetAcross + widthAcross);

        if (column == that.params.crossSectionWidth - 1) {
          // last column, reset across offset, move along with maxAlong
          offsetAcross = 0;
          that._lastElementScrollOffset += maxAlong;
          maxAlong = 0;
        } else {
          // move across, maintain maxAlong
          offsetAcross += widthAcross;
        }
      }
      this.updateSizeAlong(overallAcross);
    });
  };

  // after a scroll, need to update
  updateSizeAlong(overallAcross?: number): void {
    if (overallAcross !== undefined && this.params.fitToChildren) {
      let overallAlong = 0;
      Log.ui_list.debug(`${this.rootElement.id} updateSizeAlong`);

      // go over all visible items
      this.onEveryVisible(index => {
        let widthAlong = 0;
        for (let stride = 0; stride < this.params.crossSectionWidth; stride++) {
          const view = this.viewFromIndex(index * this.params.crossSectionWidth + stride);
          if (view) {
            const viewSize = sizeOf(view.rootElement);

            widthAlong = Math.max(
              widthAlong,
              this.params.horizontal ? viewSize.width + viewSize.extraWidth : viewSize.height + viewSize.extraHeight
            );
          }
        }

        overallAlong += widthAlong;
      });

      this.rootElement.style.width = `${this.params.horizontal ? overallAlong : overallAcross}px`;
      this.rootElement.style.height = `${!this.params.horizontal ? overallAlong : overallAcross}px`;

      // clear up the cached size
      delete this.rootElement._dsSize;
      // and walk out the parent list to call the relayout above
      let walkElement = this.rootElement.parentElement;
      while (walkElement && walkElement.parentElement != document.body) {
        if (walkElement._dsListComponent) {
          if (
            this.rootElement.parentElement != walkElement &&
            this.rootElement.parentElement != (walkElement._dsListComponent as ListComponent<M, V>).scrollElement
          ) {
            Log.ui_list.warn(
              `WARNING: "${this.rootElement.id}" trying to resize parent, but element "${this.rootElement?.parentElement?.id}" is not a IView of ${walkElement.id}`
            );
          }
          (walkElement._dsListComponent as ListComponent<M, V>).resetListLayout();
          walkElement = null;
        }
        walkElement = walkElement?.parentElement || null;
      }
    }
  }

  // in "line" units
  hideLines(fromLine: number, toLine: number): void {
    Log.ui_list.trace(`${this.rootElement.id} hide from ${fromLine} to ${toLine}`);
    // we need to hide what's now not visible
    for (
      let index = Math.max(0, fromLine * this.params.crossSectionWidth);
      index < Math.min(this.ids.length, toLine * this.params.crossSectionWidth);
      index++
    ) {
      Log.ui_list.trace(`${this.rootElement.id} hiding ${index}`);
      const view = this.viewFromIndex(index);
      clean(view?.rootElement);
      callWithDelegateFallback(view, "onHidden");
    }
  }

  public async resetContent(keepSelection = false, animate = true): Promise<void> {
    Log.ui_list.debug(`${this.rootElement.id} resetContent`);
    const oldScrollIndex = this.scrollIndex$.value;
    const oldFocusedId = this.focusedId$.value;

    //cleanup
    this.ids = [];
    // undef scroll index to be sure to refresh
    this._lastElementScrollOffset = 0;
    this.scrollIndex$.value = undefined;
    // this.focusedIndex$.value = undefined;
    this.offsetMap = {};
    this.modelMap = {};
    // at this point, we can remove any views that isn't to be cached
    this.viewMap = objFilter(this.viewMap, (_, view) => {
      // we cleanup from the DOM
      clean(view?.rootElement);
      callWithDelegateFallback(view, "onHidden");
      if (view.cacheable) {
        // we keep - but they lose focus and aren't released
        return true;
      } else {
        callWithDelegateFallback(view, "onRelease");
        return false;
      }
    });

    this._modelSource?.reset();
    // refetch all from scratch
    if (keepSelection && this.focusedId$.value) {
      await this.setFocusOnId(this.focusedId$.value, { focus: this.focused$.value, animate });
    } else {
      await this.setScrollIndex(keepSelection ? oldScrollIndex ?? 0 : 0, animate);
    }

    if (this.ids.length && (this.focusedId$.value == undefined || !this.ids.includes(this.focusedId$.value))) {
      // once we have content, we set the initial focus
      const firstFocusableId = this.params.defaultFocusId || this.getFirstFocusableId(0, 1);
      // we set what the focus will be when the focus path goes through here - but we don't actually set the focus unless we already have it
      firstFocusableId !== undefined && this.setFocusOnId(firstFocusableId, { focus: this.focused$.value, animate });
    }

    // when resetContent is done, notify all waiters it's done
    this._onContentReadyResolve.forEach(resolve => resolve());
    this._onContentReadyResolve = [];
  }

  public onContentReady(): Promise<void> {
    return new Promise(resolve => this._onContentReadyResolve.push(resolve));
  }

  receivedMore(data: M[], animate = true): void {
    // if no new elements, no need to do more. This breaks a potential loop with modelReceived
    if (data.length == 0) return;
    Log.ui_list.trace(`${this.rootElement.id} receivedMore (${data.length} items)`);

    const isInDom = isInDOM(this.rootElement);
    let viewsCreated = false;
    const that = this;

    const currentLength = this.ids.length;
    // we receive more data, so we append
    this.ids = [
      ...this.ids,
      ...data
        .filter(item => {
          const id = keyGenerator(item);
          if (!this.ids.includes(id)) return true;
          else {
            Log.ui_list.error(`duplicated id (${id}) in data set for list ${that.params.rootElement.id}`);
            return false;
          }
        })
        .map((item, index) => {
          const id = keyGenerator(item);
          // we need to keep track of the overall index, not just the additional one
          const globalIndex = currentLength + index;
          let view = that.viewMap[id];
          that.modelMap[id] = item;
          if (!view) {
            Log.ui_list.trace(`List ${that.componentId} creating view for index ${globalIndex}`);
            try {
              view = that.params.viewFactory(item, globalIndex);
              that.viewMap[id] = view;
              if (this.focused$.value && this.focusedId$.value && id == this.focusedId$.value) {
                // very specific use case - if we had the focus, been refreshed, are supposed to keep the focus - repush the focus on the new view right away
                // to prevent blinking
                view?.rootElement && DOMHelper.addClass(view?.rootElement, "focused");
                callWithDelegateFallback(view, "onFocused", false);
              }
              // force initial position to get consistent getSize values even if the scroller is translated
            } catch (error) {
              Log.ui_list.error(`List ${that.componentId} Exception thrown in arrowFactory:`, error);
            }
            viewsCreated = true;
          }
          this.scrollElement.appendChild(view.rootElement);
          if (isInDom) {
            callWithDelegateFallback(view, "onShown");
          }
          return id;
        }),
    ];
    this.updateListLayout();

    // reset focus if we have it now that we created views
    if (this.focused$.value && viewsCreated) this.onFocused();
  }

  async maybeFetch(toIndex: number, animate = true): Promise<void> {
    // fetch if more data is needed - based on current scrollIndex & visible items
    // to be sure in case we have promises, use an local async call

    // start an async fetch, then update the scroll index right away (fetch might be ongoing)
    const count = Math.max(toIndex, this.ids.length) - this.ids.length;

    if (count > 0 && !this._modelSource?.isComplete()) {
      const sourceVersion = this._modelSource?.version ?? 0;
      Log.ui_list.log(`List ${this.componentId} fetching ${count} more elements`);
      this._modelSource = this._modelSource || (await this.params.modelSource);
      // abort, source has changed
      if (sourceVersion != this._modelSource.version) {
        Log.ui_list.log(`List ${this.componentId} aborted maybeFetch - source version changed`);
        return;
      }
      this._modelSource.listComponent = this;
      const data = await this._modelSource.fetch(count);
      // abort, source has changed
      if (sourceVersion != this._modelSource.version) {
        Log.ui_list.log(`List ${this.componentId} aborted maybeFetch - source version changed`);
        return;
      }
      Log.ui_list.log(`List ${this.componentId} received ${data.length} more elements`);
      this.receivedMore(data, animate);
    }
  }

  /**
   * @description defines the first visible row/column. The unit isn't the element, it's depending on params.width !
    triggers fetching data from the model
   * @param index component index to focus
   */
  public async setScrollIndex(scrollIndex: number, animate = true): Promise<void> {
    // eslint-disable-next-line no-debugger
    // if (this.rootElement.id == "swimlane0" && scrollIndex == 0) debugger;
    const maxScrollLine =
      Math.floor(Math.max(this.ids.length - 1, 0) / this.params.crossSectionWidth) +
      1 -
      (this._modelSource?.isComplete() ? this.params.pageSize : 0);
    const toScrollIndex = Math.min(Math.max(scrollIndex, 0), Math.max(maxScrollLine, 0));
    Log.ui_list.debug(this.rootElement.id + " scrolling to", scrollIndex, toScrollIndex, maxScrollLine);
    const fromScrollIndex = this.scrollIndex$.value;

    // handle the case where we are relaying out because of RTL - no animation in this case
    const shouldUpdateScrollPosition =
      (this.ids.length === 0 || this.scrollIndex$.value != toScrollIndex) && this.params.noTransform === false;

    if (shouldUpdateScrollPosition) {
      Log.ui_list.debug(`${this.rootElement.id} setScrollIndex shouldUpdateScrollPosition`);
      // we need to show what will be visible next
      for (
        let index = Math.max(0, (toScrollIndex - this.params.visibleBefore) * this.params.crossSectionWidth);
        index <
        Math.min(
          this.ids.length,
          (toScrollIndex + this.params.pageSize + this.params.visibleAfter) * this.params.crossSectionWidth
        );
        index++
      ) {
        const view = this.viewFromIndex(index);
        view &&
          view.rootElement.parentElement != this.scrollElement &&
          this.scrollElement.appendChild(view.rootElement);
        callWithDelegateFallback(view, "onShown");
      }
    }

    // relative index in elements
    const itemIndex = toScrollIndex * this.params.crossSectionWidth;

    const offset = Math.max(0, this.offsetFromIndex(itemIndex) - this.params.anchorOffset);
    if (shouldUpdateScrollPosition) {
      Log.ui_list.debug(`${this.params.rootElement.id} started scrolling to ${offset}, animate: ${animate}`);
      this.scrollIndex$.value = toScrollIndex;
      const scrollDuration = animate && this.ids.length != 0 ? this.params.scrollDuration : 0;
      const scrollOpId = await updateScrollPosition(
        this.scrollElement,
        { x: this.params.horizontal ? -offset : 0, y: this.params.horizontal ? 0 : -offset },
        scrollDuration,
        ++this._scrollOpIdGenerator
      );
      // check if it's the last scroll operation
      if (scrollOpId == this._scrollOpIdGenerator) {
        if (fromScrollIndex != undefined && toScrollIndex > fromScrollIndex)
          this.hideLines(0, toScrollIndex - this.params.visibleBefore);
        if (fromScrollIndex != undefined && toScrollIndex < fromScrollIndex)
          this.hideLines(toScrollIndex + this.params.pageSize + this.params.visibleAfter, this.ids.length);
        Log.ui_list.debug(`${this.params.rootElement.id} finished scrolling to ${offset} in ${scrollDuration}ms`);
        this.updateSizeAlong(
          this.params.horizontal ? this.rootElement._dsSize?.height : this.rootElement._dsSize?.width
        );
      }
      // } else {
      //   this.scrollIndex$.value = -1;
    }

    // start an async fetch, let the new elements be populate (as visible)
    await this.maybeFetch(
      ((this.scrollIndex$.value ?? 0) + 2 * this.params.pageSize + this.params.visibleAfter) *
        this.params.crossSectionWidth,
      animate
    );
  }

  /**
   * @description get the first focusable component
   * @param index starting index
   * @param refocusDirection setting the focus tries to set the focus to the first element that accepts it starting at index. By default does nothing.
   * @returns true if focus was accepted, false otherwise
   */

  getFirstFocusableId(fromIndex: number, refocusDirection: -1 | 1): string | undefined {
    let targetIndex = fromIndex;
    let nextFocusedView = this.viewFromIndex(targetIndex);

    while (nextFocusedView?.rejectsFocus?.() ?? false) {
      // try to find one prev or next
      targetIndex = targetIndex + refocusDirection;
      if (targetIndex < 0 || targetIndex > this.ids.length - 1) return undefined;
      nextFocusedView = this.viewFromIndex(targetIndex);
    }

    return this.ids[targetIndex];
  }

  updateFocusTree(): void {
    // now that our own focusIndex is set, update our parents
    let walkElement: HTMLElement | null = this.rootElement;
    // a list as its own root, in which there's the scroller div, in which all the rootElements of the items
    while (walkElement && walkElement.parentElement && walkElement.parentElement.parentElement) {
      if (walkElement.parentElement.parentElement._dsListComponent) {
        // in case it's in a scroller
        (walkElement.parentElement.parentElement._dsListComponent as ListComponent<M, V> | undefined)?.focusFromSubView(
          walkElement
        );
      } else if (walkElement.parentElement._dsListComponent) {
        // in case it's static
        (walkElement.parentElement._dsListComponent as ListComponent<M, V> | undefined)?.focusFromSubView(walkElement);
      }

      walkElement = walkElement?.parentElement || null;
    }
  }
  /**
   * @description focus a component. It doesn't check if the component accepts focus or not
   * @param index component index to focus
   */
  async setFocusOnIndex(
    index?: number,
    options?: {
      focus?: boolean;
      animate?: boolean;
      scroll?: boolean;
      fromMouse?: boolean;
    }
  ): Promise<void> {
    this.focused$.value;
    Log.ui_list.log(
      `${this.params.rootElement.id} AM FOCUSED : ${this.focused$.value} setFocusOnIndex on ${index}, focus: ${
        options?.focus ?? true
      }`
    );
    this.focusedFromMouse$.value = options?.fromMouse ?? false;
    const targetScrollIndex = (() => {
      if (index == undefined) return undefined;
      switch (this.params.scrollingMode) {
        case ScrollingMode.page:
          return (
            Math.floor(Math.floor(index / this.params.crossSectionWidth) / this.params.pageSize) * this.params.pageSize
          );

        case ScrollingMode.slidingWindow:
          return Math.min(
            Math.floor(index / this.params.crossSectionWidth),
            Math.max(
              this.scrollIndex$.value ?? 0,
              Math.floor(index / this.params.crossSectionWidth) - (this.params.pageSize - 1)
            )
          );

        case ScrollingMode.elasticWindow:
          return Math.max(0, Math.floor(index / this.params.crossSectionWidth) - (this.params.pageSize - 1));
      }
    })();

    // in the case we don't show this index, then fetch more
    // if we're here from setting by id, it's been done already
    if (index != undefined && !this.viewFromIndex(index)) {
      await this.maybeFetch(index + 2 * this.params.pageSize + this.params.visibleAfter, options?.animate ?? true);
    }

    // first initial the scroll, to get the new elements in the DOM
    targetScrollIndex != undefined &&
      (options?.scroll ?? true) &&
      this.setScrollIndex(targetScrollIndex, options?.animate ?? true);
    if (options?.focus ?? true) {
      this.focused$.value = true;
    }
    const newIndex = index != undefined ? this.getFirstFocusableId(index, 1) : undefined;
    if (newIndex != this.focusedId$.value) {
      this.focusedId$.value = newIndex;
    }
    if (this.focusedId$.value != undefined) this.focusedIndex$.value = this.ids.indexOf(this.focusedId$.value);
  }

  /**
   * @description focus a component. It doesn't check if the component accepts focus or not
   * @param id component to focus
   */
  async setFocusOnId(
    id?: string,
    options?: {
      focus?: boolean;
      animate?: boolean;
      scroll?: boolean;
    }
  ): Promise<void> {
    Log.ui_list.log(
      `${this.params.rootElement.id} AM FOCUSED : ${this.focused$.value} setFocusOnId on ${id}, focus: ${
        options?.focus ?? true
      }`
    );
    let index = this.indexFromId(id);

    if (index !== undefined) {
      await this.setFocusOnIndex(index, options);
    } else {
      while (index === undefined && !this._modelSource?.isComplete()) {
        await this.maybeFetch(
          this.ids.length + 2 * this.params.pageSize + this.params.visibleAfter,
          options?.animate ?? true
        );
        index = this.indexFromId(id);
      }
      index != undefined && (await this.setFocusOnIndex(index, options));
    }
  }

  /**
   * @description focus next element across the list. If list is only 1 element wide, then it's always blocked (swimlane case)
   * @returns true if nav was blocked (focus didn't move) or false
   */
  focusAcross = (offset: 1 | -1): boolean => {
    let targetIndex = this.focusedIndex$.value || 0;
    const min =
      this.params.crossSectionWidth * Math.floor((this.focusedIndex$.value || 0) / this.params.crossSectionWidth);
    const max = Math.max(0, Math.min(min + this.params.crossSectionWidth, this.ids.length) - 1);
    do {
      targetIndex = targetIndex + offset;
      if (Math.min(Math.max(targetIndex, min), max) != targetIndex) {
        Log.ui_list.info(
          `${this.params.rootElement.id} tried to focus (across) an index (${targetIndex}) out of bound [${min} to ${max}]`
        );
        // blocked
        return true;
      }
    } while (this.viewFromIndex(targetIndex)?.rejectsFocus?.() ?? false);
    // this.setFocusedIndex(targetIndex, true);
    this.setFocusOnIndex(targetIndex);
    return false;
  };

  /**
   * @description focus next element along the list
   * @returns true if nav was blocked (focus didn't move) or false
   */
  focusAlong = (offset: 1 | -1): boolean => {
    // stepping along in a multiple column / width setup is a bit complicated.
    // First, figure out the current "line/column" the focus is in
    const previousFocusedIndex = this.focusedIndex$.value || 0;
    const previousFocusLine = Math.floor(previousFocusedIndex / this.params.crossSectionWidth);
    // which 'column' in the line was the focus in
    const previousFocusColumn = previousFocusedIndex - previousFocusLine * this.params.crossSectionWidth;
    const maxFocusLine = Math.floor(
      Math.max(this.ids.length + (this._modelSource?.isComplete() ? -1 : 0), 0) / this.params.crossSectionWidth
    );
    // then check if we can move a line
    let targetLine = previousFocusLine;
    do {
      targetLine = targetLine + offset;
      if (Math.min(Math.max(targetLine, 0), maxFocusLine) != targetLine) {
        Log.ui_list.info(
          `${this.params.rootElement.id} tried to focus (along) an index (${targetLine}) out of bound [0 to ${maxFocusLine}]`
        );
        // blocked
        return true;
      }
    } while (
      this.viewFromIndex(
        Math.min(Math.max(targetLine * this.params.crossSectionWidth + previousFocusColumn, 0), this.ids.length - 1)
      )?.rejectsFocus?.() ??
      false
    );
    // this.setFocusedIndex(
    //   Math.min(Math.max(targetLine * this.params.crossSectionWidth + previousFocusColumn, 0), this.ids.length - 1)
    // );
    this.setFocusOnIndex(
      Math.min(Math.max(targetLine * this.params.crossSectionWidth + previousFocusColumn, 0), this.ids.length - 1)
    );
    return false;
  };

  // consider all visible elements
  viewIndexNearPoint(point: Point): number | undefined {
    let nearestIndex = undefined;

    let closestDistance = Infinity;
    this.onEveryVisible(index => {
      const view = this.viewFromIndex(index);
      if (view) {
        const element = view.rootElement;
        const screenRect = screenRectOf(element);
        Log.ui_list.trace(`viewIndexNearPoint considering ${element?.id} - ${JSON.stringify(screenRect)}`);
        // if point is included,
        const distance = distanceBetweenPointAndRectSQR(point, screenRect);
        if (distance < closestDistance) {
          // figure out if any of the parents are rejecting focus (it's not a drill down op, it's a bubble up)
          let rejectsFocus = false;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let walkingElement: HTMLElement | null = view?.rootElement ?? null;
          while (walkingElement && !rejectsFocus) {
            rejectsFocus = rejectsFocus || (walkingElement._dsView?.rejectsFocus?.() ?? false);
            walkingElement = walkingElement.parentElement;
          }
          if (!rejectsFocus) {
            closestDistance = distance;
            nearestIndex = index;
          }
        }
      }
    });
    return nearestIndex;
  }

  focusNearPoint(point: Point, scroll = true): void {
    Log.ui_list.trace(`${this.rootElement.id} focusNearPoint ${JSON.stringify(point)}`);
    const targetFocusIndex = this.viewIndexNearPoint(point);
    // if (targetFocusIndex != undefined) {
    // this.setFocusedIndex(targetFocusIndex, true);
    this.setFocusOnIndex(targetFocusIndex, { scroll, fromMouse: true });
    // } else {
    //   // initial focus, try to find one
    //   const firstFocusableId = this.params.initialFocusId || this.getFirstFocusableId(1);
    //   firstFocusableId !== undefined && (this.focusedId = firstFocusableId); // this.setFocusedIndex(firstFocusableIndex, true);
    // }
  }

  focusFromSubView(viewRootElement: HTMLElement): void {
    if (this.ids.length == 0) return;
    this.onEveryVisible(index => {
      if (this.viewFromIndex(index)?.rootElement.contains(viewRootElement)) {
        this.focusedIndex$.value = index;
        this.focusedFromMouse$.value = true;
        return true; // we break
      }
    });
  }

  isRtl(): boolean {
    return (this.params.dir ?? DIR$.value) == DIR.rtl;
  }

  /*
  IFocusable implementation
   */
  public onNav(key: Keys): boolean {
    // pass to focused first if any
    Log.ui_list.log(`onNav ${key} in list ${this.params.rootElement.id} - focus is ${this.focusedIndex$.value}`);
    const view = this.viewFromIndex(this.focusedIndex$.value);

    const handled = callWithDelegateFallback(view, "onNav", key) || false;

    if (!handled) {
      Log.ui_list.log(`onNav ${this.params.rootElement.id} will handle event`);
      switch (key) {
        case Keys.up:
          return this.params.horizontal ? !this.focusAcross(-1) : !this.focusAlong(-1);
        case Keys.down:
          return this.params.horizontal ? !this.focusAcross(1) : !this.focusAlong(1);
        case Keys.left:
          return this.params.horizontal
            ? !this.focusAlong(this.isRtl() ? 1 : -1)
            : !this.focusAcross(this.isRtl() ? 1 : -1);
        case Keys.right:
          return this.params.horizontal
            ? !this.focusAlong(this.isRtl() ? -1 : 1)
            : !this.focusAcross(this.isRtl() ? -1 : 1);

        case Keys.select: {
          const model = this.modelFromIndex(this.focusedIndex$.value);
          return (
            (model !== undefined &&
              this.focusedIndex$.value !== undefined &&
              this.params.onSelect?.(model, this.focusedIndex$.value)) ||
            false
          );
        }

        default:
          return false;
      }
    } else return true;
  }

  public rejectsFocus(): boolean {
    return this.viewFromIndex(this.focusedIndex$.value)?.rejectsFocus?.() ?? false;
  }

  public onUnfocused(): void {
    Log.ui_list.trace(`${this.params.rootElement.id} onUnfocused hasFocus:${this.focused$.value}`);
    this.focused$.value = false;
    const view = this.viewFromIndex(this.focusedIndex$.value);
    view?.rootElement && DOMHelper.removeClass(view?.rootElement, "focused");
    callWithDelegateFallback(view, "onUnfocused");
  }

  public onFocused(scroll = true): void {
    if (!this.focused$.value) {
      if (this.focusedIndex$.value != undefined) {
        if (this.params.spatialFocus) {
          Log.ui_list.trace(`${this.params.rootElement.id} onFocused spatial`);

          const focusedElement = _navigationStack?.focusedLeafView?.rootElement;
          const focusedViewRect = screenRectOf(focusedElement);
          const focusedViewCenter = centerOf(focusedViewRect);
          Log.ui_list.trace(`spatial focus from ${focusedElement?.id} - ${JSON.stringify(focusedViewCenter)}`);
          focusedViewCenter && this.focusNearPoint(focusedViewCenter, scroll);
        } else {
          Log.ui_list.trace(`${this.params.rootElement.id} onFocused focusedIndex:${this.focusedIndex$.value}`);

          const view = this.viewFromIndex(this.focusedIndex$.value);
          view?.rootElement && DOMHelper.addClass(view?.rootElement, "focused");
          callWithDelegateFallback(view, "onFocused", scroll);
        }
      }
      this.focused$.value = true;
    } else {
      Log.ui_list.trace(`${this.params.rootElement.id} onFocused already has focus`);
    }
  }

  public onShown(): void {
    Log.ui_list.trace(`${this.rootElement.id} onShown`);
    this.shown$.value = true;
    this.offsetMap = {};
    this.updateListLayout();
    // if (this.focusedId$.value !== undefined) this.setFocusOnId(this.focusedId$.value, this.focused$.value, false);
    // go over visible elements to notify them
    this.onEveryVisible(index => callWithDelegateFallback(this.viewFromIndex(index), "onShown"));
  }

  public onHidden(): void {
    Log.ui_list.trace(`${this.rootElement.id} onHidden`);
    this.shown$.value = false;
    // go over visible elements to notify them
    this.onEveryVisible(index => callWithDelegateFallback(this.viewFromIndex(index), "onHidden"));
  }

  /** helper iterator over all visible tiles
   * @param callback the function to call. If it returns true, the loop breaks
   */
  onEveryVisible = (callback: (index: number) => boolean | void) => {
    for (
      let index = Math.max(
        0,
        ((this.scrollIndex$.value ?? 0) - this.params.visibleBefore) * this.params.crossSectionWidth
      );
      index <
      Math.min(
        this.ids.length,
        ((this.scrollIndex$.value ?? 0) + this.params.pageSize + this.params.visibleAfter) *
          this.params.crossSectionWidth
      );
      index++
    ) {
      if (callback(index)) break;
    }
  };
}

const updateScrollPosition = (
  element: HTMLElement,
  origin: Point,
  scrollDuration: number,
  scrollOpId?: number
): Promise<number> =>
  new Promise(resolve => {
    if (scrollDuration) {
      element.style.transition == "" && (element.style.transition = `transform ${scrollDuration / 1000}s ease`);
    } else {
      element.style.transition = "";
    }
    setOrigin(element, origin, DIR$.value);
    element.style.position != "" && (element.style.position = "");
    if (scrollDuration) {
      // const listener = () => {
      //   scrollOpId && resolve(scrollOpId);
      //   element.removeEventListener("transitionend", listener);
      // };
      // element.addEventListener("transitionend", listener);
      (async () => {
        // we know our animation takes xxx ms to run - add a bit more because CSS tends to lag behind
        await delay(scrollDuration * 1.2);
        scrollOpId && resolve(scrollOpId);
      })();
    } else {
      scrollOpId && resolve(scrollOpId);
    }
  });
