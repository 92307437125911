/**
 * Navigation related structures.
 *
 * A NavigationStack is created by calling {@link createNavigationStack} which returns an interface {@link INavigationStack}
 *
 * The concept of a stack is to push pages on top of a stack.
 *
 * Any page pushed on top of the stack will hide the page previously showing if any - except if the Page has declared that it's a popup via
 * the override of the isPopup(): true function
 *
 * By default, a page will be removed from the stack when the {@link Keys.back} key is pressed - and it's not the last page on the stack (we don't want to
 * end up without a page at all)
 * if the page shouldn't be popped on back, it can implement the {@link IPage.popOnBack} function to make it return false
 *
 * @module Navigation
 */
import { clean, IPage, isHtmlElement, isListComponent, IView, throttle } from "..";
import { callWithDelegateFallback } from "../helpers/delegate";
import { Log } from "../log";
import { Keys } from "../types";
import { IListenable, Listenable } from "./../helpers/Listenable";
require("./mouseListComponent");
import { isSwitchComponent } from "./switchComponent";

export enum InputMode {
  keys = "keys",
  pointer = "pointer",
}

/**
 * The interface of a navigation stack to be used by the app
 */
export interface INavigationStack {
  readonly mouseSupport: boolean;
  /** The bottom-most view in the tree that has the focus
   */
  readonly focusedLeafView: IView | undefined;
  /** the page at the top of the stack
   */
  readonly inputMode$: IListenable<InputMode>;
  readonly topPage: IPage | undefined;
  readonly pages$: IListenable<IPage[]>;
  pushPage(page: IPage): void;
  removePage(page?: IPage): void;
  destroyStack(): void;
  keyHandler(key: string): void;
}

export type NavigationStackParams = {
  /** the DOM element this navigation stack will use as its root.
   * All pages pushed to the stack will be a child of this root Element
   */
  rootElement: HTMLElement;
  /** should the mouse be supported?
   * @default false
   */
  mouseSupport?: boolean;
  /** min internal between mouse wheel events
   * @default 0 (no throttle)
   */
  wheelThrottle?: number;
  /** this is called when the {@link Keys.back} is pressed, and we're on the "top page"
   * on LG or Samsungs, this is supposed to show a popup that asks a user if he wants to quit the app.
   * It's up to the app to implement this popup & the logic to quit the app depending on the platforms
   */
  onExit?: () => void;
};

/** @internal */
export let _navigationStack: INavigationStack | undefined;
let lastMouseTarget: EventTarget | null = null;

/**
 * Creates a navigation stack that will be "rooted" to the provided rootElement
 */
export function createNavigationStack(params: NavigationStackParams): INavigationStack {
  if (_navigationStack) {
    throw "can't create multiple navigation stacks!";
  }
  return (_navigationStack = new NavigationStack(params));
}
class NavigationStack implements INavigationStack {
  rootElement: HTMLElement;
  inputMode$ = new Listenable(InputMode.keys);
  pages$ = new Listenable<IPage[]>([]);
  onExit?: () => void;
  mouseSupport: boolean;

  constructor(params: NavigationStackParams) {
    this.rootElement = params.rootElement;
    this.mouseSupport = params.mouseSupport ?? false;
    this.onExit = params.onExit;

    if (params.mouseSupport) {
      document.onmousemove = this.mousemoveHandler;
      document.onmousedown = this.mousedownHandler;
      // document.onmousedown = this.mousemoveHandler;
      document.onwheel = params.wheelThrottle
        ? throttle(this.wheelHandler, params.wheelThrottle).func
        : this.wheelHandler;
    }
  }

  get topPage(): IPage | undefined {
    // will return undefined if length = 0
    return this.pages$.value[this.pages$.value.length - 1];
  }

  // add one on the stack. This is only happening when actually stacking screens (popup)
  // focus handling isn't handled here - assume page does it in its creator
  pushPage = (page: IPage): void => {
    // if a page is already in our stack, don't do a thing
    if (this.pages$.value.includes(page)) return;
    // remove old UI
    if (!(page.isPopup?.() ?? false)) {
      // clean up all previous popups - until we land on a non-popup page
      let index = this.pages$.value.length - 1;
      while (index >= 0) {
        clean(this.pages$.value[index]?.rootElement);
        callWithDelegateFallback(this.pages$.value[index], "onHidden");
        if (!this.pages$.value[index]?.isPopup?.()) break;
        index--;
      }
    }
    // show new UI
    this.rootElement.appendChild(page.rootElement);
    callWithDelegateFallback(page, "onShown");

    // push it on the stack
    this.pages$.value = [...this.pages$.value, page];
    Log.ui_nav.log(
      "nav stack after push",
      this.pages$.value.map(page => page.rootElement.id)
    );
  };

  // called by something which knows the page, take it out of the stack, but leave the responsability
  // of destroying it to the caller
  private _removePage = (page?: IPage, destroyingStack: boolean = false): void => {
    if (!page) return;
    // if a page isn't in our stack, don't do a thing
    if (!this.pages$.value.includes(page)) return;
    // remove old UI
    clean(page.rootElement);
    callWithDelegateFallback(page, "onHidden");
    this.pages$.value = this.pages$.value.filter(stackPage => page !== stackPage);
    // show new UI
    if (this.topPage && !destroyingStack && !(page.isPopup?.() ?? false)) {
      // append back up all previous pages - until we land on a non-popup page
      let index = this.pages$.value.length - 1;
      while (index >= 0) {
        // don't appendChild here - we are going to top to bottom in our page stack, therefore the first to be appended is the topmost one
        // and next ones need to be inserted before the top one
        this.rootElement.insertBefore(
          this.pages$.value[index].rootElement,
          this.pages$.value[index + 1]?.rootElement ?? null
        );
        callWithDelegateFallback(this.pages$.value[index], "onShown");
        if (!this.pages$.value[index]?.isPopup?.()) break;
        index--;
      }
    }
    callWithDelegateFallback(page, "onRelease");
    Log.ui_nav.log(
      "nav stack after removePage",
      this.pages$.value.map(page => page.rootElement.id)
    );
  };

  removePage = (page?: IPage) => {
    this._removePage(page);
  }

  destroyStack = (): void => {
    while (this.pages$.value.length > 0) {
      this._removePage(this.topPage, true);
    }
  };

  focusedChildOf(view: IView | undefined): IView | undefined {
    if (!view) return undefined;

    if (isListComponent(view)) {
      return this.focusedChildOf(view.focusedView$.value) || view;
    } else if (isListComponent(view.delegate)) {
      return this.focusedChildOf(view.delegate.focusedView$.value) || view.delegate;
    } else if (isSwitchComponent(view)) {
      return this.focusedChildOf(view.shownView$.value) || view;
    } else if (isSwitchComponent(view.delegate)) {
      return this.focusedChildOf(view.delegate.shownView$.value) || view.delegate;
    } else return view;
  }

  get focusedLeafView(): IView | undefined {
    // walk down the pages following focus path. I wonder how costly that is
    return this.focusedChildOf(this.topPage);
  }

  // throttledKeyHandler = throttle(callWithDelegateFallback, 150);
  lastKey: string | undefined;
  keyHandler = (key: string): void => {
    this.inputMode$.value = InputMode.keys;
    const page = this.topPage;

    // if (key != this.lastKey) {
    //   this.lastKey = key;
    //   this.throttledKeyHandler.abort();
    if (!callWithDelegateFallback(page, "onNav", key) && key == Keys.back) {
      if (this.pages$.value.length > 1) {
        this.removePage(page);
      } else {
        this.onExit?.();
      }
    }
    // } else {
    // this.throttledKeyHandler.func(page, "onNav", key);
    // }
  };

  getFirstValidMouseTarget(target: HTMLElement): HTMLElement | null {
    // if (ev.target.className == "text") debugger;
    // first walk up the tree to find if we reject or not
    let rejectsFocus = false;
    let walkElement: HTMLElement | null = target;
    while (!rejectsFocus && walkElement && walkElement != document.body) {
      if (walkElement.parentElement?._dsListScrollElement) {
        // item of a list - we need to make sure it's an actual list element - in the scrollElement
        rejectsFocus =
          rejectsFocus ||
          (walkElement.parentElement.parentElement?._dsView?.rejectsFocus?.() ?? false) ||
          (walkElement.parentElement.parentElement?._dsView?.rejectsMouseFocus?.(walkElement) ?? false);
        walkElement = walkElement.parentElement.parentElement ?? null;
      } else {
        // anywhere else
        rejectsFocus =
          rejectsFocus ||
          (walkElement.parentElement?._dsView?.rejectsFocus?.() ?? false) ||
          (walkElement.parentElement?._dsView?.rejectsMouseFocus?.(walkElement) ?? false);
        walkElement = walkElement.parentElement ?? null;
      }
    }

    return rejectsFocus ? walkElement?.parentElement ?? null : target;
  }

  throttledMousemoveHandler = throttle((ev: MouseEvent) => {
    if (isHtmlElement(ev.target)) {
      // if (ev.target.className == "text") debugger;
      // first walk up the tree to find if we reject or not
      let walkElement: HTMLElement | null = this.getFirstValidMouseTarget(ev.target);
      // and walk up the rest of tree to figure who will handle it
      while (walkElement && walkElement != document.body) {
        if (walkElement._dsView?.onMouseMove?.({ x: ev.clientX, y: ev.clientY }, ev.target) ?? false) {
          break;
        } else {
          walkElement = walkElement?.parentElement || null;
        }
      }
    }
  }, 250);

  mousemoveHandler = (ev: MouseEvent): void => {
    this.inputMode$.value = InputMode.pointer;

    // if target hasn't change, just make sure we're not changing target without knowing
    if (ev.target != lastMouseTarget) this.throttledMousemoveHandler.abort();
    this.throttledMousemoveHandler.func(ev);
    lastMouseTarget = ev.target;
  };

  mousedownHandler = (ev: MouseEvent): void => {
    this.inputMode$.value = InputMode.pointer;

    if (isHtmlElement(ev.target) && ev.button == 0) {
      // and walk up the tree to figure how'll handle it
      let walkElement: HTMLElement | null = this.getFirstValidMouseTarget(ev.target);
      while (walkElement && walkElement != document.body) {
        if (walkElement._dsView?.onMouseDown?.({ x: ev.clientX, y: ev.clientY }, ev.target) ?? false) {
          break;
        } else {
          walkElement = walkElement?.parentElement || null;
        }
      }
    }
  };

  wheelHandler = (ev: WheelEvent): void => {
    Log.ui_nav.log("wheelHandler", this.inputMode$.value);
    // first wheel is just to turn back to pointer mode, not to wheel down
    this.inputMode$.value = InputMode.pointer;
    if (isHtmlElement(ev.target)) {
      // and walk up the tree to figure how'll handle it
      let walkElement: HTMLElement | null = ev.target;
      while (walkElement && walkElement != document.body) {
        if (walkElement._dsView?.onMouseWheel?.(ev.deltaY, { x: ev.clientX, y: ev.clientY }, ev.target) ?? false) {
          break;
        } else {
          walkElement = walkElement.parentElement || null;
        }
      }
    }
  };
}
