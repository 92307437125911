import "./warningPopup.scss";

import { DOMHelper, IPage, View } from "~ui-lib";

export class WarningPopup extends View implements IPage {
  constructor(message: string, imageUrl?: string) {
    super(DOMHelper.createDivWithParent(null, "WarningPopup"));
    const containerElement = DOMHelper.createDivWithParent(this.rootElement, "WarningPopupContainer");
    const img = DOMHelper.createDivWithParent(containerElement, "WarningPopupImg", null);
    img.style.backgroundImage = imageUrl
      ? `url("${imageUrl}")`
      : `url("${require("/static/images/buttonProgram/warning.png")}")`;
    DOMHelper.createDivWithParent(containerElement, "WarningPopupTitle", null, message);
  }
  isPopup = () => true;
}
