import "./playerPage.scss";

import { Subscription } from "rxjs";

import { Player, PlayerState } from "~libs/player";
import { PlayerHTML5 } from "~libs/players/playerHTML5";
import { PlayerTizen } from "~libs/players/playerTizen";
import { PlayerControlList } from "~pages/player/playerControls";
import { PlayableItem, pushPlayerPage } from "~pages/rootPage";
import { EStat } from "~tools/analytics/eStat";
import {
  createListComponent,
  DOMHelper,
  IListComponent,
  IPage,
  Keys,
  Listenable,
  platform,
  PlatformType,
  StaticModelSource,
  View,
} from "~ui-lib";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { VideoPlayerSwimlane } from "../../swimlaneViews/videoPlayerSwimlane";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { networkManager } from "../../tools/networkManager";
import { LoaderView } from "../loader/loaderPage";

enum PlayerComponentType {
  controls = "controls",
  nextContent = "nextContent",
}

// console.log(
//   "[eStat midroll]",
//   "\nthis.eStatData",
//   this.eStatData,
//   "\nthis.player.state$",
//   this.player.state$,
//   "\nthis.player.currentTime$.value",
//   this.player.currentTime$.value,
//   "\nthis.item.id",
//   this.item.id,
//   "\nthis.player.adPlaying$",
//   this.player.adPlaying$
// );
export let currentPlayer: Player | undefined = undefined;

export class PlayerPage extends View implements IPage {
  item: PlayableItem;
  list: IListComponent | undefined;
  player: Player;
  playerStateUnregister: () => void;
  isLaunchedChange: () => void;
  uiVisibilityTimer?: number;
  uiVisible$ = new Listenable<boolean>(false);
  isLive$ = new Listenable<boolean>(false);
  eStat: EStat;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eStatData: any;
  loader: LoaderView;
  private _subPlayer?: Subscription = undefined;
  private _subHasNext?: Subscription = undefined;
  private _resumeWhenOnline = false;
  nextContent?: PlayableItem;
  private _isOnlineUnregister: () => void;

  constructor(item: PlayableItem) {
    super(DOMHelper.createDivWithParent(null, "playerPage", "playerPage"));
    this.item = item;
    this.eStat = new EStat();

    // Show loader and start anim
    this.loader = new LoaderView();
    this.rootElement.appendChild(this.loader.rootElement);
    this.loader.start();

    // Active video defined in application background
    document.getElementById("videoBackground")?.classList.add("active");

    const broadcastInfo =
      item.media && item.media.broadcast && item.media.broadcast.start_date && item.media.broadcast.end_date
        ? item.media.broadcast
        : null;
    if (broadcastInfo) {
      const startDate = broadcastInfo.start_date.getTime(),
        endDate = broadcastInfo.end_date.getTime(),
        currentDate = Date.now();

      if (startDate <= currentDate && endDate >= currentDate) {
        this.isLive$.value = true;
      }
    }

    this.player = platform.type == PlatformType.tizen ? new PlayerTizen() : new PlayerHTML5();
    currentPlayer = this.player;
    this.playerStateUnregister = this.player.state$.didChange(state => {
      switch (state) {
        case PlayerState.BUFFERING:
          break;
        case PlayerState.ENDED:
          if (!this.player.isAdVideoPlaying()) {
            if (this.nextContent) {
              pushPlayerPage(this.nextContent);
              this.exitPlayer();
              // must display element videoBackground deactivate when player is closed
              const DOMVideo = document.getElementById("videoBackground");
              if (DOMVideo) {
                DOMVideo.classList.add("active");
                DOMVideo.hidden = false;
              }
            } else {
              this.exitPlayer();
            }
          }
          break;
        case PlayerState.ERROR:
          break;
        case PlayerState.IDLE:
          break;
        case PlayerState.PAUSED:
          window.clearTimeout(this.uiVisibilityTimer);
          break;
        case PlayerState.PLAYING:
          this.showPlayerUI();
          if (!this.player.isAdVideoPlaying() && this.eStat.isPauseMidroll) {
            this.player.sendEStat(PlayerState.PLAYING);
          }
          break;
        case PlayerState.PREPARING:
          break;
        case PlayerState.UNSET:
          break;
        case PlayerState.SEEKING:
          window.clearTimeout(this.uiVisibilityTimer);
          break;
        default:
          console.warn("Unknown player state :: ", state);
          break;
      }
    });

    this._isOnlineUnregister = networkManager.isOnline$.didChange((isOnline: boolean) => {
      if (isOnline === false) {
        if (this.player.state$.value === PlayerState.PLAYING) {
          this._resumeWhenOnline = true;
        }
        this.player.pause();
      } else if (this._resumeWhenOnline === true) {
        this._resumeWhenOnline = false;
        this.player.play();
      }
    });

    this.isLaunchedChange = this.player.videoLaunched$.didChange(isLaunched => {
      if (isLaunched) {
        this.eStat.auth(this.eStatData.serial, () =>
          this.eStat.sendState(
            this.eStatData,
            PlayerState.PLAYING,
            this.player.currentTime$.value,
            this.item.id,
            this.player.adPlaying$
          )
        );
        this.eStat.createPolling(
          this.eStatData,
          this.player.currentTime$,
          this.item.id,
          this.player.state$,
          this.player.adPlaying$
        );
        this.player.sendEStat = (playerState: PlayerState) => {
          this.eStat.sendState(
            this.eStatData,
            playerState,
            this.player.seekTime$.value !== undefined ? this.player.seekTime$.value : this.player.currentTime$.value,
            this.item.id,
            this.player.adPlaying$
          );
        };
      }
    });

    try {
      this.player.init();
    } catch (error) {
      Log.player.error(`init failed: ${error}`);
    }
  }

  init = (): void => {
    this.playContent();
  };
  hasContent = (current: any): void => {
    this._subHasNext = Plugin.getInstance()
      .fetchNextEpisodes(this.item)
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value: any) => {
          // Here use it to create the UI
          console.log("[hasContent] fetch from !", this.item);
          console.log("[hasContent] Next !", value);
          let hasContent = false;
          if (value[0] && value[0].items && value[0].items.length) {
            hasContent = true;
            this.nextContent = value[0].items[0];
          }
          const uiContainer = DOMHelper.createDivWithParent(
            this.rootElement,
            "uiContainer",
            hasContent ? "uiContainer hasContent" : "uiContainer"
          );
          this.delegate = this.list = createListComponent(
            {
              rootElement: uiContainer,
              modelSource: new StaticModelSource(
                hasContent ? [PlayerComponentType.controls, ...value] : [PlayerComponentType.controls]
              ),
              viewFactory: model => {
                switch (model) {
                  case PlayerComponentType.controls:
                    return new PlayerControlList(
                      {
                        isLive$: this.isLive$,
                        uiVisible$: this.uiVisible$,
                        player: this.player,
                        exitPlayer: this.exitPlayer,
                      },
                      this.item
                    );
                }
                return new VideoPlayerSwimlane(model, current.meta.id, this, this.item.metadata.extras.is_live);
              },
              pageSize: 1,
              visibleAfter: 1,
              mouseFocusInPageOnly: true,
              horizontal: false,
            },
            () => {
              Log.player.info("Ending loader animation");
              this.loader.end(true, () => {
                // Uncomment to test on browser:
                //value.video.url = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

                this.rootElement.removeChild(this.loader.rootElement);
                this.player.setSrc(current.video.url);
                //console.error("estat === " + value);
                this.eStatData = current.markers.estat;
              });
            }
          );
        },
        error => {
          // Here use it to trigger and display an error
          Log.player.error("[hasContent] Error !", error);

          this.delegate = this.list = createListComponent(
            {
              rootElement: DOMHelper.createDivWithParent(this.rootElement, "uiContainer", "uiContainer"),
              modelSource: new StaticModelSource([PlayerComponentType.controls]),
              viewFactory: model => {
                return new PlayerControlList(
                  {
                    isLive$: this.isLive$,
                    uiVisible$: this.uiVisible$,
                    player: this.player,
                    exitPlayer: this.exitPlayer,
                  },
                  this.item
                );
              },
              pageSize: 1,
              visibleAfter: 1,
              mouseFocusInPageOnly: true,
              horizontal: false,
            },
            () => {
              Log.player.info("Ending loader animation");
              this.loader.end(true, () => {
                // Uncomment to test on browser:
                //value.video.url = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

                this.rootElement.removeChild(this.loader.rootElement);
                this.player.setSrc(current.video.url);
                this.eStatData = current.markers.estat;
              });
            }
          );
        },
        () => {
          Log.player.log("[hasContent] Complete !");
        }
      );
  };
  playContent = (): void => {
    // Example to fetch replay url
    this._subPlayer = Plugin.getInstance()
      .fetchPlayer(this.item)
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value: any) => {
          Log.player.log("[PLAYER] Next !", value);
          this.hasContent(value);
        },
        error => {
          // Here use it to trigger and display an error
          Log.player.error("[PLAYER] Error !", error);
        },
        () => {
          Log.player.log("[PLAYER] Complete !");
        }
      );
  };

  private _pianoAnalyticsPageDisplaySent = false;

  onShown() {
    if (this._pianoAnalyticsPageDisplaySent === false) {
      // the onShown can triggered twice when clicking on a video the videoPlayerSwimlane
      this._pianoAnalyticsPageDisplaySent = true;
      this._sendPianoAnalyticsPageDisplay();
    }
  }

  // mechanizme to fetch markerPiano only one time
  private _fetchMarkerPianoPromise?: Promise<NonNullable<ReturnType<typeof parseMarkerPianoPageDisplay>>>;
  private _fetchMarkerPiano = () => {
    if (this._fetchMarkerPianoPromise === undefined) {
      return (this._fetchMarkerPianoPromise = new Promise((resolve, reject) => {
        const markerPiano = parseMarkerPianoPageDisplay(this.item.extras);
        if (markerPiano === undefined) {
          Plugin.getInstance()
            .fetchDetailled(this.item)
            .subscribe(value => {
              const newMarkerPiano = parseMarkerPianoPageDisplay(value?.[0]?.extras);
              if (newMarkerPiano === undefined) {
                Log.analytics.error("Failed to send piano analytics");
              } else {
                resolve(newMarkerPiano);
              }
            }, reject);
        } else {
          resolve(markerPiano);
        }
      }));
    } else {
      return this._fetchMarkerPianoPromise;
    }
  };

  private _sendPianoAnalyticsPageDisplay = () => {
    this._fetchMarkerPiano()
      .then(markerPiano => {
        sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
      })
      .catch((e: unknown) => {
        Log.analytics.error("Failed to send piano analytics", e);
      });
  };

  onRelease = () => {
    this._isOnlineUnregister();
    this.playerStateUnregister();
    this.player.release();
    this.eStat.clearPolling();
    window.clearTimeout(this.uiVisibilityTimer);
    document.getElementById("videoBackground")?.classList.remove("active");
    this.eStat.sendStop(this.eStatData, this.player.currentTime$.value, this.item.id);
    this._subPlayer?.unsubscribe();
    this._subHasNext?.unsubscribe();
    this.loader.onRelease();
    currentPlayer = undefined;
  };

  exitPlayer = () => {
    navigationStack.removePage(this);
  };

  showPlayerUI() {
    if (!this.uiVisible$.value && !this.player.adPlaying$.value) {
      this.uiVisible$.value = true;
      this.delegate?.onShown?.();
      //document.getElementById("playerInfoBanner")?.classList.remove("hidden");
    }
    window.clearTimeout(this.uiVisibilityTimer);
    this.uiVisibilityTimer = window.setTimeout(() => {
      this.hidePlayerUI();
    }, 5000);
  }

  hidePlayerUI() {
    this.uiVisible$.value = false;
    //document.getElementById("playerInfoBanner")?.classList.add("hidden");
  }

  onNav = (key: Keys): boolean => {
    if (key === Keys.stop || key === Keys.back) {
      this.exitPlayer();
      return true;
    }
    if (this.player.state$.value != PlayerState.SEEKING) {
      if (this.uiVisible$.value == false) {
        this.showPlayerUI();
        return true;
      } else this.showPlayerUI();
    }
    return false;
  };
}
