/* eslint-disable @typescript-eslint/no-explicit-any */

import { AdController } from "~libs/dotscreen-adc";
import { Listenable } from "~ui-lib";

export enum PlayerState {
  IDLE = 0,
  PREPARING = 1,
  BUFFERING = 2,
  PLAYING = 3,
  PAUSED = 4,
  ENDED = 5,
  ERROR = 6,
  UNSET = 7,
  SEEKING = 8,
}
export interface PlayerError {
  code: string;
}

export interface Track {
  language: string;
  index: number;
}

export class Player {
  DOMElement: HTMLElement;
  videoElement?: any;
  drmElement?: any;
  sourceElement?: HTMLElement;

  controller?: any;
  adDelegate?: any;
  adListener?: any;

  url$ = new Listenable<string>("");

  // interface members
  currentTime$ = new Listenable(0);
  duration$ = new Listenable(0);
  isPlaying$ = new Listenable<boolean>(false);
  adPlaying$ = new Listenable<boolean>(true);
  videoLaunched$ = new Listenable<boolean>(false);
  isSeeking$ = new Listenable<boolean>(false);
  state$ = new Listenable<PlayerState>(PlayerState.UNSET);
  seekTime$ = new Listenable<number | undefined>(undefined);
  seekValue$ = new Listenable<number>(10);
  error$ = new Listenable<PlayerError | undefined>(undefined);

  constructor() {
    this.DOMElement = document.getElementById("videoBackground") as HTMLElement;
    this.DOMElement.hidden = false;
  }

  /**
   * Initialization players, depends on device type
   */
  init(): void {
    this.initListener();
    this.initAnalytics();
  }

  initAnalytics(): void {}

  /**
   * Init listeners
   */

  initListener(): void {}

  /**
   * set source url and play
   */
  setSrc(url: string, playready?: boolean): void {
    this.url$.value = url;
    this.adPlaying$.value = this.isAdVideoPlaying();

    this.load(url, playready);
  }

  load(_url: string, _playready?: boolean): void {}

  setSrcAd = (url: string, playready?: boolean) => {
    this.url$.value = url;
    this.adPlaying$.value = this.isAdVideoPlaying();

    this.load(url, playready);
  };

  launchPlay(): void {
    if (this.shouldPlayVideo()) {
      this.play();
    }
  }

  setSeekTime = (resumePosition: number) => {
    this.seekTime$.value = resumePosition;
  };

  sendEStat(playerState: PlayerState): void {}

  /**
   * Play
   */
  play(): void {}

  /**
   * Pause
   */
  pause(): void {}

  /**
   * Stop
   */
  stop(): void {}

  /**
   * Toggle play/Pause
   */
  playPause = () => {
    switch (this.state$.value) {
      case PlayerState.BUFFERING:
      case PlayerState.PLAYING:
        this.pause();
        break;

      case PlayerState.PAUSED:
        this.play();
        break;

      case PlayerState.PREPARING:
        this.play();
        break;
    }
  };

  /**
   * Seek
   */
  seek(): void {}

  /**
   * RW and FF
   */
  rewind = (): void => {
    if (this.state$.value != PlayerState.SEEKING) {
      this.pause();
    }

    const target = this.position() - this.seekValue$.value;
    this.currentTime$.value = ~~Math.max(0, target);
  };

  forward = (): void => {
    if (this.state$.value != PlayerState.SEEKING) {
      this.pause();
    }

    const target = this.position() + this.seekValue$.value;
    this.currentTime$.value = ~~Math.min(target, this.duration());
  };

  /**
   * Duration and position
   */
  duration = (): number => {
    return this.duration$.value;
  };

  position = (): number => {
    return this.currentTime$.value;
  };

  /**
   * Generic Event listeners to update internal player statut
   */

  onEventPlay = (): void => {
    Log.player.log(" onEventPlay");
    this.state$.value = PlayerState.PLAYING;
    this.isPlaying$.value = true;

    // First launched detecttion
    if (!this.adPlaying$.value && !this.videoLaunched$.value) {
      this.videoLaunched$.value = true;
    }

    // Resume position after launched play
    if (this.seekTime$.value !== undefined) {
      Log.player.log("[Player][AdController] onEventPlay setSeekTime :: " + this.seekTime$.value);
      this.pause();
      this.currentTime$.value = this.seekTime$.value;
      this.seek();
      this.seekTime$.value = undefined;
    }
  };
  onEventPause = (): void => {
    Log.player.log(" onEventPause");
    this.state$.value = PlayerState.PAUSED;
    this.isPlaying$.value = false;
  };
  onEventStop = (): void => {
    Log.player.log(" onEventStop");
    this.state$.value = PlayerState.ENDED;
    this.isPlaying$.value = false;
  };
  onEventConnect = (): void => {
    Log.player.log(" onEventConnect");
    this.state$.value = PlayerState.PREPARING;
  };
  onEventSeeking = (): void => {
    Log.player.log(" onEventSeeking");
    this.state$.value = PlayerState.SEEKING;
  };
  onEventBuffer = (): void => {
    Log.player.log(" onEventBuffer");
    this.state$.value = PlayerState.BUFFERING;
  };
  onEventBufferEnd = (): void => {
    Log.player.log(" onEventBufferEnd");
  };
  onEventFinish = (): void => {
    Log.player.log(" onEventFinish");
    this.state$.value = PlayerState.ENDED;
    this.isPlaying$.value = false;
    this.currentTime$.value = 0;
    this.duration$.value = 0;

    // This call is mandatory to end ads video and proceed to next state
    if (this.isOnVideoEnd()) {
      Log.player.log(" isOnVideoEnd");
    }
  };
  onEventError = (e: any): void => {
    Log.player.log(" onEventError: ", e);
    this.state$.value = PlayerState.ERROR;
    this.isPlaying$.value = false;
  };

  onEventDRM(drmEvent: any, drmData: any) {
    Log.player.log("[TIZEN][ondrmevent] drmEvent = " + drmEvent + "; drmData = " + drmData);
  }

  /**
   * Deinit player
   */

  release(): void {
    this.stop();
    this.DOMElement.hidden = true;

    this.currentTime$.value = 0;
    this.duration$.value = 0;
    this.isPlaying$.value = false;
    this.isSeeking$.value = false;

    this.error$.value = undefined;
  }

  /**
   *ADS RELATED BLOCK
   */

  adController = (adController: AdController): void => {
    this.controller = adController;

    this.adDelegate = this.controller?.VideoPlayerDelegate;
    this.adListener = this.controller?.VideoPlayerListener;
  };

  shouldPlayVideo = (): boolean => {
    return this.adDelegate && !this.adDelegate.shouldPlayVideo(this, this.url$.value) ? false : true;
  };

  shouldPauseVideo = (): boolean => {
    return this.adDelegate && !this.adDelegate.shouldPauseVideo(this) ? false : true;
  };

  shouldStopVideo = (): boolean => {
    return this.adDelegate && !this.adDelegate.shouldStopVideo(this) ? false : true;
  };

  shouldSeekVideo = (): boolean => {
    return this.adDelegate && !this.adDelegate.shouldSeekVideo(this) ? false : true;
  };

  isAdVideoPlaying = (): boolean => {
    return this.adDelegate && this.adDelegate.isAdVideoPlaying() ? true : false;
  };

  isPreRollPlaying = (): boolean => {
    return this.adDelegate && this.adDelegate.isPreRollPlaying() ? true : false;
  };

  isMidRollPlaying = (): boolean => {
    return this.adDelegate && this.adDelegate.isMidRollPlaying() ? true : false;
  };

  isPostRollPlaying = (): boolean => {
    return this.adDelegate && this.adDelegate.isPostRollPlaying() ? true : false;
  };

  isPreRollPreparing = (): boolean => {
    return this.adDelegate && this.adDelegate.isPreRollPreparing() ? true : false;
  };

  isMidRollPreparing = (): boolean => {
    return this.adDelegate && this.adDelegate.isMidRollPreparing() ? true : false;
  };

  isPostRollPreparing = (): boolean => {
    return this.adDelegate && this.adDelegate.isPostRollPreparing() ? true : false;
  };

  isOnVideoEnd = (): boolean => {
    return this.adListener && !this.adListener.onVideoEnd() ? false : true;
  };

  onVideoAbort = (): boolean => {
    return this.adListener && !this.adListener.onVideoAbort() ? false : true;
  };

  onTimeUpdate = (elapsedTime: number, remainingTime: number, url: string): boolean => {
    return this.adListener && !this.adListener.onTimeUpdate(elapsedTime, remainingTime, url) ? false : true;
  };
}
