import "./itemMenu.scss";

import { DOMHelper, IFocusable, View } from "~ui-lib";

import { ItemAge } from "../../pages/gestionAge/itemAge";

export enum MainMenuItemSlug {
  age = "age",
  search = "search",
  home = "homepage",
  profil = "profil",
}

type ItemImages = {
  iconOn: string | null;
  iconOff: string | null;
  focusOn: string | null;
};

export type ItemMenu = {
  id: string;
  label: string;
  order: number;
  link: string;
  slug: MainMenuItemSlug;
  visible: boolean;
};

export class ItemMenuComponent extends View implements IFocusable {
  private data: ItemMenu;
  private images: ItemImages;
  private divImage: HTMLDivElement;
  private selected = false;
  private _root: HTMLElement;

  constructor(data: ItemMenu, root: HTMLElement, age?: ItemAge) {
    super(DOMHelper.createDivWithParent(null, null, "itemMenu"));
    const images: ItemImages = { iconOn: null, iconOff: null, focusOn: null };
    this._root = root;
    this.data = data;

    try {
      if (age !== undefined) {
        images.iconOn = require(`~images/mainMenu/iconOn/${age.age}ans.png`);
        images.iconOff = require(`~images/mainMenu/iconOff/${age.age}ans.png`);
        images.focusOn = require(`~images/mainMenu/focusOn/${age.age}ans.png`);
      } else {
        images.iconOn = require(`~images/mainMenu/iconOn/${data.slug}.png`);
        images.iconOff = require(`~images/mainMenu/iconOff/${data.slug}.png`);
        images.focusOn = require(`~images/mainMenu/focusOn/${data.slug}.png`);
      }
    } catch (error) {
      console.error("[itemMenu] Error !", error);
    }
    this.images = images;
    DOMHelper.createDivImg(this.rootElement, null, "itemMenuDot");
    this.divImage = DOMHelper.createDivImg(this.rootElement, null, "itemMenuImage", images.iconOff);
    DOMHelper.createDivWithParent(this.rootElement, null, "itemMenuTitle", data.label);

    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  }

  public setAge = (age: ItemAge) => {
    try {
      this.images.iconOn = require(`~images/mainMenu/iconOn/${age.age}ans.png`);
      this.images.iconOff = require(`~images/mainMenu/iconOff/${age.age}ans.png`);
      this.images.focusOn = require(`~images/mainMenu/focusOn/${age.age}ans.png`);
    } catch (error) {
      console.error("[itemMenu] Error !", error);
    }
    const isFocused = DOMHelper.hasClass(this.rootElement, "focused");
    if (this.selected) {
      if (isFocused) {
        this.divImage.style.backgroundImage = `url(${this.images.focusOn})`;
      } else {
        this.divImage.style.backgroundImage = `url(${this.images.iconOn})`;
      }
    } else {
      this.divImage.style.backgroundImage = isFocused ? `url(${this.images.focusOn})` : `url(${this.images.iconOff})`;
    }
  };

  onfocusMenu() {
    if (this.selected && DOMHelper.hasClass(this.rootElement, "focused")) {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  }

  onFocused(isMenuOpen?: boolean) {
    if (isMenuOpen && this.selected) {
      this.divImage.style.background = `url(${this.images.iconOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  }

  onUnfocused() {
    if (!DOMHelper.hasClass(this._root, "focused") && this.selected) {
      this.divImage.style.background = `url(${this.images.iconOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  }

  select = () => {
    this.selected = true;
    DOMHelper.addClass(this.rootElement, "selected");
    if (DOMHelper.hasClass(this.rootElement, "focused")) {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOn})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  };

  unSelect = () => {
    this.selected = false;
    DOMHelper.removeClass(this.rootElement, "selected");
    if (DOMHelper.hasClass(this.rootElement, "focused")) {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "contain";
    this.divImage.style.backgroundPosition = "center";
  };
}
