/**
 * @description check if Image for Background load
 * @param imageSrc image to check
 * @param onloadFallback fallback onload
 * @param onerrorFallback fallback onerror
 */
export function checkImage(imageSrc: string, onloadFallback: () => void, onerrorFallback: () => void): void {
  const img = new Image();
  img.onload = onloadFallback;
  img.onerror = onerrorFallback;
  img.src = imageSrc;
}
