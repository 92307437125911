import "./mixedSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { debounce } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { MixedView } from "~views/mixedView";

import { navigationStack } from "../main";
import { Extrait } from "../models/extrait";
import { Integrale } from "../models/integrale";
import { AgePage } from "../pages/gestionAge/agePage";
import { LimitAgePopup } from "../pages/popup/limitAgePopup";
import { ParamClickZone } from "../tools/analytics/piano";

export class MixedSwimlane extends View {
  private _searchString?: string;
  constructor(source: ItemCollection, title?: string, pageSize?: number, searchString?: string) {
    super(DOMHelper.createDivWithParent(null, "", "mixedSwimlane"));
    this._searchString = searchString;
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", title !== undefined ? title : source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Program | Integrale | Extrait)[]),
      viewFactory: (model: any) => {
        return new MixedView(model);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: pageSize ?? 5,
      visibleBefore: 1,
      visibleAfter: 1,
      onSelect: this.onSelect,
    });
  }

  private onSelect = (model: Program | ItemCollection, index: number) => {
    if (model.extras && model.extras.age_min && AgePage.selectAge && AgePage.selectAge.age < model.extras.age_min) {
      onSelectTile(model, index, undefined, this._searchString, true);
      const popupLimitAgePage = new LimitAgePopup(
        model.extras.age_min,
        "Cette collection n’est pas adaptée à ton âge. Pour y accéder, change ton âge dans les réglages."
      );
      navigationStack.pushPage(popupLimitAgePage);

      const _hide = debounce(() => {
        navigationStack.removePage(popupLimitAgePage);
      }, 2000);

      _hide();

      return true;
    } else {
      return onSelectTile(model, index, ParamClickZone.playlist, this._searchString);
    }
  };
}
