export class Artwork {
  format: string;
  key: string;
  src: string;
  id: string;

  constructor(id: string, key: string, src: string, format: string) {
    this.format = format;
    this.src = src;
    this.id = id;
    this.key = key;
  }
}
